import "./barcodeScanner.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useRef, useState } from "react";
import { collection, limit, query, where, onSnapshot, doc, setDoc, arrayUnion, increment, getDoc } from "firebase/firestore";
import { auth, db } from "../../../firebaseconfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useReactToPrint } from "react-to-print";

const BarcodeScanner = ({ shopList, userMetadata }) => {
    const [user] = useAuthState(auth);
    const [currentScan, setCurrentScan] = useState([]);
    const [fullRecords, setFullRecords] = useState([]);
    const [countStatistics, setCountStatistics] = useState([{
        session: 1,
        count: 0
    }]);

    const [personStatistics, setPersonStatistics] = useState([{
        created_by: user.displayName,
        count: 0
    }]);
    const [currentRecords, setCurrentRecords] = useState([]);
    const [searchRecords, setSearchRecords] = useState([]);
    const [currentGroup, setCurrentGroup] = useState("");
    const [currentSession, setCurrentSession] = useState("");
    const [workingDate, setWorkingDate] = useState(new Date());
    const [deliveryId, setDeliveryId] = useState("");
    const [groupRows, setGroupRows] = useState([]);
    const [joinGroupId, setJoinGroupId] = useState("");
    const [focusingScanner, setFocusingScanner] = useState("collapsed");
    const [searchString, setSearchString] = useState("");

    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

        return [htmlElRef, setFocus]
    }

    const [inputRef, setInputFocus] = useFocus();

    const handleJoinGroupId = (event) => {
        setJoinGroupId(event.target.value);
    };

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    };

    const handleDeliveryIdChange = (event) => {
        setDeliveryId(event.target.value);
    };
    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: ()=> componentRef.current
    })



    const handleGroupChange = (event) => {

        if (event.target.value === "") {
            event.preventDefault();
            return;
        }

        setCurrentGroup(event.target.value);
    };

    const handleSessionChange = (event) => {
        setCurrentSession(event.target.value);
    };

    function convertToNearestDay(time, timezone) {
        timezone = timezone * 3600;
        return (time - time % 3600 - ((time - time % 3600) + timezone) % 86400);
    }

    async function scanDeliveryId() {
        if (deliveryId.includes(" ") || deliveryId.length === 0) {
            await new Audio(require("../../../materials/scanner_fail.mp3")).play();
            alert("Please provide a Non-empty and No Space value.");
            return;
        }

        const scanCollection = collection(db, "ecommerce/scanner/records");
        var searchQuery = query(scanCollection,
            where("delivery_id_array", "array-contains-any", [deliveryId]),
            where("group", "==", currentGroup),
            limit(1));

        const unsubscribe = onSnapshot(searchQuery, async (scanSnapshot) => {
            // Unsubscribe immediately
            unsubscribe();

            if (scanSnapshot.size === 0) {
                const working_date = convertToNearestDay(workingDate.getTime() / 1000, 8);

                async function CreateRecord() {
                    await setDoc(doc(collection(db, "ecommerce/scanner/records"), currentGroup + "_" + working_date),
                        {
                            records: arrayUnion({
                                session: currentSession.toString(),
                                delivery_id: deliveryId,
                                created_at: new Date(),
                                created_by: user.displayName,
                                remark: ""
                            }),
                            delivery_id_array: arrayUnion(deliveryId),
                            total_count: increment(1)
                        }, { merge: true });
                }
                CreateRecord();
                new Audio(require("../../../materials/scanner_success.mp3")).play();
                setDeliveryId("");

            }
            else {
                await new Audio(require("../../../materials/scanner_fail.mp3")).play();
                scanSnapshot.docChanges().forEach((scanChange) => {
                    for (let i = 0; i < scanChange.doc.data().records.length; i++) {
                        if (deliveryId === scanChange.doc.data().records[i].delivery_id) {
                            alert("Record " + deliveryId + " existed at:\n" +
                                "Session : " + scanChange.doc.data().records[i].session + "\n" +
                                "Working Date : " + new Date(Number(scanChange.doc.data().working_date * 1000)).toLocaleDateString());
                            break;
                        }
                    }
                });
            }
        });
    }

    function restartSession() {

        var lastIndex = 0;
        var tempStatistics = [];
        for (let i = 0; i < countStatistics.length; i++) {
            tempStatistics.push(countStatistics[i]);

            if (countStatistics[i].session > lastIndex)
                lastIndex = countStatistics[i].session;
        }
        lastIndex++;
        tempStatistics.push({
            session: lastIndex.toString(),
            count: 0
        })

        setCountStatistics(tempStatistics);
        setCurrentSession(lastIndex.toString());
    }

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const newGroupId = uuidv4();

    useEffect(() => {
        setInputFocus();
        async function initializeGroup() {
            await setDoc(doc(collection(db, "ecommerce/scanner/group"), newGroupId), {
                name: user.displayName + " Scanner",
                owner: user.uid,
                created_at: new Date(),
                members: [user.uid],
                request_members: [],
                block_members: []
            });
        }

        async function getGroupRows() {

            if (user.uid === null)
                return;
            const groupCollection = collection(db, "ecommerce/scanner/group");
            var searchQuery = query(groupCollection,
                where("members", "array-contains-any", [user.uid]),
                limit(10));

            var currentRows = [];
            onSnapshot(searchQuery, (groupSnapshot) => {
                if (groupSnapshot.size === 0) {
                    // Initialize a group
                    initializeGroup();
                }

                groupSnapshot.docChanges().forEach((groupChange) => {
                    currentRows = currentRows.filter(item => item.temp_id !== groupChange.doc.id);
                    var isOwner = false;
                    if (groupChange.doc.data().owner === user.uid)
                        isOwner = true;

                    if (groupChange.type === "added") {
                        currentRows.push({
                            id: groupChange.doc.id + "_" + groupChange.doc.data().name,
                            owner: isOwner,
                            temp_id: groupChange.doc.id,
                            name: groupChange.doc.data().name,
                            request_members: groupChange.doc.data().request_members.length
                        });
                    }

                    if (groupChange.type === "modified") {
                        currentRows = currentRows.filter(id => id.temp_id !== groupChange.doc.id);
                        currentRows.push({
                            id: groupChange.doc.id + "_" + groupChange.doc.data().name,
                            owner: isOwner,
                            temp_id: groupChange.doc.id,
                            name: groupChange.doc.data().name,
                            request_members: groupChange.doc.data().request_members.length
                        });
                    }

                    if (groupChange.type === "removed") {
                        currentRows = currentRows.filter(id => id.temp_id !== groupChange.doc.id);
                    }
                }
                );

                setGroupRows(currentRows);

            }
            );
        };

        getGroupRows();

    }, []);

    useEffect(() => {

        async function initializeScan() {
            const working_date = convertToNearestDay(workingDate.getTime() / 1000, 8);

            if (currentGroup !== "" && working_date !== "") {
                await setDoc(doc(collection(db, "ecommerce/scanner/records"),
                    currentGroup + "_" + working_date),
                    {
                        working_date: working_date,
                        group: currentGroup,
                        records: [],
                        delivery_id_array: [],
                        total_count: 0
                    });
            }
        }


        async function getScanRows() {
            const scanCollection = collection(db, "ecommerce/scanner/records");
            var searchQuery = query(scanCollection,
                where("working_date", "==", convertToNearestDay(workingDate.getTime() / 1000, 8)),
                where("group", "==", currentGroup),
                limit(10));

            var tempRecords = [];
            onSnapshot(searchQuery, (scanSnapshot) => {
                if (scanSnapshot.size === 0) {
                    // Initialize a Scan
                    initializeScan();
                }

                scanSnapshot.docChanges().forEach((scanChange) => {
                    tempRecords = tempRecords.filter(item => item.temp_id !== scanChange.doc.id);
                    for (let i = 0; i < scanChange.doc.data().records.length; i++) {
                        tempRecords.push({
                            id: scanChange.doc.id + "_" + scanChange.doc.data().records[i].delivery_id,
                            temp_id: scanChange.doc.id,
                            session: scanChange.doc.data().records[i].session,
                            created_at: scanChange.doc.data().records[i].created_at,
                            created_by: scanChange.doc.data().records[i].created_by,
                            delivery_id: scanChange.doc.data().records[i].delivery_id,
                            remark: scanChange.doc.data().records[i].remark,
                        })
                    }
                }
                );

                setFullRecords(tempRecords);
            }
            );
        };
        getScanRows();
    }, [workingDate, currentGroup]);

    useEffect(() => {

        // Select First Group
        if (currentGroup === "") {
            if (groupRows.length > 0) {
                setCurrentGroup(groupRows[0].temp_id);
            }
        }

    }, [groupRows]);

    function getStatistics() {

        var newCountStatistics = [];
        var newPersonStatistics = [];
        var sessionFound = false;

        for (let i = 0; i < fullRecords.length; i++) {
            if (currentSession.toString() === fullRecords[i].session.toString())
                sessionFound = true;

            const findPersonIndex = newPersonStatistics.findIndex(obj => obj.created_by === fullRecords[i].created_by);
            if (findPersonIndex === -1) {
                newPersonStatistics.push({
                    created_by: fullRecords[i].created_by,
                    count: 1
                });
            }
            else {
                newPersonStatistics[findPersonIndex].count += 1;
            }


            const findSessionIndex = newCountStatistics.findIndex(obj => obj.session === fullRecords[i].session);
            if (findSessionIndex === -1) {
                newCountStatistics.push({
                    session: fullRecords[i].session,
                    count: 1
                });
            }
            else {
                newCountStatistics[findSessionIndex].count += 1;
            }
        }

        if (currentSession === "") {
            setCurrentSession(countStatistics[0].session);
        }

        if (newCountStatistics.length === 0) {
            newCountStatistics.push({
                session: 1,
                count: 0
            });
        }

        if (newPersonStatistics.length === 0) {
            newPersonStatistics.push({
                created_by: user.displayName,
                count: 0
            });
        }

        setCountStatistics(newCountStatistics);
        setPersonStatistics(newPersonStatistics);

        if (sessionFound === false)
            setCurrentSession(countStatistics[0].session);
    }

    useEffect(() => {
        getStatistics();
    }, [workingDate, fullRecords]);

    useEffect(() => {

        var currentRecords = [];
        for (let i = 0; i < fullRecords.length; i++) {
            if (fullRecords[i].session === currentSession.toString()) {
                currentRecords.push(fullRecords[i]);
            }
        }
        setCurrentRecords(currentRecords);
    }, [currentSession, fullRecords]);


    return (
        <div className="barcodeScanner">
            <Sidebar />
            <div className="barcodeScannerContainer">
                <Navbar userMetadata={userMetadata} />
                <div className="top">
                    <div className="barcodeScannerTitle">
                        <p>Barcode Scanner</p>
                    </div>
                    <div className="barcodeScannerSubTitle">
                        <p>Scan your Tracking Number to prevent Duplication.</p>
                        <br></br>
                        <p>Steps : </p>
                        <p>1. Modify Working Date / Group or Restart Session if needed.</p>
                        <p>2. Focus on Delivery ID</p>
                        <p>3. Scan with Barcode Scanner device</p>
                    </div>

                    <div className="main">
                        <div className="scannerContainer">
                            <h1>Scanner</h1>
                            <div className="topScannerContainer">
                                <div className="left">
                                    <div className="scannerDetails">
                                        <p>Display Name : </p>
                                        <p>{user.displayName}</p>
                                    </div>
                                    <div className="scannerDetails">
                                        <p>Working Date : </p>
                                        <div className="calendarWrap">
                                            <ReactDatePicker selected={workingDate}
                                                dateFormat="MMM dd, yyyy"
                                                onChange={(date) => {
                                                    setWorkingDate(date);
                                                }}>

                                            </ReactDatePicker>
                                        </div>
                                    </div>
                                    <div className="scannerDetails">
                                        <p>Delivery ID : </p>

                                        <div className="inputBoxFocus">
                                            <div className="tooltipText" style={{ visibility: focusingScanner }}>
                                                Focus here before Scan!
                                            </div>
                                            <input
                                                value={deliveryId}
                                                id="deliveryIdInputBox"
                                                className="inputBox"
                                                autoComplete="off"
                                                onBlur={() => { setFocusingScanner("visible"); }}
                                                onFocus={() => { setFocusingScanner("collapse"); }}
                                                ref={inputRef}
                                                onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        scanDeliveryId();
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={handleDeliveryIdChange}
                                            >
                                            </input>
                                        </div>
                                    </div>
                                    <button className="scanButton" onClick={() => { scanDeliveryId(); setInputFocus() }
                                    }>Scan</button>
                                    {
                                        /*
                                    <button className="manifestButton" onClick={() => { handlePrint(); setInputFocus(); }
                                    }>Print Manifest</button>
                                        */
                                    }

                                </div>
                                <div className="right">
                                    <div className="scannerDetails">
                                        <p>Current Group : </p>
                                        <FormControl sx={{ m: 1, minWidth: 150 }} className="comboBoxWrap"  >
                                            <Select
                                                className="selectComboBoxWrap"
                                                labelId="order-status-combobox-label"
                                                id="order-status-combobox"
                                                value={currentGroup}
                                                onChange={handleGroupChange}
                                            >
                                                {
                                                    Array.from(groupRows).map((item, i, row) => {
                                                        return (
                                                            <MenuItem className="menuItem" key={item.temp_id} value={item.temp_id}>{item.name}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="scannerDetails">
                                        <p>Current Session : </p>
                                        <FormControl sx={{ m: 1, minWidth: 150 }} className="comboBoxWrap"  >
                                            <Select
                                                className="selectComboBoxWrap"
                                                labelId="order-status-combobox-label"
                                                id="order-status-combobox"
                                                value={currentSession}
                                                onChange={handleSessionChange}
                                            >
                                                {
                                                    Array.from(countStatistics).map((item, i, row) => {
                                                        return (
                                                            <MenuItem className="menuItem" key={item.session} value={item.session}>{item.session}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>

                                        <button className="restartSessionButton" onClick={() => {
                                            restartSession();
                                        }
                                        }>Restart Session</button>
                                    </div>
                                </div>
                            </div>
                            <Datatable overrideDataRow={currentRecords} type="scanner_record"></Datatable>
                            {
                                /*
                            <Datatable overrideDataRow={statistics} type="scanner_statistics"></Datatable>
                            */
                            }
                        </div>

                        <div className="middleContainer">
                            <h1>Statistics</h1>
                            <br></br>
                            <div className="middleContainerDetails">

                                <div className="left">
                                    <h3>Session Count</h3>
                                    {
                                        Array.from(countStatistics).map((item, i, row) => {
                                            return (
                                                <div key={item.session} className="leftDetails">
                                                    Session {item.session} : {item.count}
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="leftDetails">
                                        <br></br>
                                        <div className="leftTitle"><b>Total Scan Count</b>: {countStatistics.reduce((a, v) => a = a + v.count, 0)}</div>
                                    </div>
                                </div>
                                <div className="right">
                                    <h3>Person Count</h3>
                                    {
                                        Array.from(personStatistics).map((item, i, row) => {
                                            return (
                                                <div key={item.created_by} className="rightDetails">
                                                    {item.created_by} : {item.count}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        

                        <div className="middleContainer">
                            <h1>Search</h1>
                            <p style={{ fontStyle: "italic" }}>Only EXACT match will be shown.</p>
                            <br></br>
                            <div className="middleContainerDetails">
                                <input
                                    value={searchString}
                                    className="inputBox"
                                    onChange={handleSearchString}
                                />
                                <button className="searchButton" onClick={async () => {
                                    setSearchRecords([]);
                                    const scanCollection = collection(db, "ecommerce/scanner/records");
                                    var searchQuery = query(scanCollection,
                                        where("delivery_id_array", "array-contains-any", [searchString]),
                                        limit(1));

                                    var tempSearchRows = [];
                                    onSnapshot(searchQuery, async (scanSnapshot) => {
                                        if (scanSnapshot.size === 0) {
                                            alert("There's no EXACT match of Delivery ID available.");
                                            setSearchRecords([]);
                                        }
                                        else {

                                            scanSnapshot.docChanges().forEach((scanChange) => {
                                                for (let i = 0; i < scanChange.doc.data().records.length; i++) {
                                                    if (scanChange.doc.data().records[i].delivery_id === searchString) {
                                                        tempSearchRows.push({
                                                            id: scanChange.doc.id + "_" + scanChange.doc.data().records[i].delivery_id,
                                                            temp_id: scanChange.doc.id,
                                                            session: scanChange.doc.data().records[i].session,
                                                            created_at: scanChange.doc.data().records[i].created_at,
                                                            created_by: scanChange.doc.data().records[i].created_by,
                                                            delivery_id: scanChange.doc.data().records[i].delivery_id,
                                                            remark: scanChange.doc.data().records[i].remark,
                                                        })
                                                        break;
                                                    }
                                                }
                                            });
                                            setSearchRecords(tempSearchRows);
                                        }
                                    });
                                }}>Search</button>
                            </div>
                            <Datatable overrideDataRow={searchRecords} type="scanner_record"></Datatable>
                        </div>

                        <div className="bottomContainer">
                            <div className="bottomDetails group">
                                <h1>Manage Group</h1>
                                <h4>For collaboration, share ID which Owned by You to friends!</h4>
                                <div className="bottomIndividualContainer">
                                    <p>Group ID : </p>
                                    <input
                                        value={joinGroupId}
                                        className="inputBox"
                                        onChange={handleJoinGroupId}
                                    />
                                    <button className="joinButton" onClick={async () => {
                                        const groupCollection = doc(db, "ecommerce/scanner/group", joinGroupId);
                                        const docSnap = await getDoc(groupCollection);
                                        if (docSnap.exists()) {

                                            var docData = docSnap.data();
                                            // If the group is not Owned by Current User
                                            // Not blocked
                                            // Not requested
                                            if (docData.owner !== user.uid &&
                                                docData.block_members.filter(item => item === user.uid).length === 0 &&
                                                docData.request_members.filter(item => item === user.uid).length === 0) {
                                                await setDoc(doc(collection(db, "ecommerce/scanner/group"), joinGroupId), {
                                                    request_members: arrayUnion(user.uid)
                                                }, { merge: true });

                                                const userCollection = doc(db, "user", docData.owner);
                                                await setDoc(userCollection, {
                                                    notification: arrayUnion({
                                                        title: "Scanner Group Request",
                                                        body: user.displayName + " has requested to join your Scanner Group.",
                                                        image: "",
                                                        link: "/barcode_scanner/group/" + joinGroupId,
                                                        created_at: new Date(),
                                                    }),
                                                    notification_count: increment(1)
                                                }, { merge: true });
                                            }
                                        }

                                        alert("Your request to join : \n" +
                                            "Group ID : " + joinGroupId + "\n" +
                                            "has been sent.\n" +
                                            "If the group exists, they will see your request.")
                                        setJoinGroupId("");
                                    }}>Join</button>
                                </div>
                                <div className="groupDetails">
                                    <Datatable overrideDataRow={groupRows} type="scanner_group"></Datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BarcodeScanner;
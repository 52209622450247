import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

const Popup = ({ title, children, openPopup, setOpenPopup }) => {
    return (<Dialog open={openPopup}>
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>

    </Dialog>)
};

export default Popup;
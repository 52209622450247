import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useState } from "react";
import { auth, db } from "../../../firebaseconfig";
import "./Login.scss";
import HomeNavbar from "../../../components/homeNavbar/HomeNavbar";
import validateEmail from "../../../utils/validateEmail";
import { doc, setDoc } from "firebase/firestore";
import HomeFooter from "../../../components/homeFooter/HomeFooter";
import { Helmet } from "react-helmet-async";
import encryption from "../../../utils/encryption";



const Login = ({ active }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const [displayName, setDisplayName] = useState("");
    const [signupEmail, setSignupEmail] = useState("");
    const [createPassword, setCreatePassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const signIn = async (auth, email, password) => {

        if (email === "" || password === "") {
            alert("Please key in the email and password.");
            return;
        }

        await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                if (user.displayName === null) user.displayName = user.email;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                switch (errorCode) {
                    case "auth/wrong-password":
                    case "auth/user-not-found":
                        alert("Please key in the correct email and password.");
                        break;

                    default:
                        alert(errorCode + "\n" + errorMessage);
                        break;

                }
            });
    };

    const createUser = async (
        email,
        displayName,
        createPassword,
        confirmPassword
    ) => {

        if (validateEmail(email) === null) {
            alert("Invalid email.");
            return;
        }
        if (displayName.length === 0) {
            alert("Please enter a Display Name.");
            return;
        }

        if(encryption.isPasswordValid(createPassword,
            confirmPassword) === false){
            return;
        }
        var encryptedPassword = encryption.aes258encryption(createPassword);


        await createUserWithEmailAndPassword(
            auth,
            email,
            createPassword
        )
            .then(async (userCredential) => {
                const user = userCredential.user;
                // update Firebase
                updateProfile(user, {
                    displayName: displayName,
                });

                const userCollection = doc(db, "user", user.uid);
                await setDoc(userCollection, {
                    uid: user.uid,
                    name: displayName,
                    email: email,
                    created_at: new Date(),
                    last_active_at: new Date(),
                    password_history: [encryptedPassword],
                    status: "normal",
                    notification: [{
                        title: "Welcome to TactiBots",
                        body: "Hello there, " + displayName + ".\n" +
                            "Check out our Blog sections for some tutorials!\n",
                        image: "",
                        link: "/blog",
                        created_at: new Date(),
                    }],
                    notification_count: 1
                });

                // update current user
                user.displayName = displayName;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                if (errorCode === "auth/email-already-in-use") {
                    alert("The email is already registered.");
                } else {
                    alert(errorCode + " : " + errorMessage);
                }
            });
    };

    function toggleLoginTabContentEvent(event) {
        tabBtns.forEach(f => f.classList.remove('active'));

        const loginTab = document.getElementsByName("loginTab");
        const signupTab = document.getElementsByName("signupTab");

        const loginTabHeader = document.getElementById("loginTabHeader");
        const signupTabHeader = document.getElementById("signupTabHeader");
        switch (event) {
            case "loginTabHeader":
                loginTabHeader.classList.toggle("active");
                signupTabHeader.classList.remove("active");
                loginTab[0].style.display = "block";
                signupTab[0].style.display = "none";
                break;
            case "signupTabHeader":
                loginTabHeader.classList.remove("active");
                signupTabHeader.classList.toggle("active");
                loginTab[0].style.display = "none";
                signupTab[0].style.display = "block";
                break;
            default:
                break;
        }
    };

    const tabBtns = document.querySelectorAll(".toggleButton");
    tabBtns.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            toggleLoginTabContentEvent(e.target.id);
        });
    });


    const forgotPassword = async (auth, email) => {
        await sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                // ..
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                if (errorCode === "auth/email-already-in-use") {
                    alert("The email is already registered.");
                } else {
                    alert(errorCode + " : " + errorMessage);
                }
            });

        alert("If the Account exists, password Reset Email has been sent.");
    }

    return (
        <div>
            <div className="homeContainer">
                <Helmet>
                    <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                    <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
                </Helmet>
                <HomeNavbar active={active} />
                <div className="middle">
                    <div className="loginSignupContainer" name="loginSignupContainer">
                        <div className="left">
                            <div className="bottom">
                                <h2>Where Businesses Learn, Share & Grow</h2>
                                <div>FREE E-Commerce System, Question and Answer Website for all Businesses</div>
                                <img src={require('../../../materials/login_background.png')} alt="" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="tab">
                                <button onClick={() => toggleLoginTabContentEvent("loginTabHeader")} className="toggleButton active" id="loginTabHeader">Login</button>
                                <button onClick={() => toggleLoginTabContentEvent("signupTabHeader")} className="toggleButton" id="signupTabHeader">Sign-Up</button>
                            </div>

                            <div className="tabContent">
                                <div className="loginTab" name="loginTab">
                                    <p>
                                        <img
                                            src={require('../../../materials/logo_background.png')}
                                            alt=""
                                            className="logo"
                                        />
                                    </p>
                                    <h1 className="title">Login to TactiBots</h1>
                                    <div>
                                        <input type="email" className="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}>
                                        </input>
                                    </div>
                                    <div>
                                        <input type="password" className="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}>
                                        </input>
                                    </div>

                                    <div className="forgotPassword">
                                        <div onClick={() => {
                                            if (validateEmail(email) === null) {
                                                alert("Invalid email.");
                                                return;
                                            }

                                            forgotPassword(auth, email)
                                        }
                                        }>
                                            Forgot Password?
                                        </div>
                                    </div>

                                    <div>
                                        <button onClick={() => {
                                            signIn(auth, email, password);
                                        }
                                        }>Log In</button>
                                    </div>

                                    <div style={{ display: "flex", gap: "5px" }}>
                                        New here?
                                        <div href="" className="toggleButton text" id="signupTabHeader"> Sign Up</div>
                                    </div>
                                </div>
                                <div className="signupTab" name="signupTab" style={{ display: "none" }}>
                                    <p>
                                        <img
                                            src={require('../../../materials/logo_background.png')}
                                            alt=""
                                            className="logo"
                                        />
                                    </p>
                                    <h1 className="title">Sign Up to TactiBots</h1>
                                    <div>
                                        <input type="email" className="signupEmail" placeholder="Email" onChange={(e) => setSignupEmail(e.target.value)}>
                                        </input>
                                    </div>
                                    <div>
                                        <input type="text" className="signupDisplayName" placeholder="Display Name" onChange={(e) => setDisplayName(e.target.value)}>
                                        </input>
                                    </div>
                                    <div>
                                        <input type="password" className="signupPassword" placeholder="Password" onChange={(e) => setCreatePassword(e.target.value)}>
                                        </input>
                                        <ul style={{marginTop: "5px", color: "black"}}>
                                            <li>At least 8 digits in length.</li>
                                            <li>At least 1 char (a-z) and 1 number (0-9) and 1 special char (eg: !@#$)</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <input type="password" className="signupConfirmPassword" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)}>
                                        </input>
                                    </div>

                                    <div>
                                        <button onClick={() => createUser(signupEmail, displayName, createPassword, confirmPassword)}>Sign Up</button>
                                    </div>

                                    <div>
                                        Already have an Account ?
                                        <div className="toggleButton text" id="loginTabHeader"> Login</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    )
}

export default Login;
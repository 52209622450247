const CryptoJS = require("crypto-js");

const Lazada = () =>{
}

/**
 * @param {Array} params
 * @param {string} path
 */
const Signature = (params, path) => {
    var concatenateParams = path;
    for(let i = 0; i < params.length; i++){
        concatenateParams += params[i];
    }

    const app_secret = "ROWuuk9oByQDm3o87lU5JdIu9mXXXZKT";


    return CryptoJS.HmacSHA256(concatenateParams, app_secret).toString(CryptoJS.enc.Hex).toUpperCase();
}

module.exports = { Lazada, Signature};
import "./Authorization.scss"
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import Popup from "../../../components/popup/Popup";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { auth, db } from "../../../firebaseconfig";
import * as ShopeeFunctions from "../../../utils/shopee";
import { collection, onSnapshot, query, where } from "firebase/firestore";

const Authorization = ({ type, userMetadata }) => {

    const [userRows, setUserRows] = useState([]);
    const [user] = useAuthState(auth);
    const [openPopup, setOpenPopup] = useState(false);
    const [platform, setPlatform] = useState("");
    const [storeName, setStoreName] = useState("");

    const [isHoveringCloseButton, setisHoveringCloseButton] = useState(false);
    const handleMouseEnterCloseButton = () => {
        setisHoveringCloseButton(true);
    };
    const handleMouseLeaveCloseButton = () => {
        setisHoveringCloseButton(false);
    };

    const [isHoveringConnectButton, setisHoveringConnectButton] = useState(false);
    const handleMouseEnterConnectButton = () => {
        setisHoveringConnectButton(true);
    };
    const handleMouseLeaveConnectButton = () => {
        setisHoveringConnectButton(false);
    };

    const openAuthorizationURL = (storeName) => {
        if (storeName.trim() === "") {
            alert(storeName);
        }
        else {
            switch (platform) {
                case "Shopee":
                    const timestamp = Math.floor(new Date().getTime() / 1000);
                    window.open(
                        "https://partner.shopeemobile.com/api/v2/shop/auth_partner" +
                        "?partner_id=2004820" +
                        "&timestamp=" + timestamp +
                        "&sign=" + ShopeeFunctions.Signature(timestamp, "/api/v2/shop/auth_partner") +
                        "&redirect=https://asia-southeast1-tactibots-1605018905444.cloudfunctions.net/app/api/ecommerce/auth/create/shopee/" + user.uid + "/" + storeName + "/"
                        , "popup"
                        , "shopeeAuthenticationWindow"
                    );
                    break;
                case "Lazada":
                    window.open("https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true" +
                        "&redirect_uri=https://asia-southeast1-tactibots-1605018905444.cloudfunctions.net/app/api/ecommerce/auth/create/lazada/" + user.uid + "/" + storeName + "/" +
                        "&client_id=115382"
                        , "popup"
                        , "lazadaAuthenticationWindow");
                    break;
                default:
                    break;
            }
        }
    }


    var currentUserRows = [];
    useEffect(() => {

        const authorizationDocsCollection = collection(db, "ecommerce/auth/shop");
        const authorizationDocsQuery = query(authorizationDocsCollection, where("uid", "==", user.uid));
        onSnapshot(authorizationDocsQuery, (snapshot) => {
            currentUserRows = Object.assign([], currentUserRows);
            snapshot.docChanges().forEach((change) => {

                var sync_status = change.doc.data().sync_status;

                if(sync_status === "stop")
                    sync_status = "active";

                if (change.type === "added") {
                    //if(currentUserRows.filter(e => e.id === change.doc.id).length === 0){
                    currentUserRows.push({
                        id: change.doc.id,
                        platform: change.doc.data().platform,
                        name: change.doc.data().name,
                        data_started_at: change.doc.data().data_started_at,
                        data_ended_at: change.doc.data().data_ended_at,
                        refresh_expire_in: change.doc.data().refresh_expire_in,
                        sync_status: sync_status
                    });
                    //}
                }

                if (change.type === "modified") {
                    currentUserRows = currentUserRows.map(obj => {
                        if (obj.id === change.doc.id) {
                            return {
                                ...obj,
                                name: change.doc.data().name,
                                data_started_at: change.doc.data().data_started_at,
                                data_ended_at: change.doc.data().data_ended_at,
                                sync_status: sync_status
                            };
                        }
                        return obj;
                    });
                }

                if (change.type === "removed") {
                    currentUserRows = currentUserRows.filter(item => item.id !== change.doc.id);
                }

            }
            );

            setUserRows(currentUserRows);
        });
    }, []);

    return (
        <div className="authorization">
            <Sidebar/>
            <div className="authorizationContainer">
                <Navbar userMetadata={userMetadata}/>
                <div className="top">
                    <div className="authorizationTitle">
                        <p>Add a New Store</p>
                    </div>
                    <div className="authorizationSubTitle">
                        <p>Credentials will not be saved within TactiBots.</p>
                        <p>Each store can only be authorized once throughout different accounts.</p>
                        <p>Data will start sync shortly after the authorization.</p>
                        <p>Tiktok are still in development.</p>
                        <br></br>
                        <p>Steps : </p>
                        <p>1. Click Logo below to authorize a New Store</p>
                        <p>2. Enter Store Name</p>
                        <p>3. Connect</p>
                    </div>

                    <div className="logoContainer">
                        <div className="logo" onClick={() => {
                            setOpenPopup(true); setPlatform("Shopee");
                            setStoreName("");
                        }}>
                            <img src={require('../../../materials/shopee_logo.png')} alt="" />
                        </div>
                        <div className="logo" onClick={() => { setOpenPopup(true); setPlatform("Lazada"); setStoreName(""); }}>
                            <img src={require('../../../materials/lazada_logo.png')} alt="" />
                        </div>
                        <div className="logoInactive" onClick={() => { /*setOpenPopup(true); setPlatform("Tiktok");*/ setStoreName(""); }}>
                            <img src={require('../../../materials/tiktok_logo.png')} alt="" />
                        </div>
                    </div>
                </div>
                <Datatable type="authorization" className="datatable" overrideDataRow={userRows} />

                <Popup className="popupContainer" title={"Add a New " + platform + " Store"} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <div style={{ paddingLeft: "20px" }}>

                        <div className="top" style={{ display: "flex", paddingBottom: "30px", paddingTop: "10px", width: "100%", height: "30px" }}>
                            <div style={{ width: "30%" }}>Store Name : </div>
                            <input style={{ width: "70%" }} type="text" className="storeName" onInput={e => setStoreName(e.target.value)}></input>
                        </div>

                        <div style={{ paddingBottom: "20px", color: "gray" }}>You will be prompted to login into your Seller Center and credentials will not be saved within TactiBots. </div>

                        <div className="bottom" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                            <div
                                onMouseEnter={handleMouseEnterCloseButton}
                                onMouseLeave={handleMouseLeaveCloseButton}
                                style={{
                                    transition: "all 0.2s ease", padding: "10px", textAlign: "center", cursor: "pointer", WebkitBoxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px",
                                    backgroundColor: isHoveringCloseButton ? "#e4e4e4" : "white"
                                }} onClick={() => setOpenPopup(false)}>
                                Close
                            </div>
                            <div
                                onMouseEnter={handleMouseEnterConnectButton}
                                onMouseLeave={handleMouseLeaveConnectButton}
                                style={{
                                    transition: "all 0.2s ease", padding: "10px", textAlign: "center", cursor: "pointer", WebkitBoxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px",
                                    backgroundColor: isHoveringConnectButton ? "#c3e2fb" : "#a1d5fe"
                                }} onClick={() => { setOpenPopup(false); openAuthorizationURL(storeName); }} >
                                Connect
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    )
}

export default Authorization;
import Dashboard from "../dashboard/Dashboard";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./DashboardHome.scss";
import { Helmet } from "react-helmet-async";

const DashboardHome = ({ userMetadata, shopeeOrders, lazadaOrders }) => {


    return (
        <div className="dashboardHome">
            <Helmet>
                <title>TactiBots - Dashboard</title>
                <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                <meta id="og-image" property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
            </Helmet>
            <Sidebar />
            <div className="dashboardHomeContainer">
                <Navbar userMetadata={userMetadata} />
                <Dashboard key={typeof(shopeeOrders.length) + typeof(lazadaOrders.length)} 
                shopeeOrders={shopeeOrders} lazadaOrders={lazadaOrders}/>
            </div>
        </div>
    )
}

export default DashboardHome;
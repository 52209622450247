import "./Questions.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../firebaseconfig";
import Popup from "../../../components/popup/Popup";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getDownloadURL, getStorage, ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import { collection, doc, getDoc, setDoc, increment, arrayRemove, arrayUnion, query, onSnapshot, orderBy, deleteDoc } from "firebase/firestore";
import moment from "moment/moment";
import { ArchiveRounded, DoneAllOutlined, DoneOutline, History, ThumbDown, ThumbUp } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import RichTextEditor from "../../../components/richTextEditor/RichTextEditor";


const SingleQuestion = ({ questionId, userMetadata }) => {

    const location = useLocation();
    const storage = getStorage();
    const [openQuestionPopup, setOpenQuestionPopup] = useState(false);
    const [openAnswerPopup, setOpenAnswerPopup] = useState(false);
    const [isHoveringCloseButton, setisHoveringCloseButton] = useState(false);
    const handleMouseEnterCloseButton = () => {
        setisHoveringCloseButton(true);
    };
    const handleMouseLeaveCloseButton = () => {
        setisHoveringCloseButton(false);
    };

    const navigate = useNavigate();
    const [desc, setDesc] = useState("");
    const [images, setImages] = useState([]);
    const [answers, setAnswers] = useState([]);

    const questionDoc = doc(db, "question", questionId);
    const [user, loading, error] = useAuthState(auth);
    const [questions, setQuestions] = useState({
        uid: "",
        tags: [],
        title: "",
        question: "",
        created_at: new Date(),
        view_count: 0,
        like_count: 0,
        like_by: [],
        dislike_by: []
    });
    const [author, setAuthor] = useState({
        name: "",
        photoURL: "",
    })

    async function getQuestion(addViewCount = false) {
        if (addViewCount === true) {
            await setDoc(questionDoc, {
                view_count: increment(1)
            }, { merge: true });
        }

        const questionDocsSnapshot = await getDoc(questionDoc);

        if (questionDocsSnapshot.exists()) {
            setQuestions(questionDocsSnapshot.data());
        }
        else {

        }
    };

    useEffect(() => {
        getQuestion(true);

        async function getAnswers() {

            const answerDocsCollection = collection(db, "question/" + questionId + "/answer");
            const answerDocsQuery = query(answerDocsCollection,
                orderBy("like_count", "desc"));

            var currentAnswer = [];
            onSnapshot(answerDocsQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        currentAnswer.push({
                            id: change.doc.id,
                            uid: change.doc.data().uid,
                            created_at: change.doc.data().created_at,
                            updated_at: change.doc.data().updated_at,
                            like_count: change.doc.data().like_count,
                            like_by: change.doc.data().like_by,
                            dislike_by: change.doc.data().dislike_by,
                            history_answer: change.doc.data().history_answer,
                            history_answer_date: change.doc.data().history_answer_date,
                            answer: change.doc.data().answer
                        });
                    }

                    if (change.type === "modified") {
                        currentAnswer = currentAnswer.map(obj => {
                            if (obj.id === change.doc.id) {
                                return {
                                    ...obj,
                                    uid: change.doc.data().uid,
                                    created_at: change.doc.data().created_at,
                                    updated_at: change.doc.data().updated_at,
                                    like_count: change.doc.data().like_count,
                                    like_by: change.doc.data().like_by,
                                    dislike_by: change.doc.data().dislike_by,
                                    history_answer: change.doc.data().history_answer,
                                    history_answer_date: change.doc.data().history_answer_date,
                                    answer: change.doc.data().answer
                                };
                            }
                            return obj;
                        });
                    }

                    if (change.type === "removed") {
                        currentAnswer = currentAnswer.filter(item => item.id !== change.doc.id);
                    }

                    setAnswers(currentAnswer);
                }
                );

            });
        }
        getAnswers();
    }, []);

    useEffect(() => {

        async function getAuthor() {

            if (typeof (questions.uid) !== "undefined" && questions.uid !== "") {
                const authorDoc = doc(db, "user", questions.uid);
                const authorDocSnapshot = await getDoc(authorDoc);

                if (authorDocSnapshot.exists()) {
                    setAuthor(authorDocSnapshot.data());
                }
            }
        }

        getAuthor();

    }, [questions]);

    return (
        <div className="questionContainer">
            <div className="singleQuestionContainer">
                <div className="top">
                    <h2>{questions.title}</h2>

                    <button onClick={() => {
                        if (!user) {
                            navigate('/login');
                            alert("You must be logged in to ask a question on TactiBots.");
                        }
                        else {
                            navigate('/questions/ask');
                        }
                    }}>Ask Question</button>
                </div>
                <div className="second">
                    <p>Asked {moment(new Date(questions.created_at.seconds * 1000)).fromNow()}</p>
                    {
                        typeof (questions.updated_at) !== "undefined" && typeof (questions.updated_at.seconds) !== "undefined" ?
                            <p>Modified {moment(new Date(questions.updated_at.seconds * 1000)).fromNow()}</p>
                            :
                            <p></p>
                    }
                    <p>Views {questions.view_count}</p>
                </div>
                <br></br>
                <hr></hr>
                <div className="question">
                    <div className="left">
                        <div className="item icon" onClick={async () => {
                            if (!user) {
                                alert("You must be logged in first.");
                                return;
                            }

                            if (user.uid === author.uid) {
                                alert("You can't do it to your Own Question.");
                                return;
                            }

                            var previouslyLike = false;
                            var previouslyDislike = false;
                            if (typeof (questions.like_by) !== "undefined") {
                                if (questions.like_by.includes(user.uid))
                                    previouslyLike = true;
                            }

                            if (typeof (questions.dislike_by) !== "undefined") {
                                if (questions.dislike_by.includes(user.uid))
                                    previouslyDislike = true;
                            }

                            // Previously Like, so just remove like
                            if (previouslyLike) {
                                await setDoc(questionDoc, {
                                    like_count: increment(-1),
                                    like_by: arrayRemove(user.uid)
                                }, { merge: true });
                            }
                            // Previously Dislike, so make it from -1 to 1
                            // Increment by 2
                            else if (previouslyDislike) {
                                await setDoc(questionDoc, {
                                    like_count: increment(2),
                                    like_by: arrayUnion(user.uid),
                                    dislike_by: arrayRemove(user.uid)
                                }, { merge: true });
                            }
                            // First Timer that never like or dislike
                            else {
                                await setDoc(questionDoc, {
                                    like_count: increment(1),
                                    like_by: arrayUnion(user.uid),
                                }, { merge: true });
                            }
                            getQuestion();
                        }}>
                            <Tooltip title="Like" style={{ fontSize: "40px" }}>
                                <ThumbUp />
                            </Tooltip>
                        </div>
                        <div className="item">
                            <p>{questions.like_count}</p>
                        </div>
                        <div className="item icon" onClick={async () => {
                            if (!user) {
                                alert("You must be logged in first.");
                                return;
                            }

                            if (user.uid === author.uid) {
                                alert("You can't do it to your Own Question.");
                                return;
                            }


                            var previouslyLike = false;
                            var previouslyDislike = false;
                            if (typeof (questions.like_by) !== "undefined") {
                                if (questions.like_by.includes(user.uid))
                                    previouslyLike = true;
                            }

                            if (typeof (questions.dislike_by) !== "undefined") {
                                if (questions.dislike_by.includes(user.uid))
                                    previouslyDislike = true;
                            }

                            // Previously Like, so make it from 1 to -1
                            // Increment by -2
                            if (previouslyLike) {
                                await setDoc(questionDoc, {
                                    like_count: increment(-2),
                                    like_by: arrayRemove(user.uid),
                                    dislike_by: arrayUnion(user.uid)
                                }, { merge: true });
                            }
                            // Previously Dislike, so make it from -1 to 0
                            // Increment by 1
                            else if (previouslyDislike) {
                                await setDoc(questionDoc, {
                                    like_count: increment(1),
                                    dislike_by: arrayRemove(user.uid)
                                }, { merge: true });
                            }
                            // First Timer that never like or dislike
                            else {
                                await setDoc(questionDoc, {
                                    like_count: increment(-1),
                                    dislike_by: arrayUnion(user.uid),
                                }, { merge: true });
                            }
                            getQuestion();
                        }}>
                            <Tooltip title="Dislike" style={{ fontSize: "40px" }}>
                                <ThumbDown />
                            </Tooltip>
                        </div>
                        {
                            user !== null ?
                                <div className="item icon" onClick={async () => {
                                    if (typeof (userMetadata.saved_questions) !== "undefined" &&
                                        userMetadata.saved_questions.includes(questionId) === false) {
                                        await setDoc(doc(collection(db, "user"), user.uid), {
                                            saved_questions: arrayUnion(questionId)
                                        }, { merge: true });
                                    }
                                    else {
                                        await setDoc(doc(collection(db, "user"), user.uid), {
                                            saved_questions: arrayRemove(questionId)
                                        }, { merge: true });
                                    }
                                }}>
                                    <Tooltip title="Save Question">
                                        <ArchiveRounded />
                                    </Tooltip>
                                </div>
                                :
                                <div></div>
                        }
                        {
                            typeof (questions.history_question) === "undefined" ?
                                <div></div> :
                                <div>

                                    <div className="item icon" onClick={() => {
                                        setOpenQuestionPopup(true);
                                    }}>
                                        <Tooltip title="View Edit History">
                                            <History />
                                        </Tooltip>
                                    </div>
                                    <Popup className="popupContainer" title={"Edit History"} openPopup={openQuestionPopup} setOpenPopup={setOpenQuestionPopup}>
                                        <div style={{ paddingLeft: "20px" }}>
                                            {
                                                Array.from(questions.history_question).map((item, j, k) => {
                                                    return (
                                                        <div key={"history_" + j} style={{
                                                            marginTop: "20px",
                                                            padding: "20px",
                                                            WebkitBoxShadow: "2px 4px 10px 1px rgba(50, 50, 50, 0.47)",
                                                            boxShadow: "2px 4px 10px 1px rgba(100, 100, 100, 0.47)",
                                                            marginBottom: "20px"
                                                        }}>
                                                            <p>Edited at {new Date(questions.history_question_date[j].seconds * 1000).toLocaleString()}</p>
                                                            <br></br>
                                                            <div dangerouslySetInnerHTML={{ __html: item.replace("<br>", "<br></br>").replace("<p></p>", "<p class=\"newLineParagraph\"></p>") }} />
                                                            <br></br>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className="bottom" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                                                <div
                                                    onMouseEnter={handleMouseEnterCloseButton}
                                                    onMouseLeave={handleMouseLeaveCloseButton}
                                                    style={{
                                                        transition: "all 0.2s ease", padding: "10px", textAlign: "center", cursor: "pointer", WebkitBoxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px",
                                                        backgroundColor: isHoveringCloseButton ? "#e4e4e4" : "white"
                                                    }} onClick={() => setOpenQuestionPopup(false)}>
                                                    Close
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                </div>
                        }
                    </div>
                    <div className="right">
                        <div className="fullQuestion">
                            <div dangerouslySetInnerHTML={{ __html: questions.question.replace("<br>", "<br></br>").replace("<p></p>", "<p class=\"newLineParagraph\"></p>") }} />
                        </div>
                        <div className="tags">
                            {
                                Array.from(questions.tags).map((tag, j, k) => {
                                    return (
                                        <div key={tag} className="tag">
                                            <Link to={"/tags/" + tag} style={{ textDecoration: "none", color: "#0071bd" }}>
                                                {tag}
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="bottom">

                            <div className="buttons">
                                {
                                    user !== null && user.uid === author.uid ?
                                        <button onClick={() => {
                                            navigate("/questions/edit/" + questionId);
                                        }}>Edit</button> :
                                        <button onClick={async () => {
                                            if (!user) {
                                                alert("You must be logged in first.");
                                                return;
                                            }

                                            if (user.uid === author.uid) {
                                                alert("You can't do it to your Own Question.");
                                                return;
                                            }

                                            var previouslyReport = false;
                                            if (typeof (questions.report_by) !== "undefined") {
                                                if (questions.report_by.includes(user.uid))
                                                    previouslyReport = true;
                                            }

                                            if (previouslyReport) {
                                                await setDoc(questionDoc, {
                                                    report_count: increment(-1),
                                                    report_by: arrayRemove(user.uid)
                                                }, { merge: true });
                                            }
                                            else {
                                                await setDoc(questionDoc, {
                                                    report_count: increment(1),
                                                    report_by: arrayUnion(user.uid)
                                                }, { merge: true });
                                            }
                                            getQuestion();
                                        }}>Report</button>
                                }
                                <button onClick={() => {
                                    const url = location.pathname;
                                    navigator.clipboard.writeText(url);
                                }}>Share</button>
                            </div>
                            <Link to={"/user/" + author.uid}>
                                <div className="author" >
                                    <img src={author.photoURL} />
                                    <h3>{author.name}</h3>
                                </div>
                                <p>asked at {moment(new Date(questions.created_at.seconds * 1000)).fromNow()}</p>
                            </Link>
                        </div>
                    </div>
                </div>
                <br></br>
                <hr></hr>
                <div className="answers">
                    {
                        answers.length === 0 ?
                            <h2>There's no answer right now.</h2>
                            :
                            <div>
                                <h2 style={{ marginBottom: "40px" }}>{answers.length} answers</h2>
                                {
                                    Array.from(answers).map((item, i, row) => {
                                        return (
                                            <div key={item.id}>
                                                <div className="singleAnswer">
                                                    <div className="left">
                                                        <div className="item icon" onClick={async () => {
                                                            if (!user) {
                                                                alert("You must be logged in first.");
                                                                return;
                                                            }

                                                            if (user.uid === item.uid) {
                                                                alert("You can't do it to your Own Answer.");
                                                                return;
                                                            }

                                                            var previouslyLike = false;
                                                            var previouslyDislike = false;
                                                            if (typeof (item.like_by) !== "undefined") {
                                                                if (item.like_by.includes(item.uid))
                                                                    previouslyLike = true;
                                                            }

                                                            if (typeof (item.dislike_by) !== "undefined") {
                                                                if (item.dislike_by.includes(item.uid))
                                                                    previouslyDislike = true;
                                                            }

                                                            // Previously Like, so just remove like
                                                            if (previouslyLike) {
                                                                await setDoc(doc(db, "question/" + questionId + "/answer", item.id), {
                                                                    like_count: increment(-1),
                                                                    like_by: arrayRemove(item.uid)
                                                                }, { merge: true });
                                                            }
                                                            // Previously Dislike, so make it from -1 to 1
                                                            // Increment by 2
                                                            else if (previouslyDislike) {
                                                                await setDoc(doc(db, "question/" + questionId + "/answer", item.id), {
                                                                    like_count: increment(2),
                                                                    like_by: arrayUnion(item.uid),
                                                                    dislike_by: arrayRemove(item.uid)
                                                                }, { merge: true });
                                                            }
                                                            // First Timer that never like or dislike
                                                            else {
                                                                await setDoc(doc(db, "question/" + questionId + "/answer", item.id), {
                                                                    like_count: increment(1),
                                                                    like_by: arrayUnion(item.uid),
                                                                }, { merge: true });
                                                            }
                                                        }}>
                                                            <Tooltip title="Like" style={{ fontSize: "40px" }}>
                                                                <ThumbUp />
                                                            </Tooltip>
                                                        </div>
                                                        <div className="item">
                                                            <p>{questions.like_count}</p>
                                                        </div>
                                                        <div className="item icon" onClick={async () => {
                                                            if (!user) {
                                                                alert("You must be logged in first.");
                                                                return;
                                                            }

                                                            if (user.uid === item.uid) {
                                                                alert("You can't do it to your Own Answer.");
                                                                return;
                                                            }

                                                            var previouslyLike = false;
                                                            var previouslyDislike = false;
                                                            if (typeof (item.like_by) !== "undefined") {
                                                                if (item.like_by.includes(item.uid))
                                                                    previouslyLike = true;
                                                            }

                                                            if (typeof (item.dislike_by) !== "undefined") {
                                                                if (item.dislike_by.includes(item.uid))
                                                                    previouslyDislike = true;
                                                            }

                                                            // Previously Like, so make it from 1 to -1
                                                            if (previouslyLike) {
                                                                await setDoc(doc(db, "question/" + questionId + "/answer", item.id), {
                                                                    like_count: increment(-2),
                                                                    like_by: arrayRemove(item.uid),
                                                                    dislike_by: arrayUnion(item.uid)
                                                                }, { merge: true });
                                                            }
                                                            // Previously Dislike, so make it from -1 to 0
                                                            else if (previouslyDislike) {
                                                                await setDoc(doc(db, "question/" + questionId + "/answer", item.id), {
                                                                    like_count: increment(1),
                                                                    dislike_by: arrayRemove(item.uid)
                                                                }, { merge: true });
                                                            }
                                                            // First Timer that never like or dislike
                                                            else {
                                                                await setDoc(doc(db, "question/" + questionId + "/answer", item.id), {
                                                                    like_count: increment(-1),
                                                                    dislike_by: arrayUnion(item.uid),
                                                                }, { merge: true });
                                                            }
                                                        }}>
                                                            <Tooltip title="Dislike" style={{ fontSize: "40px" }}>
                                                                <ThumbDown />
                                                            </Tooltip>
                                                        </div>
                                                        <div>
                                                            {
                                                                typeof (item.history_answer) === "undefined" ?
                                                                    <div></div> :
                                                                    <div>
                                                                        <div className="item icon" onClick={() => {
                                                                            setOpenAnswerPopup(true);
                                                                        }}>
                                                                            <Tooltip title="View Edit History">
                                                                                <History />
                                                                            </Tooltip>
                                                                        </div>

                                                                            
                                                                        <Popup className="popupContainer" title={"Edit History"} openPopup={openAnswerPopup} setOpenPopup={setOpenAnswerPopup}>
                                                                            <div style={{ paddingLeft: "20px" }}>
                                                                                {
                                                                                    Array.from(item.history_answer).map((innerItem, j, k) => {
                                                                                        return (
                                                                                            <div key={"answer_" + j} style={{
                                                                                                marginTop: "20px",
                                                                                                padding: "20px",
                                                                                                WebkitBoxShadow: "2px 4px 10px 1px rgba(50, 50, 50, 0.47)",
                                                                                                boxShadow: "2px 4px 10px 1px rgba(100, 100, 100, 0.47)",
                                                                                                marginBottom: "20px"
                                                                                            }}>
                                                                                                <p>Edited at {new Date(item.history_answer_date[j].seconds * 1000).toLocaleString()}</p>
                                                                                                <br></br>
                                                                                                <div dangerouslySetInnerHTML={{ __html: innerItem.replace("<br>", "<br></br>").replace("<p></p>", "<p class=\"newLineParagraph\"></p>") }} />
                                                                                                <br></br>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                                <div className="bottom" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                                                                                    <div
                                                                                        onMouseEnter={handleMouseEnterCloseButton}
                                                                                        onMouseLeave={handleMouseLeaveCloseButton}
                                                                                        style={{
                                                                                            transition: "all 0.2s ease", padding: "10px", textAlign: "center", cursor: "pointer", WebkitBoxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px",
                                                                                            backgroundColor: isHoveringCloseButton ? "#e4e4e4" : "white"
                                                                                        }} onClick={() => setOpenAnswerPopup(false)}>
                                                                                        Close
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Popup>
                                                                    </div>
                                                            }</div>
                                                        {
                                                            user !== null &&
                                                                user.uid === questions.uid ?
                                                                <div className="item icon" onClick={async () => {

                                                                    if(questions.marked_as_answer === item.id){
                                                                        await setDoc(questionDoc, {
                                                                            marked_as_answer: ""
                                                                        }, { merge: true });

                                                                        await setDoc(doc(collection(db, "user"), item.uid), {
                                                                            marked_as_answer: arrayRemove(questionId)
                                                                        }, { merge: true });
                                                                    }
                                                                    else{
                                                                        await setDoc(questionDoc, {
                                                                            marked_as_answer: item.id
                                                                        }, { merge: true });

                                                                        await setDoc(doc(collection(db, "user"), item.uid), {
                                                                            marked_as_answer: arrayUnion(questionId)
                                                                        }, { merge: true });
                                                                    }
                                                                    getQuestion();
                                                                }}>
                                                                    <Tooltip title="Mark as Answer?">
                                                                        <DoneOutline />
                                                                    </Tooltip>
                                                                </div>
                                                                :
                                                                <div></div>
                                                        }
                                                        {
                                                            item.id === questions.marked_as_answer ?
                                                                <div className="item icon marked">
                                                                    <Tooltip title="Marked as Answer by Author">
                                                                        <DoneAllOutlined />
                                                                    </Tooltip>
                                                                </div>
                                                                :
                                                                <div></div>
                                                        }
                                                    </div>
                                                    <div className="right">
                                                        <div className="fullQuestion">
                                                            <div dangerouslySetInnerHTML={{ __html: item.answer.replace("<br>", "<br></br>") }} />
                                                        </div>
                                                        <div className="bottom">

                                                            {
                                                                user !== null && user.uid === item.uid ?
                                                                    <div className="buttons">
                                                                        <button onClick={() => {
                                                                            navigate("/questions/edit/" + questionId + "/" + item.id);
                                                                        }}>Edit</button>
                                                                        <button onClick={async () => {
                                                                            if (window.confirm('Are you sure you want to delete the answer?')) {


                                                                                const bucket = "gs://tactibots-1605018905444.appspot.com/questions/" + questionId + "/" + item.id;
                                                                                const storageRef = ref(storage, bucket);
                                                                                listAll(storageRef)
                                                                                    .then((res) => {
                                                                                        res.items.forEach((itemRef) => {
                                                                                            const deleteRef = ref(storage, itemRef._location.path_);
                                                                                            deleteObject(deleteRef).then(() => {
                                                                                                // File deleted successfully
                                                                                            }).catch((error) => {
                                                                                                // Uh-oh, an error occurred!
                                                                                            });
                                                                                        });
                                                                                    }).catch((error) => {
                                                                                        // Uh-oh, an error occurred!
                                                                                    });

                                                                                var createdMonth = new Date(questions.created_at.seconds * 1000).getFullYear() + "-"
                                                                                    + (new Date(questions.created_at.seconds * 1000).getMonth() + 1).toString().padStart(2, "0");

                                                                                await deleteDoc(doc(db, "question/" + questionId + "/answer", item.id));
                                                                                await setDoc(doc(collection(db, "count"), "answers"), {
                                                                                    total_count: increment(-1),
                                                                                    [createdMonth]: increment(-1),
                                                                                }, { merge: true });

                                                                                if (questions.marked_as_answer === item.id) {
                                                                                    await setDoc(questionDoc, {
                                                                                        marked_as_answer: "",
                                                                                        answer_count: increment(-1)
                                                                                    }, { merge: true });
                                                                                }
                                                                                else {
                                                                                    await setDoc(questionDoc, {
                                                                                        answer_count: increment(-1)
                                                                                    }, { merge: true });
                                                                                }
                                                                                navigate(location.pathname);
                                                                            }
                                                                            else {
                                                                                return;
                                                                            }

                                                                        }}>Delete</button>
                                                                    </div> :
                                                                    <div className="buttons">
                                                                        <button onClick={async () => {
                                                                            if (!user) {
                                                                                alert("You must be logged in first.");
                                                                                return;
                                                                            }

                                                                            if (user.uid === item.uid) {
                                                                                alert("You can't do it to your Own Answer.");
                                                                                return;
                                                                            }


                                                                            var previouslyReport = false;
                                                                            if (typeof (questions.report_by) !== "undefined") {
                                                                                if (questions.report_by.includes(user.uid))
                                                                                    previouslyReport = true;
                                                                            }

                                                                            if (previouslyReport) {
                                                                                await setDoc(questionDoc, {
                                                                                    report_count: increment(-1),
                                                                                    report_by: arrayRemove(user.uid)
                                                                                }, { merge: true });
                                                                            }
                                                                            else {
                                                                                await setDoc(questionDoc, {
                                                                                    report_count: increment(1),
                                                                                    report_by: arrayUnion(user.uid)
                                                                                }, { merge: true });
                                                                            }
                                                                            getQuestion();
                                                                        }}>Report</button>
                                                                    </div>
                                                            }
                                                            <Link to={"/user/" + author.uid}>
                                                                <div className="author" >
                                                                    <img src={author.photoURL} />
                                                                    <h3>{author.name}</h3>
                                                                </div>
                                                                <p>answered at {moment(new Date(item.created_at.seconds * 1000)).fromNow()}</p>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <hr style={{ width: "95%", marginLeft: "auto" }}></hr>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
                <div className="answer">
                    {
                        user === null
                            ?
                            <button onClick={() => {
                                navigate("/login");
                            }}>Login</button>
                            :
                            <div>
                                <h2>Your Answer</h2>
                                <div className="tipTapEditor">
                                    <RichTextEditor setDesc={setDesc} setImages={setImages} ></RichTextEditor>
                                    <button onClick={async () => {
                                        // Calculate character
                                        var readableQuestions = "";
                                        var insideBracket = false;

                                        var calculateChar = desc;
                                        calculateChar = calculateChar.replace("&amp;", " ").replace("&lt;", " ").replace("&gt;", " ");
                                        for (let i = 0; i < calculateChar.length; i++) {
                                            if (insideBracket === false && calculateChar[i] === "<") {
                                                insideBracket = true;
                                                continue;
                                            }

                                            if (insideBracket === true && calculateChar[i] === ">") {
                                                insideBracket = false;
                                                continue;
                                            }

                                            if (insideBracket === false) {
                                                readableQuestions += calculateChar[i];
                                            }
                                        }

                                        if (readableQuestions.length < 20) {
                                            alert("Answer must be at least 20 characters.");
                                            return;
                                        }

                                        // Replace SRC with Download URL
                                        var finalHTML = desc;

                                        const documentId = doc(collection(db, "question/" + questionId + "/answer")).id;

                                        var copyImages = [];
                                        for (let i = 0; i < images.length; i++) {
                                            if (desc.includes("alt=\"" + images[i].size + "_" + encodeURIComponent(images[i].name)))
                                                copyImages.push(images[i]);
                                        }

                                        for (let i = 0; i < copyImages.length; i++) {

                                            var startingImageIndex = 0;
                                            var endingImageIndex = desc.indexOf("alt=\"" + copyImages[i].size + "_" + encodeURIComponent(copyImages[i].name));
                                            var startingIndex = -1;
                                            while (true) {
                                                startingImageIndex = desc.substring(startingImageIndex).indexOf("<img class=\"tiptap-image-class\" src=\"") + 32 + startingImageIndex;
                                                startingIndex++;

                                                if (startingIndex === i)
                                                    break;
                                            }

                                            const bucket = "gs://tactibots-1605018905444.appspot.com/questions/" + questionId + "/" + documentId + "/" + copyImages[i].size + "_" + encodeURIComponent(copyImages[i].name);
                                            const storageRef = ref(storage, bucket);
                                            await uploadBytes(storageRef, copyImages[i]);
                                            const link = await getDownloadURL(storageRef)

                                            finalHTML = finalHTML.replace(desc.substring(startingImageIndex, endingImageIndex), "src=\"" + link + "\" ");
                                        }

                                        var newAnswer = {
                                            uid: user.uid,
                                            answer: finalHTML,
                                            created_at: new Date(),
                                            like_count: 0,
                                        };

                                        await setDoc(doc(collection(db, "question/" + questionId + "/answer"), documentId), newAnswer);
                                        var currentMonth = new Date().getFullYear() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0");

                                        await setDoc(questionDoc, {
                                            answer_count: increment(1)
                                        }, { merge: true });

                                        // Total Count
                                        await setDoc(doc(collection(db, "count"), "answers"), {
                                            total_count: increment(1),
                                            [currentMonth]: increment(1),
                                        }, { merge: true });

                                        navigate(location.pathname);
                                    }}>Submit</button>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SingleQuestion;
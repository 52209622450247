import "./Questions.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../firebaseconfig";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { Helmet } from "react-helmet-async";


const Tag = () => {

    const navigate = useNavigate();

    const [user, loading, error] = useAuthState(auth);
    const [tags, setTags] = useState([]);

    useEffect(() => {

    }, [user]);


    useEffect(() => {
        const tagDocsCollection = collection(db, "tag");
        const tagDocsQuery = query(tagDocsCollection,
            orderBy("total_count", "desc"),
            limit(10));


        var currentMonth = new Date().getFullYear() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0");
        var currentTagsRow = [];
        onSnapshot(tagDocsQuery, (snapshot) => {

            currentTagsRow = Object.assign([], currentTagsRow);
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    currentTagsRow.push({
                        id: change.doc.id,
                        name: change.doc.data().name,
                        description: change.doc.data().description,
                        total_count: change.doc.data().total_count,
                        month_count: change.doc.data()[currentMonth],
                    });
                }

                if (change.type === "modified") {
                    currentTagsRow = currentTagsRow.map(obj => {
                        if (obj.id === change.doc.id) {
                            return {
                                ...obj,
                                name: change.doc.data().name,
                                description: change.doc.data().description,
                                total_count: change.doc.data().total_count,
                                month_count: change.doc.data()[currentMonth],
                            };
                        }
                        return obj;
                    });
                }

                if (change.type === "removed") {
                    currentTagsRow = currentTagsRow.filter(item => item.id !== change.doc.id);
                }

            }
            );

            setTags(currentTagsRow);
        });

    }, []);

    /*
const tags = [
    {
        id: 4,
        title: "ssm",
        description: "​Suruhanjaya Syarikat Malaysia (SSM) is a Governement Entity responsibile for Company.",
        count: 20,
        answer_count: 5,
    },
    {
        id: 3,
        title: "accounting",
        description: "Accounting helps recording financial transactions pertaining to a business.",
        count: 13,
        answer_count: 7,
    },
    {
        id: 2,
        title: "incorporation",
        description: "The process of constituting a company, city, or other organization as a legal corporation.",
        count: 3,
        answer_count: 1,
    },
    {
        id: 1,
        title: "overtime",
        description: "Working in excess of normal working hours on a normal work day.",
        count: 11,
        answer_count: 5,
    }
]
*/

    return (
        <div className="questionContainer">
            <Helmet>
                <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
            </Helmet>
            <div className="topContainer">
                <div>
                    <h2>Tags</h2>
                    <p>A tag is a keyword or label that categorizes your question with other, similar questions. Using the right tags makes it easier for others to find and answer your question.</p>
                </div>
                {
                    /*

                <button onClick={() => {
                    if (!user) {
                        navigate('/tags/suggestion');
                        alert("You must be logged in to suggest a tag on TactiBots.");
                    }
                    else {

                    }
                }}>Suggest Tag</button>
                    */
                }
            </div>
            <div className="secondContainer">
                <h2>{tags.length} tags</h2>
            </div>
            <div className="middleTagContainer">
                {
                    Array.from(tags).map((item, i, row) => {
                        return (
                            <div key={item.id}>
                                <div className="singleTag">
                                    <div className="details">
                                        <h3>{item.name}</h3>
                                        <p className="description">{item.description}</p>
                                    </div>
                                    <div className="bottom">
                                        <div className="counts">
                                            <p>{item.total_count} questions</p>
                                            <p>{item.month_count} asked this month</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Tag;
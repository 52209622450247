import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import "./HomeNavbar.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseconfig";


const HomeNavbar = ({ active, userMetadata }) => {

    const [user] = useAuthState(auth);
    const [openPopup, setOpenPopup] = useState("none");

    useEffect(() => {
        var btnContainer = document.getElementById(active);

        if (btnContainer !== null)
            btnContainer.classList.toggle("active");
    }, [active]);

    return (
        <div className="homeNavbar">
            <div className="top">
                <div className="topLeft">
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <img
                            src={require('../../materials/logo_wide.png')}
                            width="320px"
                            height="70px"
                            alt=""
                            className="topLeftBigLogo"
                        />
                        <img
                            src={require('../../materials/logo_background.png')}
                            width="70px"
                            height="70px"
                            alt=""
                            className="topLeftSmallLogo"
                        />
                    </Link>
                </div>
                <div className="hamburger">
                    <div >
                        {
                            user === null ?
                                <Link to="/login" style={{ textDecoration: "none" }}
                                >
                                    <p className="sectionButton">
                                        Login & Signup
                                    </p>
                                </Link>
                                :
                                <Link to="/" style={{ textDecoration: "none" }}
                                >
                                    <p className="sectionButton">
                                        Dashboard
                                    </p>
                                </Link>
                        }
                    </div>
                    <div className="icon">
                        <MenuIcon onClick={() => {
                            if (openPopup === "none") {
                                setOpenPopup("block");
                            }
                            else {
                                setOpenPopup("none");
                            }
                        }}>
                        </MenuIcon>
                    </div>
                </div>
                <div className="topRight">
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <p className="sectionButton" id="middleHomeContainer">
                            Home
                        </p>
                    </Link>

                    {
                            user === null ?
                            <Link to="/login" style={{ textDecoration: "none" }}>
                                <p className="sectionButton" id="loginSignupContainer">
                                    Login & Signup
                                </p>
                            </Link>
                            :
                            <Link to="/" style={{ textDecoration: "none" }}>
                                <p className="sectionButton" id="loginSignupContainer">
                                    Dashboard
                                </p>
                            </Link>
                    }

                    <Link to="/about_us" style={{ textDecoration: "none" }}>
                        <p className="sectionButton" id="aboutUsContainer">
                            About Us
                        </p>
                    </Link>
                    <Link to="/blog" style={{ textDecoration: "none" }}>
                        <p className="sectionButton" id="blogContainer">
                            Blog
                        </p>
                    </Link>
                    <Link to="/questions" style={{ textDecoration: "none" }}>
                        <p className="sectionButton" id="questionsContainer">
                            Forum
                        </p>
                    </Link>
                    <Link to="/privacy" style={{ textDecoration: "none" }}>
                        <p className="sectionButton" id="privacyContainer">
                            Privacy
                        </p>
                    </Link>
                    <Link to="/terms" style={{ textDecoration: "none" }}>
                        <p className="sectionButton" id="termsContainer">
                            Terms
                        </p>
                    </Link>
                    <Link to="/services" style={{ textDecoration: "none" }}>
                        <p className="sectionButton" id="servicesContainer">
                            Services
                        </p>
                    </Link>
                    <Link to="/contact_us" style={{ textDecoration: "none" }}>
                        <p className="sectionButton" id="contactUsContainer">
                            Contact Us
                        </p>
                    </Link>
                </div>
            </div>

            <div className="popUpContainer" style={{ display: openPopup }}>
                <div className="details">

                    <Link to="/" onClick={() => {
                        setOpenPopup("none");
                    }
                    } style={{ textDecoration: "none", color: "#0071bd" }}>
                        <p className="sectionButton" id="middleHomeContainer">
                            Home
                        </p>
                    </Link>

                    {
                            user === null ?
                            <Link to="/login" onClick={() => {
                                setOpenPopup("none");
                            }
                            } style={{ textDecoration: "none", color: "#0071bd" }}>
                                <p className="sectionButton" id="loginSignupContainer">
                                    Login & Signup
                                </p>
                            </Link>
                            :
                            <Link to="/" onClick={() => {
                                setOpenPopup("none");
                            }
                            } style={{ textDecoration: "none", color: "#0071bd" }}>
                                <p className="sectionButton" id="loginSignupContainer">
                                    Dashboard
                                </p>
                            </Link>
                    }

                    <Link to="/about_us" onClick={() => {
                        setOpenPopup("none");
                    }
                    } style={{ textDecoration: "none", color: "#0071bd" }}>
                        <p className="sectionButton" id="aboutUsContainer">
                            About Us
                        </p>
                    </Link>
                    <Link to="/blog" onClick={() => {
                        setOpenPopup("none");
                    }
                    } style={{ textDecoration: "none", color: "#0071bd" }}>
                        <p className="sectionButton" id="blogContainer">
                            Blog
                        </p>
                    </Link>
                    <Link to="/questions" onClick={() => {
                        setOpenPopup("none");
                    }
                    } style={{ textDecoration: "none", color: "#0071bd" }}>
                        <p className="sectionButton" id="questionsContainer">
                            Forum
                        </p>
                    </Link>
                    <Link to="/privacy" onClick={() => {
                        setOpenPopup("none");
                    }
                    } style={{ textDecoration: "none", color: "#0071bd" }}>
                        <p className="sectionButton" id="privacyContainer">
                            Privacy
                        </p>
                    </Link>
                    <Link to="/terms" onClick={() => {
                        setOpenPopup("none");
                    }
                    } style={{ textDecoration: "none", color: "#0071bd" }}>
                        <p className="sectionButton" id="termsContainer">
                            Terms
                        </p>
                    </Link>
                    
                    <Link to="/services" onClick={() => {
                        setOpenPopup("none");
                    }
                    } style={{ textDecoration: "none", color: "#0071bd" }}>
                        <p className="sectionButton" id="servicesContainer" >
                            Services
                        </p>
                    </Link>
                    <Link to="/contact_us" onClick={() => {
                        setOpenPopup("none");
                    }
                    } style={{ textDecoration: "none", color: "#0071bd" }}>
                        <p className="sectionButton" id="contactUsContainer" >
                            Contact Us
                        </p>
                    </Link>

                    <p className="sectionButton" id="privacyContainer" onClick={() => {
                        setOpenPopup("none");
                    }
                    }
                    >
                        Close
                    </p>
                </div>
            </div>

        </div>
    )
}

export default HomeNavbar;
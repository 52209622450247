
import CryptoJS from "crypto-js";

const encryptionKey = "u8x/A?D(G+KbPeShVmYp3s6v9y$B&E)H";
const encryptionIv = "w9z$C&F)J@NcRfUjXnZr4u7x!A%D*G-K";


const aes258encryption = (password) => {

    var key = CryptoJS.enc.Utf8.parse(encryptionKey);
    var iv = CryptoJS.enc.Utf8.parse(encryptionIv);

    var encryptedPassword = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(password), 
        key, 
        {
            keySize: 256 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();

    return encryptedPassword;
};

const aes258descryption = (encryptedPassword) => {
    var key = CryptoJS.enc.Utf8.parse(encryptionKey);
    var iv = CryptoJS.enc.Utf8.parse(encryptionIv);

    var decryptedPassword = CryptoJS.AES.decrypt(
        encryptedPassword, 
        key, 
        {
            keySize: 256 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Utf8.stringify(decryptedPassword);
};

const isPasswordValid = (createPassword,
    confirmPassword) =>{
    const passwordAlgorithm = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
    if (passwordAlgorithm.test(createPassword) === false) {
        alert("Please ensure that the password is :\n" +
        "- At least 8 digits in length.\n" +
        "- At least 1 char (a-z) and 1 number (0-9) and 1 special char (eg: !@#$)");
        return false;
    }

    if (createPassword !== confirmPassword) {
        alert("Password and confirmation password are not same.");
        return false;
    }

    return true;
}

export default {aes258encryption, aes258descryption, isPasswordValid};
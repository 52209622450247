import "./sidebar.scss";
import DashboardIcon from '@mui/icons-material/Dashboard';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseconfig";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import { AccountBox, AccountCircle, Assessment, Assignment, Book, DocumentScanner, Feedback, LocalShipping, PrivacyTip, QuestionAnswer, Tag } from "@mui/icons-material";

const Sidebar = () => {
    const { dispatch } = useContext(DarkModeContext);
    const location = useLocation();
    
    
    return (
        <div className="sidebar">
            <Link to="/" style={{ textDecoration: "none" }}>
                <div className="top">
                    <img
                        src={require('../../materials/logo_background.png')}
                        alt=""
                        className="logo"
                    />
                    <span className="logoText">TactiBots</span>
                </div>
            </Link>
            <hr />
            <div className="center">
                <ul>
                    <p className="title">MAIN</p>
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/dashboard" || location.pathname === "/" ? "active" : ""}>
                            <DashboardIcon className="icon" />
                            <span>Dashboard</span>
                        </li>
                    </Link>

                    <p className="title">E-COMMERCE</p>

                    <Link to="/authorization" style={{ textDecoration: "none"}}>
                        <li className={location.pathname === "/authorization" ? "active" : ""}>
                            <IntegrationInstructionsIcon className="icon" />
                            <span>Authorization</span>
                        </li>
                    </Link>

                    <Link to="/barcode_scanner" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/barcode_scanner" ? "active" : ""}>
                            <DocumentScanner className="icon" />
                            <span>Barcode Scanner</span>
                        </li>
                    </Link>
                    <Link to="/products" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/products" ? "active" : ""}>
                            <InventoryIcon className="icon" />
                            <span>Products</span>
                        </li>
                    </Link>
                    <Link to="/reports/profit_loss" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/reports/profit_loss" || location.pathname === "/reports/orders" ? "active" : ""}>
                            <Assessment className="icon" />
                            <span>Reports</span>
                        </li>
                    </Link>
                    <Link to="/shipment" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/shipment" ? "active" : ""}>
                            <LocalShipping className="icon" />
                            <span>Shipment</span>
                        </li>
                    </Link>

                    <p className="title">FORUM</p>
                    <Link to="/questions" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/questions" ? "active" : ""}>
                            <QuestionAnswer className="icon" />
                            <span>Questions</span>
                        </li>
                    </Link>
                    <Link to="/tags" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/tags" ? "active" : ""}>
                            <Tag className="icon" />
                            <span>Tags</span>
                        </li>
                    </Link>
                    <p className="title">SUPPORT</p>
                    <Link to="/blog" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/blog" ? "active" : ""}>
                            <Book className="icon" />
                            <span>Blog</span>
                        </li>
                    </Link>
                    <Link to="/feedback" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/feedback" ? "active" : ""}>
                            <Feedback className="icon" />
                            <span>Feedback</span>
                        </li>
                    </Link>
                    <Link to="/services" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/services" ? "active" : ""}>
                            <Book className="icon" />
                            <span>Services</span>
                        </li>
                    </Link>

                    <p className="title">SETTINGS</p>
                    <Link to="/account" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/account" ? "active" : ""}>
                            <AccountBox className="icon" />
                            <span>Account</span>
                        </li>
                    </Link>
                    <Link to="/privacy" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/privacy" ? "active" : ""}>
                            <PrivacyTip className="icon" />
                            <span>Privacy</span>
                        </li>
                    </Link>
                    <Link to="/terms" style={{ textDecoration: "none" }}>
                        <li className={location.pathname === "/terms" ? "active" : ""}>
                            <Assignment className="icon" />
                            <span>Terms</span>
                        </li>
                    </Link>
                    
                    <li onClick={() => {
                        signOut(auth);
                    }}>
                        <LogoutIcon className="icon" />
                        <span>Logout</span>
                    </li>
                </ul>
            </div>
            <div className="bottom">
                <div className="colorOption" onClick={() => dispatch({ type: "LIGHT" })}></div>
                <div className="colorOption" onClick={() => dispatch({ type: "DARK" })}></div>
            </div>
        </div>
    )
}

export default Sidebar;
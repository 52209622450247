import "./FeedbackSingle.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { auth, db } from "../../firebaseconfig";
import { collection, onSnapshot, query, where, doc, setDoc, documentId } from "firebase/firestore";
import { useLocation } from "react-router-dom";

const FeedbackSingle = ({ type, userMetadata }) => {

    const location = useLocation();
    const feedbackId = location.pathname.split('/')[2];

    const [user] = useAuthState(auth);
    const [singleFeedback, setSingleFeedback] = useState({});
    const [feedbackSingleTitle, setFeedbackSingleTitle] = useState("");
    const [feedbackSingleImages, setFeedbackSingleImages] = useState([]);
    const [feedbackSingleProgress, setFeedbackSingleProgress] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [fullMessage, setFullMessage] = useState([]);


    useEffect(() => {
        const feedbackDocsCollection = collection(db, "feedback");
        const feedbackDocsQuery = query(feedbackDocsCollection,
            where(documentId(), "==", feedbackId));

        onSnapshot(feedbackDocsQuery, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.doc.data().uid === user.uid) {
                    setSingleFeedback(change.doc.data());
                    setFeedbackSingleTitle(change.doc.data().title);
                    setFeedbackSingleImages(change.doc.data().images);
                    setFeedbackSingleProgress(change.doc.data().progress);
                    setFullMessage(change.doc.data().chat);
                }
            }
            );
        });

    }, []);

    async function updateFeedback() {
        const feedbackSingleDocRef = doc(db, "feedback", feedbackId);
        singleFeedback.title = feedbackSingleTitle;
        await setDoc(feedbackSingleDocRef, singleFeedback);
    }

    async function sendChat() {

        const feedbackSingleDocRef = doc(db, "feedback", feedbackId);
        singleFeedback.chat.push({
            message: currentMessage,
            created_at: new Date(),
            sent_by: "user"
        });
        await setDoc(feedbackSingleDocRef, singleFeedback);

        setCurrentMessage("");
    }

    return (
        <div className="feedbackSingle">
            <Sidebar />
            <div className="feedbackSingleContainer">
                <Navbar userMetadata={userMetadata} />
                <div className="top">
                    <div className="feedbackSingleTitle">
                        <p>Feedback Details</p>
                    </div>

                    <div className="top">
                        <div className="topDetails">

                            <div className="left">
                                <div>
                                    <h2>Title : </h2>
                                    <div style={{ marginTop: "5px" }}>
                                        <input value={feedbackSingleTitle} onInput={e => setFeedbackSingleTitle(e.target.value)} style={{ fontSize: "20px" }}></input>

                                        <button className="saveButton" onClick={() => { updateFeedback() }}>Save</button>
                                    </div>
                                </div>
                                <div>
                                    <h2>Status : </h2>
                                    <div className={`withStatus ${singleFeedback.status}`} style={{ textTransform: "capitalize" }}>
                                        {singleFeedback.status ?
                                            (singleFeedback.status).replace("-", " ")
                                            : ""}
                                    </div>
                                </div>
                                <div>
                                    <h2>Created At : </h2>
                                    <div>{
                                        singleFeedback.created_at ?
                                            new Date(Number(singleFeedback.created_at.seconds) * 1000).toLocaleString('en-US',
                                                {
                                                    timeZone: 'Asia/Kuala_Lumpur',
                                                    year: 'numeric', month: 'short', day: 'numeric',
                                                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                })
                                            : ""
                                    }</div>
                                </div>
                                <div>
                                    <h2>Last Updated At : </h2>
                                    <div>{
                                        singleFeedback.updated_at ?
                                            new Date(Number(singleFeedback.updated_at.seconds) * 1000).toLocaleString('en-US',
                                                {
                                                    timeZone: 'Asia/Kuala_Lumpur',
                                                    year: 'numeric', month: 'short', day: 'numeric',
                                                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                })
                                            : ""
                                    }</div>
                                </div>
                            </div>

                            <div className="timeline">
                                {
                                    Array.from(feedbackSingleProgress).map((item, i , row) => {
                                        
                                        var lastClass = "";
                                        if( i + 1 === row.length){
                                            lastClass = "last";
                                        }
                                        return (
                                            <div key={item.created_at.seconds} className="container right">
                                                <div className={`content ${lastClass}`}>
                                                    <h3>{item.title}</h3>
                                                    <p style={{ fontSize: "14px" }}>{item.description}</p>
                                                    <p style={{ fontSize: "12px", float: "right" }}>{new Date(Number(item.created_at.seconds) * 1000).toLocaleString('en-US',
                                                        {
                                                            timeZone: 'Asia/Kuala_Lumpur',
                                                            year: 'numeric', month: 'short', day: 'numeric',
                                                            hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                        })}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="fullChatContainer">
                            <h2>Chat : </h2>
                            <div className="chatContainer">
                                <div className="innerContainer">
                                    {
                                        Array.from(fullMessage).map(item => {
                                            return (

                                                <div key={item.created_at.seconds} className={`container ${item.sent_by}`}>
                                                    <img
                                                        src={item.sent_by === "user" ?
                                                            user.photoURL :
                                                            "https://yt3.ggpht.com/yti/APfAmoHJv0MQgcb5Ic_eq3s7F5lt2Cn2qYQ_dsMGlPtE=s108-c-k-c0x00ffffff-no-rj"}
                                                        alt="Avatar"></img>
                                                    <p>{item.message}</p>
                                                    <span className={`time-${item.sent_by}`}>{new Date(Number(item.created_at.seconds) * 1000).toLocaleString('en-US',
                                                        {
                                                            timeZone: 'Asia/Kuala_Lumpur',
                                                            year: 'numeric', month: 'short', day: 'numeric',
                                                            hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                        })}</span>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        /*

                                    <div className="container tactibots">
                                        <img src="https://yt3.ggpht.com/yti/APfAmoHJv0MQgcb5Ic_eq3s7F5lt2Cn2qYQ_dsMGlPtE=s108-c-k-c0x00ffffff-no-rj" alt="Avatar"></img>
                                        <p>Hello. How are you today?</p>
                                        <span className="time-right">11:00</span>
                                    </div>

                                    <div className="container user">
                                        <img src={user.photoURL} alt="Avatar" className="right"></img>
                                        <p>Hey! I'm fine. Thanks for asking!</p>
                                        <span className="time-left">11:01</span>
                                    </div>

                                    <div className="container tactibots">
                                        <img src="https://yt3.ggpht.com/yti/APfAmoHJv0MQgcb5Ic_eq3s7F5lt2Cn2qYQ_dsMGlPtE=s108-c-k-c0x00ffffff-no-rj" alt="Avatar"></img>
                                        <p>Sweet! So, what do you wanna do today?</p>
                                        <span className="time-right">11:02</span>
                                    </div>

                                    <div className="container user">
                                        <img src={user.photoURL} alt="Avatar" className="right"></img>
                                        <p>Nah, I dunno. Play soccer.. or learn more coding perhaps?</p>
                                        <span className="time-left">11:05</span>
                                    </div>
                                    <div className="container user">
                                        <img src={user.photoURL} alt="Avatar" className="right"></img>
                                        <p>Nah, I dunno. Play soccer.. or learn more coding perhaps?</p>
                                        <span className="time-left">11:05</span>
                                    </div>
                                    <div className="container user">
                                        <img src={user.photoURL} alt="Avatar" className="right"></img>
                                        <p>Nah, I dunno. Play soccer.. or learn more coding perhaps?</p>
                                        <span className="time-left">11:05</span>
                                    </div>
                                        */
                                    }
                                </div>

                            </div>
                            <textarea className="chat" placeholder="Aa"
                                value={currentMessage}
                                onInput={(e) => { setCurrentMessage(e.target.value) }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && e.shiftKey === false) {
                                        e.preventDefault();
                                        sendChat();
                                    }
                                }}
                            ></textarea>
                        </div>
                        <div className="feedbackSingleForm">
                            <h2>Details : </h2>
                            <div style={{ overflowWrap: "break-word", wordWrap: "break-word", wordBreak: "break-all", fontSize: "14px" }}

                            >{singleFeedback.details}</div>
                        </div>

                        {
                            <div className="images">
                                <h2>Images : </h2>
                                <div style={{ display: "flex", flexWrap: "wrap" }} >
                                    {
                                        Array.from(feedbackSingleImages).map(item => {
                                            return (
                                                <div key={item.link}>
                                                    <p>{item.name}</p>
                                                    <img
                                                        style={{ padding: "5px", width: "200px" }}
                                                        src={item.link} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackSingle;
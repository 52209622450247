import "../../Blogs.scss";
import HomeNavbar from "../../../../../components/homeNavbar/HomeNavbar";
import { AccessTime, RateReview, Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
import BlogBottomLikes from "../../../blog/BlogBottomLikes";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../../../firebaseconfig";
import { collection, doc, documentId, increment, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";


const Commission2023Jan10 = () => {

    const [user, loading, error] = useAuthState(auth);
    const blogDoc = doc(db, "count", "blogs_pn_shopee_commission_2023_01_10");
    const [documentDetails, setDocumentDetails] = useState({});
    useEffect(() => {

        async function increaseView() {
            await setDoc(blogDoc, {
                view_count: increment(1)
            }, { merge: true });
        }

        increaseView();

        async function getDocumentDetails() {
            const docCollection = collection(db, "count");
            const docQuery = query(docCollection,
                where(documentId(), "==", "blogs_pn_shopee_commission_2023_01_10"));

            onSnapshot(docQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {

                    setDocumentDetails(change.doc.data());
                })
            });
        }

        getDocumentDetails();
    }, []);

    return (
        <div className="homeContainer">
            <Helmet>
                <title>TactiBots | Commission 2023 Jan 10</title>
                <meta property="description" content="If Courier Charged Shipping Fee is greater than Buyer Paid Shipping Fee, Shipping Overcharge happens." />
                <meta property="og:description" content="If Courier Charged Shipping Fee is greater than Buyer Paid Shipping Fee, Shipping Overcharge happens." />
                <meta property="og:url" content="https://tactibots.com/blogs/platform_news/shopee_commission_2023_01_10"></meta>
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshipping_overcharge%2Fthumbnail.PNG?alt=media&token=0329fb7c-977f-4902-9b64-1e36396f3858"></meta>
            </Helmet>
            <HomeNavbar active={"blogContainer"} />
            <div className="singleBlogContainer" name="blogContainer">
                <div className="info">
                    <div className="time">
                        <AccessTime></AccessTime>
                        2022 December 22, 1:48pm
                    </div>
                    <div className="author">
                        <RateReview></RateReview>
                        TactiBots
                    </div>
                    <div className="time">
                        <Visibility></Visibility>
                        {
                            typeof (documentDetails) !== "undefined" ? documentDetails.view_count : 0
                        } views
                    </div>
                </div>
                <h1 className="title">Shopee Comission 2023 Jan 10</h1>
                <img className="thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fplatform_news%2Fshopee%2Fcommission_2023_01_10%2Fthumbnail.PNG?alt=media&token=7a87af3d-70e3-412e-96e1-5f25676af5a5"
                    alt=""
                />
                <div className="content">
                    <h3>Shopee Commission Fee is Raised!</h3>
                    <p>Sellers may refer to the summary chart below for the upcoming changes on 2023 Jan 10.</p>
                    <h3>Summary:</h3>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fplatform_news%2Fshopee%2Fcommission_2023_01_10%2Fsummary.PNG?alt=media&token=ebfc5086-e930-472d-92df-0142f4b5e469"
                        alt=""
                    />
                    <p>All commission fees listed above are before 6% SST.
                    <br></br>For example, Fashion Category is actually billed at 4.24% (4% x 1.06)</p>

                    <p>Note: </p>
                    <ul>
                        <li>Marketplace sellers with less than 100 completed orders since joining Shopee will not be charged the commission fee.</li>
                        <li>Completed orders refer to the paid orders that are delivered and accepted by the buyer. This excludes cancelled or returned/refunded orders, and orders pending delivery.</li>
                        <li>Priority sellers are entitled to special rates. Please reach out to your respective account manager or agent for more details.</li>
                    </ul>

                    <h3>What are the impacts?</h3>
                    <p>For example,
                    <br></br>If seller has RM 10,000 sales per month with a 30% profit margin.
                    </p>
                    <p>Prior to the update, seller has RM 3,000 of net income.
                        <br></br>After the update, seller will have to pay extra 0.5% to 2.0%, which ranged from RM 50 to RM 200.
                    </p>
                    <p>Worst case scenario wise, net income will then become RM 2,800 which is a <b>6% profit reduction</b>.</p>
                    <p>Sellers are advised to adjust pricing accordingly to prevent unexpected loss.</p>
                </div>
                <BlogBottomLikes document_id={"blogs_pn_shopee_commission_2023_01_10"} document_details={documentDetails} user={user} />
            </div>
        </div>
    )
}

export default Commission2023Jan10;
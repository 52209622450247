import "./shipment.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select } from "@mui/material";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "../../../components/daterangepicker/dateRangePickerComp.scss";
import { useEffect, useRef, useState } from "react";
import Popup from "../../../components/popup/Popup";
import { DocumentReference, Firestore, addDoc, arrayUnion, collection, doc, getDoc, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../../../firebaseconfig";
import { Center, ChakraProvider, Spinner } from "@chakra-ui/react";
import convertToNearestDay from "../../../utils/convertToNearestDay";
import { useAuthState } from "react-firebase-hooks/auth";
import * as ShopeeFunctions from "../../../utils/shopee";
import * as LazadaFunctions from "../../../utils/lazada";
import { getStorage, ref, uploadBytes } from "firebase/storage";

const Shipment = ({ shopList, userMetadata, shopeeOrders, lazadaOrders }) => {

    const storage = getStorage();

    const [shipmentId, setShipmentId] = useState("");
    const [processingText, setProcessingText] = useState("Processing ...");
    const [generateDate, setGenerateDate] = useState(null);
    const [courierList, setCourierList] = useState([{
        "name": "All",
        "All": 0,
        "To Process": 0,
        "Processed": 0
    }]);
    const [statusList, setStatusList] = useState(["All", "To Process", "Processed"]);
    const [user] = useAuthState(auth);

    const [orderCreatedDate, setOrderCreatedDateRange] = useState([
        {
            //startDate: new Date(convertToNearestDay(new Date().valueOf() / 1000 - 86400 * 5, 8) * 1000),
            startDate: new Date(convertToNearestDay(new Date().valueOf() / 1000 - 86400 * 3, 8) * 1000),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [firstRunningReport, setFirstRunningReport] = useState(false);
    const [runningReport, setRunningReport] = useState(false);
    const [showGeneratedDocuments, setShowGeneratedDocuments] = useState(false);
    const [generatedDocumentsListener, setGeratedDocumentsListener] = useState(false);
    const [generatedDocuments, setGeneratedDocuments] = useState([]);

    const [orderStatus, setOrderStatus] = useState('');
    const [platform, setPlatform] = useState([]);
    const [orderSearch, setOrderSearch] = useState('order_id');
    const [orderSearchString, setOrderSearchString] = useState('');
    const [preferenceCourierFilter, setPreferenceCourierFilter] = useState('All');
    const [preferenceStatusFilter, setPreferenceStatusFilter] = useState('');

    // open close
    const [open, setOpen] = useState(false)
    const [openPopup, setOpenPopup] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [lazadaRows, setLazadaRows] = useState([]);
    const [shopeeRows, setShopeeRows] = useState([]);
    const [finalRows, setFinalRows] = useState([]);

    // get the target element to toggle 
    const refOne = useRef(null)

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }

    const handleOrderStatusChange = (event) => {
        setOrderStatus(event.target.value);
    };
    const handlePlatformChange = async (event) => {
        var tempPlatforms = platform;
        if(event.target.checked === true)
        {
            if(tempPlatforms.includes(event.target.name) === false)
            {
                tempPlatforms.push(event.target.name);
            }
        }
        else
        {
            if(tempPlatforms.includes(event.target.name))
            {
                tempPlatforms = tempPlatforms.filter((pla) => pla !== event.target.name);
            }
        }
        setPlatform(tempPlatforms);
        
        await setDoc(doc(collection(db, "user"), user.uid), {
            preference_shipment_platform: tempPlatforms
        }, { merge: true });

        setRunningReport(true);
    };

    useEffect(() => {
        if(typeof(userMetadata.preference_shipment_platform) === "undefined")
        {
            var tempPlatforms = [];
            tempPlatforms.push('Shopee');
            setPlatform(tempPlatforms);
        }
        else
        {
            setPlatform(userMetadata.preference_shipment_platform);
        }
    },[userMetadata]);

    const handleOrderSearchChange = (event) => {
        setOrderSearch(event.target.value);
    };

    useEffect(() => {
        filterDataRows();
    }, [preferenceCourierFilter, preferenceStatusFilter, shopeeRows, lazadaRows]);

    useEffect(() => {
        if(shopeeOrders.length > 0 || lazadaOrders.length > 0)
        {
            setFirstRunningReport(true);
        }
    }, [shopeeOrders, lazadaOrders]);

    useEffect(() => {
        setGeratedDocumentsListener(true);
    }, [showGeneratedDocuments])

    useEffect(() => {
        if (generatedDocumentsListener === true) {
            var fiveDaysAgo = new Date();
            fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);

            const shipmentDocsCollection = collection(db, "ecommerce/shipment/shipment");
            const shipmentDocsQuery = query(shipmentDocsCollection,
                where("uid", "==", user.uid),
                where("created_at", ">=", fiveDaysAgo));

            var generatedDocumentRows = [];
            onSnapshot(shipmentDocsQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    var fullData = change.doc.data();
                    fullData.id = change.doc.id;
                    generatedDocumentRows.push(fullData);
                }
                );

                setGeneratedDocuments(generatedDocumentRows);
            });
        }
    }, [generatedDocumentsListener]);

    function filterDataRows() {
        var tempRows = [];

        for (let index = 0; index < shopeeRows.length; index++) {

            if (orderSearchString !== "" && orderSearchString.length >= 3) {
                if (orderSearch === "order_id"
                    && String(shopeeRows[index].order_id).includes(orderSearchString) === false) {
                    continue;
                }
                else if (orderSearch === "tracking_number"
                    && shopeeRows[index].tracking_number.includes(orderSearchString) === false
                ) {
                    continue;
                }
            }

            switch (preferenceCourierFilter) {
                case "All":
                    break;
                default:
                    if (shopeeRows[index].shipping_carrier !== preferenceCourierFilter) {
                        continue;
                    }
                    break;
            }

            var toProcessArray = ["READY_TO_SHIP", "pending"];
            var processedArray = ["PROCESSED", "ready_to_ship", "shipped"];
            switch (preferenceStatusFilter) {
                case "All":
                    break;
                case "To Process":
                    if (toProcessArray.includes(shopeeRows[index].order_status) === false)
                        continue;
                    break;
                case "Processed":
                    if (processedArray.includes(shopeeRows[index].order_status) === false)
                        continue;
                    break;
                default:
                    break;
            }
            
            tempRows.push(shopeeRows[index]);
        }


        
        for (let index = 0; index < lazadaRows.length; index++) {

            if (orderSearchString !== "" && orderSearchString.length >= 3) {
                if (orderSearch === "order_id"
                    && String(lazadaRows[index].order_id).includes(orderSearchString) === false) {
                    continue;
                }
                else if (orderSearch === "tracking_number"
                    && lazadaRows[index].tracking_number.includes(orderSearchString) === false
                ) {
                    continue;
                }
            }

            switch (preferenceCourierFilter) {
                case "All":
                    break;
                default:
                    if (lazadaRows[index].shipping_carrier !== preferenceCourierFilter) {
                        continue;
                    }
                    break;
            }

            var toProcessArray = ["READY_TO_SHIP", "pending"];
            var processedArray = ["PROCESSED", "ready_to_ship", "shipped"];
            switch (preferenceStatusFilter) {
                case "All":
                    break;
                case "To Process":
                    if (toProcessArray.includes(lazadaRows[index].order_status) === false)
                        continue;
                    break;
                case "Processed":
                    if (processedArray.includes(lazadaRows[index].order_status) === false)
                        continue;
                    break;
                default:
                    break;
            }
            
            tempRows.push(lazadaRows[index]);
        }
        setFinalRows(tempRows);
    }

    function getLazadaDataRows(orderDocsQuery, filterOrderStatus) {
        var currentRows = [];

            /*
            if (orderShopSnapshot.size === 0)
                alert("There's no Orders available.");
            */

            var includedStatus = ["pending", "ready_to_ship", "shipped"];
            if (orderSearchString !== "" && orderSearchString.length >= 3) {

                const unsubscribe = onSnapshot(orderDocsQuery, async (orderShopSnapshot) => {
                    unsubscribe();
                    orderShopSnapshot.docChanges().forEach((orderChange) => {
                        var shopName = "";
                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopList[index].name;
                                break;
                            }
                        };
    
                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            var trackingNumberString = "";
                            var trackingNumberArray = [];
    
                            if (includedStatus.includes(orderChange.doc.data().order_list[index].statuses[0]) === false)
                                continue;
    
                            for (let i = 0; i < orderChange.doc.data().order_list[index].item_details.length; i++) {
                                if (trackingNumberArray.includes(orderChange.doc.data().order_list[index].item_details[i].tracking_code) === false) {
                                    if (trackingNumberString !== "") {
                                        trackingNumberString += ", " + orderChange.doc.data().order_list[index].item_details[i].tracking_code
                                    }
                                    else {
                                        trackingNumberString = orderChange.doc.data().order_list[index].item_details[i].tracking_code;
                                    }
    
                                    trackingNumberArray.push(orderChange.doc.data().order_list[index].item_details[i].tracking_code);
                                }
                            }
    
    
                            if (orderSearch === "order_id") {
                                if (String(orderChange.doc.data().order_list[index].order_id).includes(orderSearchString) === false) {
                                    continue;
                                }
                            }
                            else {
                                var anyTrackingNumberMatch = false;
                                for (let i = 0; i < trackingNumberArray.length; i++) {
                                    if (trackingNumberArray[i].includes(orderSearchString) === true)
                                        anyTrackingNumberMatch = true;
                                }
                                if (anyTrackingNumberMatch === false) {
                                    continue;
                                }
                            }
    
                            var shipment_provider = orderChange.doc.data().order_list[index].item_details[0].shipment_provider;
                            if (shipment_provider.includes("Pickup:")) {
                                shipment_provider = shipment_provider.split(',')[0].split(':')[1].trim();
                            }
    
                            currentRows.push({
                                id: orderChange.doc.data().order_list[index].shop_id + "_" + orderChange.doc.data().order_list[index].order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Lazada",
                                shop_id: orderChange.doc.data().order_list[index].shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().order_list[index].remark,
                                order_id: String(orderChange.doc.data().order_list[index].order_id),
                                tracking_number: trackingNumberString,
                                create_time: new Date(orderChange.doc.data().order_list[index].created_at).valueOf() / 1000,
                                shipping_carrier: shipment_provider,
                                note: orderChange.doc.data().order_list[index].remarks,
                                order_status: orderChange.doc.data().order_list[index].statuses[0],
                                status: orderChange.doc.data().order_list[index].status,
                                item_details: orderChange.doc.data().order_list[index].item_details
                            });
                        }
    
                        setLazadaRows(currentRows);
                    });
                }
                );
            }
            else {
                for (let i = 0; i < lazadaOrders.length; i++) {
                    var shopName = "";
                    for (let index = 0; index < shopList.length; index++) {
                        if (shopList[index].shop_id === lazadaOrders[i].shop_id) {
                            shopName = shopList[index].name;
                            break;
                        }
                    };

                    var isThereAnyIncludedStatus = false;
                    for (let j = 0; j < includedStatus.length; j++)
                    {
                        if(lazadaOrders[i].order_status.includes(includedStatus[j]))
                        {
                            isThereAnyIncludedStatus = true;
                            break;
                        }
                    }
                    

                    if (isThereAnyIncludedStatus === false)
                        continue;
                    
                    currentRows.push({
                        id: lazadaOrders[i].shop_id + "_" + lazadaOrders[i].order_id,
                        platform: "Lazada",
                        shop_id: lazadaOrders[i].shop_id,
                        name: shopName,
                        remark: lazadaOrders[i].remark,
                        order_id: String(lazadaOrders[i].order_id),
                        tracking_number: lazadaOrders[i].tracking_number,
                        create_time: new Date(lazadaOrders[i].create_time),
                        shipping_carrier: lazadaOrders[i].shipment_provider,
                        note: lazadaOrders[i].note,
                        message_to_seller: lazadaOrders[i].message_to_seller,
                        order_status: lazadaOrders[i].order_status,
                        status: lazadaOrders[i].status,
                        item_details: lazadaOrders[i].item_details,
                        order_item_id_details: lazadaOrders[i].order_item_id_details
                    });
                };
            }
            setLazadaRows(currentRows);

    }

    function getShopeeDataRows(orderDocsQuery, filterOrderStatus) {
        var currentRows = [];

        if (filterOrderStatus === "Search") {

            const unsubscribe = onSnapshot(orderDocsQuery, async (orderShopSnapshot) => {
                unsubscribe();

                if (orderShopSnapshot.size === 0)
                    alert("There's no Orders available.");

                var includedStatus = ["READY_TO_SHIP", "PROCESSED"];
                var currentShop;
                const promises = [];
                if (orderSearchString !== "" && orderSearchString.length >= 3) {

                    orderShopSnapshot.docChanges().forEach((orderChange) => {

                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().shop_id) {
                                currentShop = shopList[index];
                                break;
                            }
                        };


                        if (includedStatus.includes(orderChange.doc.data().order_status) === true) {
                            var trackingNumber = "";
                            if (typeof (orderChange.doc.data().logistic_details) !== "undefined") {
                                trackingNumber = orderChange.doc.data().logistic_details.tracking_number;
                            }

                            var shipping_carrier = orderChange.doc.data().customer_details.shipping_carrier;
                            if (orderChange.doc.data().customer_details.shipping_carrier === "") {
                                shipping_carrier = orderChange.doc.data().customer_details.checkout_shipping_carrier;
                            }

                            currentRows.push({
                                id: orderChange.doc.data().shop_id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Shopee",
                                shop_id: orderChange.doc.data().shop_id,
                                name: currentShop.name,
                                remark: orderChange.doc.data().remark,
                                message_to_seller: orderChange.doc.data().customer_details.message_to_seller,
                                note: orderChange.doc.data().customer_details.note,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumber,
                                create_time: orderChange.doc.data().customer_details.create_time,
                                order_status: orderChange.doc.data().order_status,
                                status: orderChange.doc.data().status,
                                shipping_carrier: shipping_carrier,
                                item_details: orderChange.doc.data().customer_details.item_list
                            });
                        }
                        else {
                            //console.log(orderChange.doc.data().order_id + " " + orderChange.doc.data().order_status);
                        }

                    });
                }

                setShopeeRows(currentRows);

                if (currentRows.length === 0)
                    alert("There's no Orders available.");
            }
            );
        }
        else {
            /*
            if (shopeeOrders.length === 0)
                alert("There's no Orders available.");
            */
            var includedStatus = ["READY_TO_SHIP", "PROCESSED"];
            var currentShop;
            const promises = [];

            for (let i = 0; i < shopeeOrders.length; i++) {
                for (let index = 0; index < shopList.length; index++) {
                    if (shopList[index].shop_id === shopeeOrders[i].shop_id) {
                        currentShop = shopList[index];
                        break;
                    }
                };

                if (includedStatus.includes(shopeeOrders[i].order_status) === true) {
                    var trackingNumber = "";
                    if (shopeeOrders[i].order_status === "PROCESSED" &&
                        (typeof (shopeeOrders[i].logistic_details.tracking_number) === "undefined" || shopeeOrders[i].logistic_details.tracking_number === "")) {
                        const logisticTimestamp = Math.floor(new Date().getTime() / 1000);
                        const logisticSign = ShopeeFunctions.Signature(logisticTimestamp, "/api/v2/logistics/get_tracking_number",
                            currentShop.access_token, currentShop.shop_id);

                        const request = new XMLHttpRequest();
                        request.open("GET", "https://partner.shopeemobile.com/api/v2/logistics/get_tracking_number?" +
                            "partner_id=2004820" +
                            "&shop_id=" + currentShop.shop_id +
                            "&access_token=" + currentShop.access_token +
                            "&timestamp=" + logisticTimestamp +
                            "&sign=" + logisticSign +
                            "&order_sn=" + shopeeOrders[i].order_id, false);  // `false` makes the request synchronous
                        request.send(null);
                        if (request.status !== 200) {

                        }
                        const data = JSON.parse(request.responseText);
                        trackingNumber = data.response.tracking_number;

                        var tracking_number_array = [""];
                        if (typeof (trackingNumber) !== 'undefined' &&
                            trackingNumber !== "") {
                            for (let j = 3; j < trackingNumber.length; j++) {
                                for (let k = 0; k < trackingNumber.length; k++) {
                                    if (k + j <= trackingNumber.length)
                                        tracking_number_array.push(currentShop.shop_id + "_" + trackingNumber.substring(k, k + j));
                                }
                            };
                            tracking_number_array.push(currentShop.shop_id + "_" + trackingNumber);
                        };

                        setDoc(doc(db, "ecommerce/shopee/order", String(shopeeOrders[i].order_id)),
                            {
                                tracking_number_array: arrayUnion(...tracking_number_array),
                                logistic_details: data.response,
                                updated_at: new Date()
                            }, {
                            merge: true
                        });
                    }

                    if (typeof (shopeeOrders[i].logistic_details) !== "undefined" &&
                        shopeeOrders[i].logistic_details.tracking_number !== "") {
                        trackingNumber = shopeeOrders[i].logistic_details.tracking_number;
                    }

                    var shipping_carrier = shopeeOrders[i].customer_details.shipping_carrier;
                    if (shopeeOrders[i].customer_details.shipping_carrier === "") {
                        shipping_carrier = shopeeOrders[i].customer_details.checkout_shipping_carrier;
                    }

                    var remark = "";
                    if (typeof (shopeeOrders[i].remark) !== "undefined") {
                        remark = shopeeOrders[i].remark;
                    }

                    var status = "";
                    if (typeof (shopeeOrders[i].status) !== "undefined") {
                        status = shopeeOrders[i].status;
                    }

                    currentRows.push({
                        id: shopeeOrders[i].shop_id + "_" + shopeeOrders[i].order_id,
                        platform: "Shopee",
                        shop_id: shopeeOrders[i].shop_id,
                        name: currentShop.name,
                        remark: remark,
                        message_to_seller: shopeeOrders[i].customer_details.message_to_seller,
                        note: shopeeOrders[i].customer_details.note,
                        order_id: shopeeOrders[i].order_id,
                        tracking_number: trackingNumber,
                        create_time: shopeeOrders[i].customer_details.create_time,
                        order_status: shopeeOrders[i].order_status,
                        status: status,
                        shipping_carrier: shipping_carrier,
                        item_details: shopeeOrders[i].customer_details.item_list
                    });
                }

            };

            setShopeeRows(currentRows);
        }
    }

    async function removeDuplicate(currentRows) {

        var uniqueRow = [];
        currentRows = currentRows.sort(function (a, b) {
            return a.order_id.localeCompare(b.order_id) || b.tracking_number.localeCompare(a.tracking_number);
        });

        var newFinalRows = [];
        for (let i = 0; i < currentRows.length; i++) {
            if (uniqueRow.includes(currentRows[i].order_id) === false) {
                uniqueRow.push(currentRows[i].order_id);
                newFinalRows.push(currentRows[i]);
            }
            else {

                var collection = "";
                switch (currentRows[i].platform) {
                    case "Shopee":
                        collection = "ecommerce/shopee/order";
                        break;
                    case "Lazada":
                        collection = "ecommerce/lazada/order";
                        break;
                    default:
                        break;
                }
                const duplicateDocRef = doc(db, collection, currentRows[i].temp_id);
                const docSnap = await getDoc(duplicateDocRef);

                if (docSnap.exists()) {
                    var fullData = docSnap.data();

                    fullData.order_id_list = fullData.order_id_list.filter(item => item !== currentRows[i].order_id);
                    fullData.order_list = fullData.order_list.filter(item => item.order_id !== currentRows[i].order_id);
                    fullData.number -= 1;

                    await setDoc(doc(db, collection, currentRows[i].temp_id), fullData);
                }
            }
        }

        return newFinalRows;

    }

    function setStatistics() {
        var courier =
            [{
                "name": "All",
                "All": 0,
                "To Process": 0,
                "Processed": 0
            }]


        const allCourierIndex = courier.findIndex(item => item.name === "All");

        if(platform.includes("Shopee"))
        {
            for (let i = 0; i < shopeeRows.length; i++) {
                courier[allCourierIndex]["All"] = Number(courier[allCourierIndex]["All"]) + 1;

                var currentCourierIndex = courier.findIndex(item => item.name === shopeeRows[i].shipping_carrier);
                if (currentCourierIndex === -1) {
                    courier.push({
                        "name": shopeeRows[i].shipping_carrier,
                        "All": 0,
                        "To Process": 0,
                        "Processed": 0
                    })
                    currentCourierIndex = courier.findIndex(item => item.name === shopeeRows[i].shipping_carrier);
                }

                courier[currentCourierIndex]["All"] = Number(courier[currentCourierIndex]["All"]) + 1;
                switch (shopeeRows[i].order_status) {
                    case "READY_TO_SHIP":
                        courier[allCourierIndex]["To Process"] = Number(courier[allCourierIndex]["To Process"]) + 1;
                        courier[currentCourierIndex]["To Process"] = Number(courier[currentCourierIndex]["To Process"]) + 1;
                        break;
                    case "PROCESSED":
                        courier[allCourierIndex]["Processed"] = Number(courier[allCourierIndex]["Processed"]) + 1;
                        courier[currentCourierIndex]["Processed"] = Number(courier[currentCourierIndex]["Processed"]) + 1;
                        break;
                    default:
                        break;
                }
            }
        }

        if(platform.includes("Lazada"))
        {
            for (let i = 0; i < lazadaRows.length; i++) {
                courier[allCourierIndex]["All"] = Number(courier[allCourierIndex]["All"]) + 1;

                var currentCourierIndex = courier.findIndex(item => item.name === lazadaRows[i].shipping_carrier);
                if (currentCourierIndex === -1) {
                    courier.push({
                        "name": lazadaRows[i].shipping_carrier,
                        "All": 0,
                        "To Process": 0,
                        "Processed": 0
                    })
                    currentCourierIndex = courier.findIndex(item => item.name === lazadaRows[i].shipping_carrier);
                }

                courier[currentCourierIndex]["All"] = Number(courier[currentCourierIndex]["All"]) + 1;
                switch (lazadaRows[i].order_status) {
                    case "pending":
                        courier[allCourierIndex]["To Process"] = Number(courier[allCourierIndex]["To Process"]) + 1;
                        courier[currentCourierIndex]["To Process"] = Number(courier[currentCourierIndex]["To Process"]) + 1;
                        break;
                    case "shipped":
                    case "ready_to_ship":
                        courier[allCourierIndex]["Processed"] = Number(courier[allCourierIndex]["Processed"]) + 1;
                        courier[currentCourierIndex]["Processed"] = Number(courier[currentCourierIndex]["Processed"]) + 1;
                        break;
                    default:
                        break;
                }
            }
        }

        setCourierList(courier);
    }

    useEffect(() => {


        // const interval = setInterval(() => setProcessingText("Processing " + new Date()), 1000);
    }, []);
    useEffect(() => {
        const accountDocsCollection = collection(db, "user");
        const accountDocsQuery = query(accountDocsCollection,
            where("uid", "==", user.uid));

        onSnapshot(accountDocsQuery, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                if (typeof (change.doc.data().preference_shipment_status) === "undefined") {
                    setPreferenceStatusFilter("All");

                    await setDoc(doc(collection(db, "user"), user.uid), {
                        preference_shipment_status: "To Process"
                    }, { merge: true });

                }
                else {
                    setPreferenceStatusFilter(change.doc.data().preference_shipment_status);
                }
            }
            );
        });
    }, []);

    useEffect(() => {

        //console.log("LATEST PROCESSING TEXT " + new Date() + " " + processingText);
    }, [processingText]);

    useEffect(() => {
        setStatistics();
    }, [shopeeRows, lazadaRows]);

    useEffect(() => {
        if (runningReport === true || firstRunningReport === true) {
            generateReport();
        }
    }, [runningReport, firstRunningReport]);


    function generateReport() {
        var shopeeShopListArray = ["0"];
        for (let i = 0; i < shopList.length; i++) {
            if (shopList[i].platform === "Shopee")
                shopeeShopListArray.push(shopList[i].shop_id);
        }

        var lazadaShopListArray = ["0"];
        for (let i = 0; i < shopList.length; i++) {
            if (shopList[i].platform === "Lazada")
                lazadaShopListArray.push(shopList[i].shop_id);
        }

        if(platform.includes("Shopee"))
        {
            if (orderSearchString !== "" && orderSearchString.length >= 3) {
                const shopeeOrderDocsCollection = collection(db, "ecommerce/shopee/order");
                var orderSearchStringArray = [];
                for (let i = 0; i < shopeeShopListArray.length; i++) {
                    orderSearchStringArray.push(shopeeShopListArray[i] + "_" + orderSearchString.toUpperCase());
                }

                var searchQuery;
                if (orderSearch === "order_id") {
                    searchQuery = query(shopeeOrderDocsCollection,
                        where("order_id_array", "array-contains-any", orderSearchStringArray),
                        orderBy("create_time", "desc"));
                }
                else {
                    searchQuery = query(shopeeOrderDocsCollection,
                        where("tracking_number_array", "array-contains-any", orderSearchStringArray),
                        orderBy("create_time", "desc"));
                }
                getShopeeDataRows(searchQuery, "Search");
            }
            else {
                getShopeeDataRows("", orderStatus);
            }
        }

        if(platform.includes("Lazada"))
        {

            if (orderSearchString !== "" && orderSearchString.length >= 3) {
                const lazadaOrderDocsCollection = collection(db, "ecommerce/lazada/order");

                var orderSearchStringArray = [];
                for (let i = 0; i < lazadaShopListArray.length; i++) {
                    orderSearchStringArray.push(lazadaShopListArray[i] + "_" + orderSearchString.toUpperCase());
                }

                var searchQuery;
                if (orderSearch === "order_id") {
                    searchQuery = query(lazadaOrderDocsCollection,
                        where("order_id_array", "array-contains-any", orderSearchStringArray),
                        orderBy("create_time", "desc"));
                }
                else {
                    searchQuery = query(lazadaOrderDocsCollection,
                        where("tracking_number_array", "array-contains-any", orderSearchStringArray),
                        orderBy("create_time", "desc"));
                }
                getLazadaDataRows(searchQuery, "Search");
            }
            else {
                getLazadaDataRows("", orderStatus);
            }
        }
        
        setRunningReport(false);
        setGenerateDate(new Date());
    }

    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);

    }, []);

    const orderSearchStringOnChange = event => {
        setOrderSearchString(event.target.value);
    }

    async function massShip() {

        if (finalRows.length === 0) {
            alert("There's no Orders to be processed.");
            return;
        }

        var tempRows = [];
        if (selectedRows.length !== 0) {
            for (let i = 0; i < finalRows.length; i++) {
                if (selectedRows.includes(finalRows[i]["id"])) {
                    tempRows.push(finalRows[i]);
                }
            }
        }
        else {
            tempRows = finalRows;
        }

        // Process SKU
        var processedRows = [];
        for(let i = 0; i < tempRows.length; i++){
            var processedSkuRows = [];
            switch (tempRows[i].platform) {
                case "Shopee":
                    for(let j = 0; j < tempRows[i].item_details.length; j++)
                    {
                        var sku = tempRows[i].item_details[j].model_sku;
                        if(sku === "")
                        {
                            sku = tempRows[i].item_details[j].item_sku;
                        }
        
                        if(sku === "")
                        {
                            sku = tempRows[i].item_details[j].item_id + "_" + tempRows[i].item_details[j].model_id;
                        }
        
                        const findIndex = processedSkuRows.findIndex(item => item.sku === sku);
                        if(findIndex === -1)
                        {
                            processedSkuRows.push({
                                sku: sku,
                                name: tempRows[i].item_details[j].item_name,
                                variation: tempRows[i].item_details[j].model_name,
                                quantity: tempRows[i].item_details[j].model_quantity_purchased,
                                image: tempRows[i].item_details[j].image_info.image_url
                            });
                        }
                        else
                        {
                            processedSkuRows[findIndex].quantity = processedSkuRows[findIndex].quantity +tempRows[i].item_details[j].model_quantity_purchased;
                        }
                    }
                    
                    processedRows.push({
                        id: tempRows[i].id,
                        shop_id: tempRows[i].shop_id,
                        platform: tempRows[i].platform,
                        name: tempRows[i].name,
                        order_id: tempRows[i].order_id,
                        create_time: tempRows[i].create_time,
                        message_to_seller: tempRows[i].message_to_seller,
                        note: tempRows[i].note,
                        remark: tempRows[i].remark,
                        order_status: tempRows[i].order_status,
                        shipping_carrier: tempRows[i].shipping_carrier,
                        item_details: processedSkuRows
                    });
                    break;
                case "Lazada":
                    for(let j = 0; j < tempRows[i].item_details.length; j++)
                    {
                        var sku = tempRows[i].item_details[j].sku;
                        const findIndex = processedSkuRows.findIndex(item => item.sku === sku);
                        if(findIndex === -1)
                        {
                            processedSkuRows.push({
                                sku: sku,
                                name: tempRows[i].item_details[j].item_name,
                                variation: tempRows[i].item_details[j].variation_name,
                                quantity: tempRows[i].item_details[j].quantity,
                                image: tempRows[i].item_details[j].image
                            });
                        }
                        else
                        {
                            processedSkuRows[findIndex].quantity = processedSkuRows[findIndex].quantity +tempRows[i].item_details[j].quantity;
                        }
                    }
                    
                    processedRows.push({
                        id: tempRows[i].id,
                        shop_id: tempRows[i].shop_id,
                        platform: tempRows[i].platform,
                        name: tempRows[i].name,
                        order_id: tempRows[i].order_id,
                        create_time: tempRows[i].create_time,
                        message_to_seller: tempRows[i].message_to_seller,
                        note: tempRows[i].note,
                        remark: tempRows[i].remark,
                        order_status: tempRows[i].order_status,
                        shipping_carrier: tempRows[i].shipping_carrier,
                        item_details: processedSkuRows
                    });
                    break;
                default:
                    break;
            }
        }


        if (window.confirm("Do you want to process " + tempRows.length + " orders?\n" +
        "We're still testing on this feature.")) {
        } else {
            return;
        }

        // Record process to FireStore
        var shipmentId = "";
        const shippingDocRef = await addDoc(collection(db, "ecommerce/shipment/shipment"), {
            uid: user.uid,
            created_at: new Date(),
            number: tempRows.length,
            order_details: processedRows
        });

        shipmentId = shippingDocRef.id;
        setShipmentId(shippingDocRef.id);

        var currentPlatformShop = "";
        var currentShop = {};
        var currentPickupAddress = {};

        for (let i = 0; i < tempRows.length; i++) {
            // Get Current Order's Shop Details
            if (currentPlatformShop !== tempRows[i].platform + tempRows[i].shop_id) {
                currentPlatformShop = tempRows[i].platform + tempRows[i].shop_id;
                for (let j = 0; j < shopList.length; j++) {
                    if (shopList[j].platform === tempRows[i].platform && shopList[j].shop_id === tempRows[i].shop_id) {
                        currentShop = shopList[j];

                        switch (tempRows[i].platform) {
                            case "Shopee":
                                const shopeeAddressListTimestamp = Math.floor(new Date().getTime() / 1000);
                                const shopeeAddressListSign = ShopeeFunctions.Signature(shopeeAddressListTimestamp, "/api/v2/logistics/get_address_list", currentShop.access_token, currentShop.shop_id);

                                await fetch(
                                    "https://partner.shopeemobile.com/api/v2/logistics/get_address_list?" +
                                    "access_token=" + currentShop.access_token +
                                    "&partner_id=2004820" +
                                    "&sign=" + shopeeAddressListSign +
                                    "&shop_id=" + currentShop.shop_id +
                                    "&timestamp=" + shopeeAddressListTimestamp
                                    ,
                                    {
                                        method: 'GET',
                                    }
                                )
                                    .then((response) => response.json())
                                    .then((data) => {

                                        for (let addressIndex = 0; addressIndex < data.response.address_list.length; addressIndex++) {
                                            var pickUpAddressFound = false;
                                            for (let addressTypeIndex = 0; addressTypeIndex < data.response.address_list[addressIndex].address_type.length; addressTypeIndex++) {
                                                if (data.response.address_list[addressIndex].address_type[addressTypeIndex] === "PICKUP_ADDRESS") {
                                                    pickUpAddressFound = true;
                                                    currentPickupAddress = data.response.address_list[addressIndex];
                                                    break;
                                                }
                                            }

                                            if (pickUpAddressFound === true)
                                                break;
                                        }
                                    });
                                break;
                            default:
                                break;
                        }

                        break;
                    }
                }

            }

            await addDoc(collection(db, "ecommerce/shipment/orders"), {
                shipment_id: shipmentId,
                uid: user.uid,
                created_at: new Date(),
                order_details: tempRows[i],
                shop_details: currentShop
            });

            switch (tempRows[i].platform) {
                case "Shopee":
                    break;
                case "Lazada":
                    break;
                default:
                    break;
            }
        }


        window.open('/shipment/print_awb?' + shipmentId, '_blank');
    }


    return (
        <div className="shipment">
            <Sidebar />

{
    /*

            <Popup openPopup={runningReport} setOpenPopup={setRunningReport}>
                <ChakraProvider>
                    <Center h="50vh" w="50vh" >

                        <div>
                            <div style={{ marginLeft: "30px" }}>
                                <Spinner />
                            </div>
                            <div>
                                <p>{processingText}</p>
                            </div>

                        </div>
                    </Center>
                </ChakraProvider>
            </Popup>
    */
}
            <div className="shipmentContainer">
                <Navbar userMetadata={userMetadata} />
                <div className="top">
                    <div className="shipmentTitle">
                        <p>Shipment</p>
                        <button onClick={() => {
                            setShowGeneratedDocuments(true);
                        }}>Generated Documents in Past 5 Days</button>
                    </div>
                    <div className="shipmentSubTitle">
                        <p>Mass Process your eCommerce Orders all at once.</p>
                        <p>Print Airway Bill with SKU details.</p>
                        <br></br>
                        <p>Steps : </p>
                        <p>1. Select 'Platform' and 'Store'</p>
                        <p>2. Filter Orders with Status / Courier</p>
                        <p>3. 'Mass Ship' All or Selected Orders</p>
                        <p>4. Print</p>
                        <br></br>
                    </div>

                    <div className="main">

                        <div className="orderContainer">
                            <h1>Orders</h1>

                            <div className="platformFilter">
                                <p>Platform : </p>
                                <div >
                                    
                                <FormControlLabel
                                    label="Shopee"
                                    control={
                                    <Checkbox onChange={handlePlatformChange} name="Shopee"
                                    checked={typeof(platform) !== "undefined" && 
                                    platform.includes("Shopee")} />
                                    }
                                />
                                <FormControlLabel
                                    label="Lazada"
                                    control={
                                    <Checkbox onChange={handlePlatformChange} name="Lazada" 
                                    checked={typeof(platform) !== "undefined" && 
                                    platform.includes("Lazada")}
                                    />
                                    }
                                />
                                </div>
                            </div>
                            
                            {
                                /*
                            <div className="platformFilter">
                                <p>Shop : </p>
                                <div >
                                <FormControlLabel
                                    label="ABC"
                                    control={
                                    <Checkbox checked={Domain} onChange={handleChange} name="Domain" />
                                    }
                                />
                                </div>
                            </div>
                                */
                            }

                            <div className="generateContainer">

                                <p>Generated At : {
                                    generateDate !== null ?
                                        generateDate.toLocaleString() :
                                        "NA"} </p>
                            </div>
                        </div>
                    </div>
                    <div className="filterContainer">
                        <h1>Filter</h1>
                        <div className="details">
                            <p>Status : </p>
                            <div>
                                {
                                    Array.from(statusList).map((item, i, row) => {
                                        return (
                                            <button key={item + "_" + item}
                                                className={item === preferenceStatusFilter ? "active" : ""}
                                                onClick={async () => {
                                                    setPreferenceStatusFilter(item);
                                                    await setDoc(doc(collection(db, "user"), user.uid), {
                                                        preference_shipment_status: item
                                                    }, { merge: true });
                                                }}
                                            >{item} (
                                                {
                                                    item === "All" ? courierList[0]["All"] :
                                                        item === "Processed" ? courierList[0]["Processed"] :
                                                            item === "To Process" ? courierList[0]["To Process"] : 0
                                                })</button>
                                        )
                                    })
                                }</div>
                        </div>
                        <div className="details">
                            <p>Courier : </p>
                            <div>
                                {
                                    Array.from(courierList).map((item, i, row) => {
                                        return (<button key={item.name + "_" + item.name}
                                            className={item.name === preferenceCourierFilter ? "active" : ""}
                                            onClick={() => setPreferenceCourierFilter(item.name)}
                                        >{item.name} ({item[preferenceStatusFilter]})</button>
                                        )
                                    })
                                }</div>
                        </div>
                    </div>
                </div>

                <div className="processContainer">
                    <button onClick={() => {
                        massShip();
                    }}>Mass Ship</button>
                </div>

                <Datatable type={"shipment"} overrideDataRow={finalRows} selectedRow={selectedRows} setSelectedRows={setSelectedRows} />



                <Popup openPopup={showGeneratedDocuments} setOpenPopup={setShowGeneratedDocuments}>
                    <ChakraProvider>
                        <Center>
                            <div>
                                {
                                    generatedDocuments.length > 0 ?
                                        <table style={{ border: "1px solid" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border: "1px solid black", padding: "10px" }}>Number of Orders</th>
                                                    <th style={{ border: "1px solid black", padding: "10px" }}>Generated At</th>
                                                    <th style={{ border: "1px solid black", padding: "10px" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>{
                                                Array.from(generatedDocuments).map((item, i, row) => {

                                                    return (
                                                        <tr key={item.id}>
                                                            <td style={{ border: "1px solid black", textAlign: "center" }}>{item.number}</td>
                                                            <td style={{ border: "1px solid black", textAlign: "center", paddingLeft: "10px", paddingRight: "10px" }}>{
                                                                new Date(Number(item.created_at.seconds) * 1000).toLocaleString('en-US',
                                                                    {
                                                                        timeZone: 'Asia/Kuala_Lumpur',
                                                                        year: 'numeric', month: 'short', day: 'numeric',
                                                                        hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                                    })
                                                            }</td>
                                                            <td style={{ border: "1px solid black" }}>
                                                                <button onClick={() => {
                                                                    window.open('/shipment/print_awb?' + item.id, '_blank');
                                                                }} style={{ margin: "5px" }}>Print</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>

                                        : <div>No Records</div>
                                }
                                <button style={{ marginTop: "20px" }} onClick={() => {
                                    setShowGeneratedDocuments(false);
                                }}>Close</button>
                            </div>
                        </Center>
                    </ChakraProvider>
                </Popup>
            </div>
        </div>
    )
}

export default Shipment;
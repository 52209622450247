import "./Questions.scss";
import HomeNavbar from "../../../components/homeNavbar/HomeNavbar";
import HomeFooter from "../../../components/homeFooter/HomeFooter";
import HomeSidebar from "../../../components/sidebar/HomeSidebar";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseconfig";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useEffect } from "react";
import Question from "./Question";
import { Helmet } from "react-helmet-async";


const Questions = ({ active, userMetadata }) => {

    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        var btnContainer = document.getElementById(active);

        if (btnContainer !== null)
            btnContainer.classList.toggle("active");
    }, [active]);


    if (!user) {
        return (
            <div className="questions">
            <Helmet>
                <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
            </Helmet>
                <HomeNavbar />
                <div className="questionsContainer" style={{paddingTop: "80px", display: "flex", flex: "6"}}>
                    <HomeSidebar/>
                    <Question />
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="questions">
            <Helmet>
                <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
            </Helmet>
                <Sidebar />
                <div className="questionsContainer" style={{flex: "6"}}>
                    <Navbar userMetadata={userMetadata} />
                    <Question />
                </div>
            </div>
        )
    }
}

export default Questions;
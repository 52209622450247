import "./Feedback.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import Popup from "../../components/popup/Popup";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { auth, db } from "../../firebaseconfig";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, onSnapshot, query, where, orderBy, doc, getDocs, setDoc, updateDoc, arrayUnion, increment } from "firebase/firestore";

const Feedback = ({ type, userMetadata }) => {

    const storage = getStorage();
    const [userRows, setUserRows] = useState([]);
    const [user] = useAuthState(auth);
    const [openPopup, setOpenPopup] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState("");
    const [feedbackDetails, setFeedbackDetails] = useState("");
    const [feedbackImages, setFeedbackImages] = useState([]);

    const [isHoveringCloseButton, setisHoveringCloseButton] = useState(false);
    const handleMouseEnterCloseButton = () => {
        setisHoveringCloseButton(true);
    };
    const handleMouseLeaveCloseButton = () => {
        setisHoveringCloseButton(false);
    };

    const [isHoveringConnectButton, setisHoveringConnectButton] = useState(false);
    const handleMouseEnterConnectButton = () => {
        setisHoveringConnectButton(true);
    };
    const handleMouseLeaveConnectButton = () => {
        setisHoveringConnectButton(false);
    };


    var currentUserRows = [];
    useEffect(() => {

        var halfYearAgo = new Date();
        halfYearAgo.setDate(halfYearAgo.getDate() - 180);
        
        const feedbackDocsCollection = collection(db, "feedback");
        const feedbackDocsQuery = query(feedbackDocsCollection, 
            where("uid", "==", user.uid), 
            where("created_at", ">=", halfYearAgo),
            orderBy("created_at"));

        var no = 0;
        onSnapshot(feedbackDocsQuery, (snapshot) => {
            currentUserRows = Object.assign([], currentUserRows);
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    no++;
                    //if(currentUserRows.filter(e => e.id === change.doc.id).length === 0){
                    currentUserRows.push({
                        id: change.doc.id,
                        no: no,
                        title: change.doc.data().title,
                        details: change.doc.data().details,
                        status: change.doc.data().status,
                        created_at : change.doc.data().created_at
                    });
                    //}
                }

                if (change.type === "modified") {
                    currentUserRows = currentUserRows.map(obj => {
                        if (obj.id === change.doc.id) {
                            return {
                                ...obj,
                                title: change.doc.data().title,
                                details: change.doc.data().details,
                                status: change.doc.data().status,
                                created_at : change.doc.data().created_at
                            };
                        }
                        return obj;
                    });
                }

                if (change.type === "removed") {
                    currentUserRows = currentUserRows.filter(item => item.id !== change.doc.id);
                }

            }
            );

            setUserRows(currentUserRows);
        });
    }, []);

    
    async function createFeedback() {
        if(feedbackDetails.length < 30){
            alert("Please describe with more than 30 characters.");
            return;
        }

        if (feedbackDetails !== "") {
            var downloadURL = [];
            if (feedbackImages.length > 0) {
                for(let i = 0; i < feedbackImages.length; i++){
                    const bucket = "gs://tactibots-1605018905444.appspot.com/feedback/" + user.uid + "/" + feedbackImages[i].size+ "_" + encodeURIComponent(feedbackImages[i].name);
                    const storageRef = ref(storage, bucket);
                    await uploadBytes(storageRef, feedbackImages[i]);
                    downloadURL.push(
                        {
                            name: feedbackImages[i].name,
                            link: await getDownloadURL(storageRef)
                        });
                }
            }

            var newFeedback = {
                uid: user.uid,
                title: feedbackTitle,
                details: feedbackDetails,
                images: downloadURL,
                created_at: new Date(),
                updated_at : new Date(),
                status: "open",
                progress:[{
                    title: "Ticket Created",
                    description: "Ticket is pending review from TactiBots.",
                    created_at: new Date()
                }],
                chat: []
            };

            await setDoc(doc(collection(db, "feedback")), newFeedback);

            setFeedbackImages([]);
            setFeedbackDetails("");
            setFeedbackTitle("");
            setOpenPopup(false); 
        }

    }


    return (
        <div className="feedback">
            <Sidebar />
            <div className="feedbackContainer">
                <Navbar userMetadata={userMetadata} />
                <div className="top">
                    <div className="feedbackTitle">
                        <p>Feedback</p>
                    </div>
                    <div className="feedbackSubTitle">
                        <p>TactiBots are currently in BETA testing.</p>
                        <p>We appreciate all your feedback!</p>
                        <br></br>
                        <p>Kindly let us know your thoughts and we will try to improve.</p>
                        <p>We're happy to have you as our user!</p>
                    </div>

                    <button className="feedbackButton" onClick={() => {
                        var openCount = 0;
                        for(let i = 0; i < userRows.length; i++){
                            if(userRows[i].status === "open")
                                openCount++;
                        }

                        if(openCount < 10)
                            setOpenPopup(true);
                        else
                            alert("We only allow Maximum of 10 Feedback with status 'Open' at a time.")
                        }}>Add a new Feedback</button>
                </div>
                <Datatable type="feedback" className="datatable" overrideDataRow={userRows} />

                <Popup className="popupContainer" title={"Add a New Feedback"} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    <div style={{ paddingLeft: "20px", maxWidth: "500px", width:"500px" }}>
                        <div className="top" style={{
                            paddingBottom: "30px", paddingTop: "10px"
                        }}>
                            <div>Title : </div>
                            <input onInput={e => setFeedbackTitle(e.target.value)}></input>
                            <div>Details : </div>
                            <textarea style={{ height: "300px", width: "90%", overflowWrap: "break-word", wordWrap: "break-word", wordBreak: "break-all" }} className="feedbackForm" 
                            onInput={e => setFeedbackDetails(e.target.value)}></textarea>

                            <div>
                                <p>Attach maximum of 6 images with size of 1.0MB.</p>
                                <input multiple type="file" style={{ width: "100%", marginBottom: "10px" }}
                                    accept=".jpg, .jpeg, .png"
                                    onChange={(e) => {
                                        var tempArray = Object.assign([], feedbackImages);
                                        for (let i = 0; i < e.target.files.length; i++) {
                                            // Maximum 6 images
                                            if(tempArray.length >= 6)
                                                break;

                                            if(e.target.files[i].size >= 2000000){
                                                alert("Please insert a Smaller Size image.");
                                                return;
                                            }

                                            // Check if existed based on Name and Size
                                            if(Boolean(tempArray.find(x => x.size === e.target.files[i].size && x.name === e.target.files[i].name))){

                                            }
                                            else{
                                                tempArray.push(e.target.files[i]);
                                            }
                                        }

                                        setFeedbackImages(tempArray);
                                    }}>
                                </input>
                                <div style={{ display: "flex", maxWidth: "480px", flexWrap: "wrap"}}>
                                {
                                    Array.from(feedbackImages).map(item => {
                                        return (
                                            <div key={item.name + "_" + item.size}>
                                                <p>{item.name}</p>
                                                <img
                                                    style={{ padding: "5px", width: "150px" }}
                                                    src={item ? URL.createObjectURL(item) : null} />
                                            </div>
                                        )
                                    })
                                }</div>
                            </div>
                        </div>

                        <div style={{ color: "gray" }}>We're thankful for your feedback!</div>
                        <div style={{ paddingBottom: "20px", color: "gray" }}>Kindly provide as much details as possible.</div>

                        <div className="bottom" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                            <div
                                onMouseEnter={handleMouseEnterCloseButton}
                                onMouseLeave={handleMouseLeaveCloseButton}
                                style={{
                                    transition: "all 0.2s ease", padding: "10px", textAlign: "center", cursor: "pointer", WebkitBoxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px",
                                    backgroundColor: isHoveringCloseButton ? "#e4e4e4" : "white"
                                }} onClick={() => {setOpenPopup(false); setFeedbackImages([]);}}>
                                Close
                            </div>
                            <div
                                onMouseEnter={handleMouseEnterConnectButton}
                                onMouseLeave={handleMouseLeaveConnectButton}
                                style={{
                                    transition: "all 0.2s ease", padding: "10px", textAlign: "center", cursor: "pointer", WebkitBoxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px",
                                    backgroundColor: isHoveringConnectButton ? "#c3e2fb" : "#a1d5fe"
                                }} onClick={() => { createFeedback();}} >
                                Submit
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        </div>
    )
}

export default Feedback;
import "./Blog.scss";
import HomeNavbar from "../../../components/homeNavbar/HomeNavbar";
import CarouselComp from "../../../components/carousel/CarouselComp";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AccessTime } from "@mui/icons-material";
import HomeFooter from "../../../components/homeFooter/HomeFooter";
import { Helmet } from "react-helmet-async";

const Blog = ({ active, userMetadata }) => {

    const [searchString, setSearchString] = useState("");
    const [categoryString, setCategoryString] = useState("");
    const [currentBlogs, setCurrentBlogs] = useState([]);


    useEffect(() => {
        if (categoryString === "")
            setCurrentBlogs(blogs);
        else
            setCurrentBlogs(blogs.filter(item => item.category === categoryString));

    }, [categoryString]);


    const blogs = [
        {
            id: 5,
            title: "Shopee Comission 2023 Jan 10",
            created_at: "2022 Dec 22, 1:48pm",
            author: "TactiBots",
            category: "Platform News",
            image: "https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fplatform_news%2Fshopee%2Fcommission_2023_01_10%2Fthumbnail.PNG?alt=media&token=7a87af3d-70e3-412e-96e1-5f25676af5a5",
            link: "/blogs/platform_news/shopee_commission_2023_01_10"
        },
        {
            id: 4,
            title: "Shopee Order Income",
            created_at: "2022 Nov 29, 1:52pm",
            author: "TactiBots",
            category: "Seller Tips",
            image: "https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshopee_order_income%2Fthumbnail.PNG?alt=media&token=7442caff-9dc2-4774-a607-a425638f534d",
            link: "/blogs/seller_tips/shopee_order_income"
        },
        {
            id: 3,
            title: "Barcode Scanner",
            created_at: "2022 Nov 17, 8:58am",
            author: "TactiBots",
            category: "TactiBots Tutorials",
            image: "https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Ftactibots_tutorials%2Fbarcode_scanner%2Fthumbnail.PNG?alt=media&token=be5be047-bfda-478f-bc42-bc614ba2e051",
            link: "/blogs/tactibots_tutorials/barcode_scanner"
        },
        {
            id: 2,
            title: "Order Report",
            created_at: "2022 Nov 13, 5:12pm",
            author: "TactiBots",
            category: "TactiBots Tutorials",
            image: "https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Ftactibots_tutorials%2Forder_report%2Fthumbnail.PNG?alt=media&token=08807829-92dd-4ca4-9282-c99cac7a34f5",
            link: "/blogs/tactibots_tutorials/order_report"
        },
        {
            id: 1,
            title: "Shipping Overcharge",
            created_at: "2022 Nov 11, 6:38pm",
            author: "TactiBots",
            category: "Seller Tips",
            image: "https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshipping_overcharge%2Fthumbnail.PNG?alt=media&token=0329fb7c-977f-4902-9b64-1e36396f3858",
            link: "/blogs/seller_tips/shipping_overcharge"
        },
    ];

    function toggleActiveCategoryEvent(event) {
        const allCategoriesCategory = document.getElementById("allCategories");
        const platformNewsCategory = document.getElementById("platformNews");
        const sellerTipsCategory = document.getElementById("sellerTips");
        const tactibotsTutorialsCategory = document.getElementById("tactibotsTutorials");
        switch (event) {
            case "allCategories":
                allCategoriesCategory.classList.toggle("active");
                platformNewsCategory.classList.remove("active");
                sellerTipsCategory.classList.remove("active");
                tactibotsTutorialsCategory.classList.remove("active");
                break;
            case "platformNews":
                allCategoriesCategory.classList.remove("active");
                platformNewsCategory.classList.toggle("active");
                sellerTipsCategory.classList.remove("active");
                tactibotsTutorialsCategory.classList.remove("active");
                break;
            case "sellerTips":
                allCategoriesCategory.classList.remove("active");
                platformNewsCategory.classList.remove("active");
                sellerTipsCategory.classList.toggle("active");
                tactibotsTutorialsCategory.classList.remove("active");
                break;
            case "tactibotsTutorials":
                allCategoriesCategory.classList.remove("active");
                platformNewsCategory.classList.remove("active");
                sellerTipsCategory.classList.remove("active");
                tactibotsTutorialsCategory.classList.toggle("active");
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <div className="homeContainer">
                <Helmet>
                    <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                    <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
                </Helmet>
                <HomeNavbar active={active} userMetadata={userMetadata} />
                <div className="middle">
                    <div className="blogContainer" name="blogContainer">
                        <div className="carousel">
                            <CarouselComp items={blogs.filter(item => item.id === 1 || item.id === 4)}>
                            </CarouselComp>
                        </div>
                        <div className="filterContainer">
                            <div className="filter search">
                                <h3>Search : </h3>
                                <input onInput={e => setSearchString(e.target.value)}></input>
                            </div>
                            <div className="filter category">
                                <h3>Category : </h3>
                                <div className="categories">
                                    <p className="categoryButton active" id="allCategories" onClick={() => { toggleActiveCategoryEvent("allCategories"); setCategoryString(""); }}>
                                        All ({blogs.length})
                                    </p>
                                    <p className="categoryButton" id="platformNews" onClick={() => { toggleActiveCategoryEvent("platformNews"); setCategoryString("Platform News"); }}>
                                        Platform News ({blogs.filter(item => item.category === "Platform News").length})
                                    </p>
                                    <p className="categoryButton" id="sellerTips" onClick={() => { toggleActiveCategoryEvent("sellerTips"); setCategoryString("Seller Tips"); }}>
                                        Seller Tips ({blogs.filter(item => item.category === "Seller Tips").length})
                                    </p>
                                    <p className="categoryButton" id="tactibotsTutorials" onClick={() => { toggleActiveCategoryEvent("tactibotsTutorials"); setCategoryString("TactiBots Tutorials"); }}>
                                        TactiBots Tutorials ({blogs.filter(item => item.category === "TactiBots Tutorials").length})
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }}></div>
                        <div className="recentBlogs">
                            {
                                Array.from(currentBlogs).map((item, i, row) => {
                                    return (
                                        <div className="blog" key={item.id}>
                                            <Link to={item.link} style={{ textDecoration: "none", color: "#0071bd" }}>
                                                <img src={item.image} ></img>
                                                <h3 className="title">{item.title}</h3>
                                                <h4 className="category">{item.category}</h4>
                                                <div className="time">
                                                    <AccessTime></AccessTime>
                                                    {item.created_at}
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    )
}

export default Blog;
import "./sidebar.scss";
import DashboardIcon from '@mui/icons-material/Dashboard';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseconfig";
import { useNavigate } from "react-router-dom";
import { AccountBox, AccountCircle, Assessment, Assignment, Book, DocumentScanner, Feedback, PrivacyTip, QuestionAnswer, Tag } from "@mui/icons-material";

const HomeSidebar = () => {
    const { dispatch } = useContext(DarkModeContext);
    return (
        <div className="sidebar">
            <div className="center">
                <ul>
                    <p className="title">FORUM</p>
                    <Link to="/questions" style={{ textDecoration: "none" }}>
                        <li>
                            <QuestionAnswer className="icon" />
                            <span>Questions</span>
                        </li>
                    </Link>     
                    <Link to="/tags" style={{ textDecoration: "none" }}>
                        <li>
                            <Tag className="icon" />
                            <span>Tags</span>
                        </li>
                    </Link>
                </ul>
            </div>
        </div>
    )
}

export default HomeSidebar;
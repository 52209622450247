import { Image as ImageIcon, FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatQuote, InsertLink} from '@mui/icons-material'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import { useEffect, useRef, useState } from 'react';
import "./RichTextEditor.scss";

const RichTextEditor = ({ setDesc, setImages, content }) => {
    const imageInputFile = useRef(null);
    const [questionImages, setQuestionImages] = useState([]);

    const editor = useEditor({
        extensions: [
            StarterKit,
            Image.configure({
                HTMLAttributes: {
                    class: "tiptap-image-class",
                },
                inline: true,
            }),
            Link.configure({
                HTMLAttributes: {
                  class: 'tiptap-link-class',
                },
              })
        ],
        content: content,
        onUpdate: ({ editor }) => {
            const html = editor.getHTML();
            setDesc(html);
        }
    })

    const [formatValue, setFormatValue] = useState("");

    if (!editor) {
        return null
    }
    else {
        return (
            <div className="tiptapContainer">
                <div className="menuBar">
                    <FormatBold
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleBold()
                                .run()
                        }
                        className={"muiIcon " + editor.isActive('bold') ? 'muiIcon is-active' : 'muiIcon'}
                    />
                    <FormatItalic
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        disabled={
                            !editor.can()
                                .chain()
                                .focus()
                                .toggleItalic()
                                .run()
                        }
                        className={"muiIcon " + editor.isActive('italic') ? 'muiIcon is-active' : 'muiIcon'}
                    />
                    <div className="formatDropDown">
                        <select value={formatValue} onChange={(e) => {
                            switch (e.target.value) {
                                case "h1":
                                    editor.chain().focus().toggleHeading({ level: 1 }).run();
                                    break;
                                case "h2":
                                    editor.chain().focus().toggleHeading({ level: 2 }).run();
                                    break;
                                case "h3":
                                    editor.chain().focus().toggleHeading({ level: 3 }).run();
                                    break;
                                case "p":
                                    editor.chain().focus().setParagraph().run();
                                    break;
                                default:
                                    break;
                            }
                            setFormatValue("");
                        }} onFocus={() => { }}>
                            <option value="" hidden>Format</option>
                            <option className="h1" value="h1">Heading 1</option>
                            <option className="h2" value="h2">Heading 2</option>
                            <option className="h3" value="h3">Heading 3</option>
                            <option className="p" value="p">Paragraph</option>
                        </select>
                    </div>
                    <FormatQuote
                        onClick={() => editor.chain().focus().toggleBlockquote().run()}
                        className={"muiIcon " + editor.isActive('blockquote') ? 'muiIcon is-active' : 'muiIcon'}
                    />
                    <FormatListBulleted
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        className={"muiIcon " + editor.isActive('bulletList') ? 'muiIcon is-active' : 'muiIcon'}
                    />
                    <FormatListNumbered
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        className={"muiIcon " + editor.isActive('orderedList') ? 'muiIcon is-active' : 'muiIcon'}
                    />
                    <ImageIcon className={"muiIcon"}
                        onClick={() => { imageInputFile.current.click(); }}
                    />
                    <InsertLink className={"muiIcon"}
                        onClick={() => {
                            editor.commands.unsetLink();
                        }}
                    />
                    <input multiple type="file" ref={imageInputFile} style={{ display: 'none', width: "100%", marginBottom: "10px" }}
                        accept=".jpg, .jpeg, .png"
                        onClick={(event)=> { 
                            event.currentTarget.value = null
                        }}
                        onChange={async (e) => {
                            var tempArray = Object.assign([], questionImages);
                            for (let i = 0; i < e.target.files.length; i++) {
                                if (e.target.files[i].size >= 2000000){
                                    alert("Please insert a Smaller Size image.");
                                    return;
                                }

                                // Check if existed based on Name and Size
                                if (Boolean(tempArray.find(x => x.size === e.target.files[i].size && x.name === e.target.files[i].name))) {

                                }
                                else {
                                    tempArray.push(e.target.files[i]);
                                }
                            }
                            
                            setQuestionImages(tempArray);
                            setImages(tempArray);

                            if (tempArray[tempArray.length - 1]) {
                                editor.chain().focus().setImage({ src: URL.createObjectURL(tempArray[tempArray.length - 1]), alt: tempArray[tempArray.length - 1].size + "_" + encodeURIComponent(tempArray[tempArray.length - 1].name)}).run()
                            }
                        }}>
                    </input>
                </div>
                <EditorContent editor={editor} />
            </div>
        )
    }
}

export default RichTextEditor;
import { Navigate, useLocation } from "react-router-dom";
import Commission2023Jan10 from "../blogs/platformNews/Shopee/Commission2023Jan10";
import ShippingOverchargeBlog from "../blogs/sellerTips/shippingOvercharge/ShippingOverchargeBlog";
import ShopeeOrderIncome from "../blogs/sellerTips/shopee/ShopeeOrderIncome";
import BarcodeScannerBlog from "../blogs/tactibotsTutorials/barcodeScanner/BarcodeScannerBlog";
import OrderReportBlog from "../blogs/tactibotsTutorials/orderReport/OrderReportBlog";

const SingleBlog = ({ userMetadata }) => {

    const location = useLocation();
    const url = location.pathname;

    if (url.split('/').length !== 4) {
        return (<Navigate replace to="/" />)
    }

    const category = url.split('/')[2];
    const blog = url.split('/')[3];

    switch (category) {
        case "platform_news":
            switch (blog) {
                case "shopee_commission_2023_01_10":
                    if (typeof (userMetadata) === "undefined")
                        return (<Commission2023Jan10 />);
                    else
                        return (<Commission2023Jan10 userMetadata={userMetadata} />);
                default:
                    return (<Navigate replace to="/" />);
            }
        case "seller_tips":
            switch (blog) {
                case "shipping_overcharge":
                    if (typeof (userMetadata) === "undefined")
                        return (<ShippingOverchargeBlog />);
                    else
                        return (<ShippingOverchargeBlog userMetadata={userMetadata} />);
                case "shopee_order_income":
                    if (typeof (userMetadata) === "undefined")
                        return (<ShopeeOrderIncome />);
                    else
                        return (<ShopeeOrderIncome userMetadata={userMetadata} />);
                    
                default:
                    return (<Navigate replace to="/" />);
            }
        case "tactibots_tutorials":
            switch (blog) {
                case "order_report":
                    if (typeof (userMetadata) === "undefined")
                        return (<OrderReportBlog />);
                    else
                        return (<OrderReportBlog userMetadata={userMetadata} />);
                case "barcode_scanner":
                    if (typeof (userMetadata) === "undefined")
                        return (<BarcodeScannerBlog />);
                    else
                        return (<BarcodeScannerBlog userMetadata={userMetadata} />);
                default:
                    return (<Navigate replace to="/" />);
            }
        default:
            return (<Navigate replace to="/" />);
    }
}

export default SingleBlog;
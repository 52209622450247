import "./featured.scss";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useEffect, useState } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebaseconfig";
import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";

const Featured = ({ todayAmount, yesterdayOrder, yesterdayAmount }) => {

    const [positive, setPositive] = useState(true);
    const [reachTarget, setReachTarget] = useState(true);
    const [targetAmount, setTargetAmount] = useState(3000);
    const [percentage, setPercentage] = useState(0);
    const [user] = useAuthState(auth);

    function getTargetValue() {
        const userDocsCollection = collection(db, "user");
        var userQuery = query(userDocsCollection,
            where("uid", "==", user.uid));

        const unsubscribe = onSnapshot(userQuery, (snapshot) => {
            
            snapshot.docChanges().forEach(async (data) => {
                if(typeof(data.doc.data().target_value) === "number")
                {
                    setTargetAmount(data.doc.data().target_value);
                }
                else
                {
                    setTargetAmount(3000);
                    await setDoc(doc(db, "user", user.uid), {
                        target_value: 3000
                      }, {
                        merge: true
                      });
                }
            });

            
            unsubscribe();
        });

    };

    getTargetValue();

    async function handleChange(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value !== '' 
        && re.test(e.target.value) 
        && Number(e.target.value) > 0) {

            setTargetAmount(Number(e.target.value));
            await setDoc(doc(db, "user", user.uid), {
                target_value: Number(e.target.value)
              }, {
                merge: true
              });
        }
        else{
            return;
        }

        var currentAmount = Number(String(todayAmount).replace(/,/g, ''));
        if (currentAmount > e.target.value)
            setReachTarget(true);
        else
            setReachTarget(false);
        
        var currentPercentage = 0;
        currentPercentage = currentAmount / e.target.value * 100;
        setPercentage(Number(currentPercentage.toFixed(0)));
    }

    var hourago = new Date(((Math.floor(new Date().getTime() / 1000)) - Math.floor(new Date().getTime() / 1000) % 3600) * 1000);

    useEffect(() => {
        var currentAmount = Number(String(todayAmount).replace(/,/g, ''));
        if (currentAmount > targetAmount)
            setReachTarget(true);
        else
            setReachTarget(false);
        
        var currentPercentage = 0;
        currentPercentage = currentAmount / targetAmount * 100;
        setPercentage(Number(currentPercentage.toFixed(0)));


        if (Number(String(todayAmount).replace(/,/g, '')) > Number(String(yesterdayAmount).replace(/,/g, '')))
            setPositive(true);
        else
            setPositive(false);

    }, [todayAmount, yesterdayAmount, targetAmount]);



    return (
        <div className="featured">
            <div className="top">
                <h1 className="title">TOTAL REVENUE</h1>
                <MoreVertIcon fontSize="small" />
            </div>
            <div className="bottom">
                <div className="featuredChart">
                    <CircularProgressbar value={percentage} text={percentage + "%"} strokeWidth={5} />
                </div>

                <p className="title">Total Sales for Today</p>
                <p className="time">As of {new Date().toLocaleTimeString()}</p>
                <p className="amount">RM {todayAmount}</p>

                <div className="summary">
                    <div className="item">
                        <div className="itemTitle">Today Target</div>
                        {
                            reachTarget ?
                                <div className="itemResult positive">
                                    <ArrowDropUpIcon fontSize="small" />
                                    <div className="resultAmount">RM</div>
                                    <input
                                        className="targetInput"
                                        type="text"
                                        value={targetAmount}
                                        onChange={handleChange}
                                    />
                                </div>
                                :

                                <div className="itemResult negative">
                                    <ArrowDropDownIcon fontSize="small" />
                                    <div className="resultAmount">RM</div>
                                    <input
                                        className="targetInput"
                                        type="text"
                                        value={targetAmount}
                                        onChange={handleChange}
                                    />
                                </div>
                        }
                    </div>
                    <div className="item">
                        <div className="itemTitle">As of {hourago.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} Yesterday</div>
                        {
                            positive ?
                                <div className="itemResult positive">
                                    <ArrowDropUpIcon fontSize="small" />
                                    <div className="resultAmount">RM {yesterdayAmount}</div>
                                </div>
                                :
                                <div className="itemResult negative">
                                    <ArrowDropDown fontSize="small" />
                                    <div className="resultAmount">RM {yesterdayAmount}</div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Featured;
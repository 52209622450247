import "./Home.scss";
import HomeNavbar from "../../../components/homeNavbar/HomeNavbar";
import HomeFooter from "../../../components/homeFooter/HomeFooter";
import { Helmet } from "react-helmet-async";


const Home = ({ active }) => {

    return (
        <div>
            <div className="homeContainer">
                <Helmet>
                    <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                    <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
                </Helmet>
                <HomeNavbar active={active} />
                <div className="middle">
                    <div className="middleHomeContainer" name="middleHomeContainer">
                        <div className="middleHomeTop">
                            <h2>Where Businesses Learn, Share & Grow</h2>
                            <div>FREE E-Commerce System, Question and Answer Website for all Businesses</div>
                            <img src={require('../../../materials/login_background.png')} alt="" />
                        </div>
                        <h1>Features</h1>
                        <div className="features">
                            <div className="feature">
                                <div className="title">
                                    Barcode Scanner
                                </div>
                                <div className="details">
                                    Keep track of Parcels
                                </div>
                            </div>
                            <div className="feature">
                                <div className="title">
                                    Profit Loss
                                </div>
                                <div className="details">
                                    Estimate your E-Commerce Profit with Cost of Goods Sold included
                                </div>
                            </div>
                            <div className="feature">
                                <div className="title">
                                    Inventory
                                </div>
                                <div className="details">
                                    Manage Stock
                                </div>
                            </div>
                            <div className="feature">
                                <div className="title">
                                    Order Checking
                                </div>
                                <div className="details">
                                    Detect Overcharged, Return Refund, Abnormal Orders
                                </div>
                            </div>
                            <div className="feature">
                                <div className="title">
                                    Question and Answer
                                </div>
                                <div className="details">
                                    Learn, Share and Grow along with other Businesses
                                </div>
                            </div>
                        </div>
                        <h1>Our Partners</h1>
                        <div className="partners">
                            <div className="partner">
                                <div className="title">
                                    Lazada
                                </div>
                                <div className="details">
                                    <img style={{ maxWidth: "100%", maxHeight: "90px" }} src={require('../../../materials/lazada_logo.png')} alt="" />
                                </div>
                            </div>
                            <div className="partner">
                                <div className="title">
                                    Shopee
                                </div>
                                <div className="details">
                                    <img style={{ maxWidth: "100%", maxHeight: "90px" }} src={require('../../../materials/shopee_logo.png')} alt="" />
                                </div>
                            </div>
                            <div className="partner">
                                <div className="title">
                                    Tiktok
                                </div>
                                <div className="details">
                                    <img style={{ maxWidth: "100%", maxHeight: "90px" }} src={require('../../../materials/tiktok_logo.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    )
}

export default Home;
import "../../Blogs.scss";
import HomeNavbar from "../../../../../components/homeNavbar/HomeNavbar";
import { AccessTime, RateReview, Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
import BlogBottomLikes from "../../../blog/BlogBottomLikes";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../../../firebaseconfig";
import { collection, doc, documentId, increment, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";


const ShippingOverchargeBlog = () => {

    const [user, loading, error] = useAuthState(auth);
    const blogDoc = doc(db, "count", "blogs_st_shipping_overcharge");
    const [documentDetails, setDocumentDetails] = useState({});
    useEffect(() => {

        async function increaseView() {
            await setDoc(blogDoc, {
                view_count: increment(1)
            }, { merge: true });
        }

        increaseView();

        async function getDocumentDetails() {
            const docCollection = collection(db, "count");
            const docQuery = query(docCollection,
                where(documentId(), "==", "blogs_st_shipping_overcharge"));

            onSnapshot(docQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {

                    setDocumentDetails(change.doc.data());
                })
            });
        }

        getDocumentDetails();
    }, []);

    return (
        <div className="homeContainer">
            <Helmet>
                <title>TactiBots | Shipping Overcharge</title>
                <meta property="description" content="If Courier Charged Shipping Fee is greater than Buyer Paid Shipping Fee, Shipping Overcharge happens." />
                <meta property="og:description" content="If Courier Charged Shipping Fee is greater than Buyer Paid Shipping Fee, Shipping Overcharge happens." />
                <meta property="og:url" content="https://tactibots.com/blogs/seller_tips/shipping_overcharge"></meta>
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshipping_overcharge%2Fthumbnail.PNG?alt=media&token=0329fb7c-977f-4902-9b64-1e36396f3858"></meta>
            </Helmet>
            <HomeNavbar active={"blogContainer"} />
            <div className="singleBlogContainer" name="blogContainer">
                <div className="info">
                    <div className="time">
                        <AccessTime></AccessTime>
                        2022 November 11, 6:38pm
                    </div>
                    <div className="author">
                        <RateReview></RateReview>
                        TactiBots
                    </div>
                    <div className="time">
                        <Visibility></Visibility>
                        {
                            typeof (documentDetails) !== "undefined" ? documentDetails.view_count : 0
                        } views
                    </div>
                </div>
                <h1 className="title">Shipping Overcharge</h1>
                <img className="thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshipping_overcharge%2Fthumbnail.PNG?alt=media&token=0329fb7c-977f-4902-9b64-1e36396f3858"
                    alt=""
                />
                <div className="content">
                    <h3>Ever wonder why your order could have negative income?</h3>
                    <p>This could happened due to Overcharge Shipping Fee.</p>
                    <p>Seller have to understand that platforms like Shopee Lazada doesn't in charge of the shipping fee.
                        The platforms is <b>paying shipping fee on behalf of sellers</b>. The responsibility of packaging lies on Seller themselves.
                    </p>
                    <p>If Courier Charged Shipping Fee is greater than Buyer Paid Shipping Fee, Shipping Overcharge happens.
                        <br></br>Vice versa, Shipping Undercharge happens.
                    </p>
                    <h3>Example for Shopee Order:</h3>
                    <img
                        src={require('../../../../../pages/general/blogs/sellerTips/shippingOvercharge/shipping_overcharged_shopee.PNG')}
                        alt=""
                    />
                    <p>Notice that the Shipping Subtotal is <b>-RM1.70</b>.<br></br>
                        The Shipping Fee paid by buyer is RM 6.30 while the actual charge is <b>-RM8.00</b>.</p>
                    <h3>Example for Lazada Order:</h3>
                    <img
                        src={require('../../../../../pages/general/blogs/sellerTips/shippingOvercharge/shipping_overcharged_lazada.PNG')}
                        alt=""
                    />
                    <p>For Lazada's order, 'Wrong Weight Adjustment' is equivalent to Shipping Overcharge.<br></br>
                        For this order, the Overcharged is a whoping <b>-RM34.10</b>.</p>

                    <p>Note that sellers may file a claim with respective platforms within the time limit.<br></br><b>The limit varies from 30 days to 60 days depending on the courier and platform.</b></p>

                    <p>To ensure sellers have minimum Overcharged cases, sellers are recommended to :</p>
                    <ul>
                        <li>Ensure the necessary field (i.e dimensions of the product) is filled in so that the correct weight is used to calculate the shipping fee</li>
                        <li>Ensure that the weight and dimension of the product inputted in the product listing are filled up correctly (e.g. sellers have included the packaging into the dimension input) so that the shipping fee is calculated correctly.</li>
                        <li>Ensure all products have the same settings for the shipping fee coverage (whether if sellers cover the shipping fee)</li>
                        <li>Ensure that the default shipping address is the same as the actual shipping address.</li>
                    </ul>

                    <h3>How to detect these Orders?</h3>
                    <p>TactiBots provide FREE abnormal order detection features.<br></br>
                        Click
                        <Link to="/blogs/tactibots_tutorials/order_report" style={{ whiteSpace: "nowrap", marginLeft: "5px", marginRight: "5px" }}>
                            here
                        </Link>
                        to learn how.</p>
                </div>
                <BlogBottomLikes document_id={"blogs_st_shipping_overcharge"} document_details={documentDetails} user={user} />
            </div>
        </div>
    )
}

export default ShippingOverchargeBlog;
import "./Questions.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../firebaseconfig";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, doc, getDoc, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import moment from "moment/moment";


const Question = () => {

    const navigate = useNavigate();

    const [user, loading, error] = useAuthState(auth);
    const [totalQuestionCount, setTotalQuestionCount] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [searchQuestions, setSearchQuestions] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [finalSearchString, setFinalSearchString] = useState("");

    useEffect(() => {
        const questionDocsCollection = collection(db, "question");
        const questionDocsQuery = query(questionDocsCollection,
            orderBy("created_at"),
            limit(10));

        var currentQuestionsRow = [];
        onSnapshot(questionDocsQuery, (snapshot) => {

            snapshot.docChanges().forEach(async (change) => {
                var calculateChar = change.doc.data().question;
                calculateChar = calculateChar.replace("</p><p>", "</p><p> ").replace("&amp;", "&").replace("&lt;", "_").replace("&gt;", "_");

                var insideBracket = false;
                var questionPreview = "";
                for (let i = 0; i < calculateChar.length; i++) {
                    if (insideBracket === false && calculateChar[i] === "<") {
                        insideBracket = true;
                        continue;
                    }

                    if (insideBracket === true && calculateChar[i] === ">") {
                        insideBracket = false;
                        continue;
                    }

                    if (insideBracket === false) {
                        questionPreview += calculateChar[i];
                        if (questionPreview.length > 200) {
                            questionPreview += "...";
                            break;
                        }
                    }
                }

                if (change.type === "added") {
                    currentQuestionsRow.push({
                        id: change.doc.id,
                        like_count: change.doc.data().like_count,
                        answer_count: change.doc.data().answer_count,
                        view_count: change.doc.data().view_count,
                        marked_as_answer: change.doc.data().marked_as_answer,
                        title: change.doc.data().title,
                        tags: change.doc.data().tags,
                        question: questionPreview,
                        uid: change.doc.data().uid,
                        name: change.doc.data().name,
                        image: change.doc.data().image,
                        created_at: change.doc.data().created_at
                    });
                }


                if (change.type === "modified") {
                    currentQuestionsRow = currentQuestionsRow.map(obj => {
                        if (obj.id === change.doc.id) {
                            return {
                                ...obj,
                                like_count: change.doc.data().like_count,
                                answer_count: change.doc.data().answer_count,
                                view_count: change.doc.data().view_count,
                                marked_as_answer: change.doc.data().marked_as_answer,
                                title: change.doc.data().title,
                                tags: change.doc.data().tags,
                                question: questionPreview,
                                uid: change.doc.data().uid,
                                name: change.doc.data().name,
                                image: change.doc.data().image,
                                created_at: change.doc.data().created_at
                            };
                        }
                        return obj;
                    });
                }

                if (change.type === "removed") {
                    currentQuestionsRow = currentQuestionsRow.filter(item => item.id !== change.doc.id);
                }

            }
            );

            setQuestions(currentQuestionsRow);
        });

        async function getTotalCount(){
            const questionCountDoc = doc(db, "count", "questions");
            const questionCountSnapshot = await getDoc(questionCountDoc);

            if(questionCountSnapshot.exists){
                setTotalQuestionCount(questionCountSnapshot.data().total_count);
            }
        }
        getTotalCount();
        

    }, []);

    function toggleFilterEvent(event) {
        tabBtns.forEach(f => f.classList.remove('active'));

        const latestButton = document.getElementById("latestButton");
        const activeButton = document.getElementById("activeButton");
        const unansweredButton = document.getElementById("unansweredButton");
        switch (event) {
            case "latestButton":
                latestButton.classList.toggle("active");
                activeButton.classList.remove("active");
                unansweredButton.classList.remove("active");
                break;
            case "activeButton":
                latestButton.classList.remove("active");
                activeButton.classList.toggle("active");
                unansweredButton.classList.remove("active");
                break;
            case "unansweredButton":
                latestButton.classList.remove("active");
                activeButton.classList.remove("active");
                unansweredButton.classList.toggle("active");
                break;
            default:
                break;
        }
    };


    const tabBtns = document.querySelectorAll(".toggleButton");
    tabBtns.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            toggleFilterEvent(e.target.id);
        });
    });

    useEffect(() => {
        const timeOutId = setTimeout(() => setFinalSearchString(searchString), 1000);
        return () => clearTimeout(timeOutId);
    },[searchString]);

    useEffect(() =>{
        
        const searchStringArray = [];
        for(let i = 0; i < finalSearchString.split(' ').length; i++){
            searchStringArray.push(finalSearchString.split(' ')[i].toLowerCase());
        }

        const questionDocsCollection = collection(db, "question");
        const questionDocsQuery = query(questionDocsCollection,
            where("search_string_array", "array-contains-any", searchStringArray),
            orderBy("view_count", "desc"),
            limit(10));

        onSnapshot(questionDocsQuery, (snapshot) => {
            var currentQuestionsRow = [];
            snapshot.docChanges().forEach(async (change) => {
                var calculateChar = change.doc.data().question;
                calculateChar = calculateChar.replace("</p><p>", "</p><p> ").replace("&amp;", "&").replace("&lt;", "_").replace("&gt;", "_");

                var insideBracket = false;
                var questionPreview = "";
                for (let i = 0; i < calculateChar.length; i++) {
                    if (insideBracket === false && calculateChar[i] === "<") {
                        insideBracket = true;
                        continue;
                    }

                    if (insideBracket === true && calculateChar[i] === ">") {
                        insideBracket = false;
                        continue;
                    }

                    if (insideBracket === false) {
                        questionPreview += calculateChar[i];
                        if (questionPreview.length > 200) {
                            questionPreview += "...";
                            break;
                        }
                    }
                }

                if (change.type === "added") {
                    currentQuestionsRow.push({
                        id: change.doc.id,
                        like_count: change.doc.data().like_count,
                        answer_count: change.doc.data().answer_count,
                        view_count: change.doc.data().view_count,
                        title: change.doc.data().title,
                        tags: change.doc.data().tags,
                        question: questionPreview,
                        uid: change.doc.data().uid,
                        name: change.doc.data().name,
                        image: change.doc.data().image,
                        created_at: change.doc.data().created_at
                    });
                }


                if (change.type === "modified") {
                    currentQuestionsRow = currentQuestionsRow.map(obj => {
                        if (obj.id === change.doc.id) {
                            return {
                                ...obj,
                                like_count: change.doc.data().like_count,
                                answer_count: change.doc.data().answer_count,
                                view_count: change.doc.data().view_count,
                                title: change.doc.data().title,
                                tags: change.doc.data().tags,
                                question: questionPreview,
                                uid: change.doc.data().uid,
                                name: change.doc.data().name,
                                image: change.doc.data().image,
                                created_at: change.doc.data().created_at
                            };
                        }
                        return obj;
                    });
                }

                if (change.type === "removed") {
                    currentQuestionsRow = currentQuestionsRow.filter(item => item.id !== change.doc.id);
                }

            }
            );

            setSearchQuestions(currentQuestionsRow);
        });
    }, [finalSearchString]);


    return (
        <div className="questionContainer">
            <div className="topContainer">
                <h2>All Questions</h2>
                <button onClick={() => {
                    if (!user) {
                        navigate('/login');
                        alert("You must be logged in to ask a question on TactiBots.");
                    }
                    else {
                        navigate('/questions/ask');
                    }
                }}>Ask Question</button>
            </div>
            <div className="secondContainer">
                <div className="search">
                    <input
                        onChange={(e) => { setSearchString(e.target.value)}}
                        className="inputBox" placeholder="Search Questions ..."
                    />
                    <button>Search</button>
                </div>
            </div>
            <div className="secondContainer">
                {
                    finalSearchString.length === 0 ? 
                    <h2>{totalQuestionCount} questions</h2>
                    :
                    <h2>{searchQuestions.length} questions</h2>
                }
                <div className="buttonContainer">
                    <button className="toggleButton active" id="latestButton">Latest</button>
                    <button className="toggleButton" id="activeButton">Active</button>
                    <button className="toggleButton" id="unansweredButton">Unanswered</button>
                </div>
            </div>
            <div className="middleContainer">
                {
                    finalSearchString.length === 0 ? Array.from(questions).map((item, i, row) => {
                        return (
                            <div key={item.id}>
                                <div className="singleQuestion">
                                    <div className="left">
                                        <p className="item">{item.like_count} likes</p>
                                        <p className={typeof(item.marked_as_answer) !== "undefined" && item.marked_as_answer !== "" ? "item marked" : "item"} >{item.answer_count} answers</p>
                                        <p className="item">{item.view_count} views</p>
                                    </div>
                                    <div className="right">
                                        <Link to={"/questions/" + item.id + "/" + encodeURIComponent(item.title.toLowerCase())} style={{ textDecoration: "none", color: "#0071bd" }}>
                                            <h3>{item.title}</h3>
                                            <p>{item.question}</p>
                                        </Link>
                                        <div className="tags">

                                            {
                                                Array.from(item.tags).map((tag, j, k) => {
                                                    return (
                                                        <div key={tag} className="tag">
                                                            <Link to={"/tags/" + tag} style={{ textDecoration: "none", color: "#0071bd" }}>
                                                                {tag}
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom">
                                    <Link to={"/user/" + item.uid}>
                                        <div className="author" >
                                            <img src={item.image}/>
                                            <h3>{item.name}</h3>
                                        </div>
                                    </Link>
                                </div>
                                <p className="bottomTime">asked {moment(new Date(item.created_at.seconds * 1000)).fromNow()}</p>
                                <br></br>
                                <hr></hr>
                            </div>
                        )
                    }) : 
                    Array.from(searchQuestions).map((item, i, row) => {
                        return (
                            <div key={item.id}>
                                <div className="singleQuestion">
                                    <div className="left">
                                        <p className="item">{item.like_count} likes</p>
                                        <p className={typeof(item.marked_as_answer) !== "undefined" && item.marked_as_answer !== "" ? "item marked" : "item"} >{item.answer_count} answers</p>
                                        <p className="item">{item.view_count} views</p>
                                    </div>
                                    <div className="right">
                                        <Link to={"/questions/" + item.id} style={{ textDecoration: "none", color: "#0071bd" }}>
                                            <h3>{item.title}</h3>
                                            <p>{item.question}</p>
                                        </Link>
                                        <div className="tags">

                                            {
                                                Array.from(item.tags).map((tag, j, k) => {
                                                    return (
                                                        <div key={tag} className="tag">
                                                            <Link to={"/tags/" + tag} style={{ textDecoration: "none", color: "#0071bd" }}>
                                                                {tag}
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom">
                                    <Link to={"/user/" + item.uid}>
                                        <div className="author" >
                                            <img src={item.image}/>
                                            <h3>{item.name}</h3>
                                        </div>
                                    </Link>
                                </div>
                                <p className="bottomTime">asked {moment(new Date(item.created_at.seconds * 1000)).fromNow()}</p>
                                <br></br>
                                <hr></hr>
                            </div>
                        )
                    })
                    
                }
            </div>
        </div>
    )
}

export default Question;
import "./carouselComp.scss";
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";


const CarouselComp = ({ items }) => {


    if (typeof (items) === "undefined" || items.length === 0) {
        items = [{
            id: 1,
            image: "https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshipping_overcharge%2Fthumbnail.PNG?alt=media&token=0329fb7c-977f-4902-9b64-1e36396f3858",
            link: "blogs/seller_tips/shipping_overcharge"
        }, {
            id: 2,
            image: "https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshipping_overcharge%2Fthumbnail.PNG?alt=media&token=cc612afc-f548-45bf-9b52-35872f67d266",
            link: "blogs/seller_tips/shipping_overcharge"
        },
        ];
    }
    
    return (
        <div className="carouselContainer">
            <Carousel infiniteLoop={true}
                autoPlay={true} interval={6000}
                showStatus={false} showIndicators={false} showThumbs={false} className="carouselBody">
                {
                    Array.from(items).map((item, i, row) => {
                        return (
                            <div key={item.id}>
                                <Link to={item.link}>
                                    <div>
                                        <img src={item.image} ></img>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

export default CarouselComp;
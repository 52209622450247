import "./Product.scss"
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { auth, db } from "../../../firebaseconfig";
import { collection, onSnapshot, query, where, limit } from "firebase/firestore";

const Product = ({ type, userMetadata }) => {

    const [dataRows, setDataRows] = useState([]);
    const [finalDataRows, setFinalDataRows] = useState([]);
    var [filterString, setFilterString] = useState("");
    const [user] = useAuthState(auth);

    useEffect(() => {

        const inventoryCollection = collection(db, "inventory");
        var inventoryQuery = query(inventoryCollection,
            where("uid", "==", user.uid),
            limit(100));

         var finalDataRows = Object.assign([],finalDataRows);
        onSnapshot(inventoryQuery, (snapshot) => {

            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    const fullInventoryData = change.doc.data().inventory;
                    Object.keys(fullInventoryData).map((key, index) => {
                        finalDataRows.push({
                            id: change.doc.id + "_" + key + "_" + fullInventoryData[key].sku,
                            temp_id: change.doc.id,
                            image: fullInventoryData[key].image,
                            sku: fullInventoryData[key].sku,
                            item_name: fullInventoryData[key].item_name,
                            variation_name: fullInventoryData[key].variation_name,
                            quantity: fullInventoryData[key].quantity,  
                            sale_price: fullInventoryData[key].sale_price,
                            purchase_price: fullInventoryData[key].purchase_price,
                            created_at: fullInventoryData[key].created_at,
                        });

                        return true;
                    });
                }

                if (change.type === "modified") {
                    finalDataRows = finalDataRows.filter(item => item.temp_id !== change.doc.id);
                    const fullInventoryData = change.doc.data().inventory;
                    Object.keys(fullInventoryData).map((key, index) => {
                        finalDataRows.push({
                            id: change.doc.id + "_" + key + "_" + fullInventoryData[key].sku,
                            temp_id: change.doc.id,
                            image: fullInventoryData[key].image,
                            sku: fullInventoryData[key].sku,
                            item_name: fullInventoryData[key].item_name,
                            variation_name: fullInventoryData[key].variation_name,
                            quantity: fullInventoryData[key].quantity,
                            sale_price: fullInventoryData[key].sale_price,
                            purchase_price: fullInventoryData[key].purchase_price,
                            created_at: fullInventoryData[key].created_at,
                        });

                        return true;
                    });
                }

                if (change.type === "removed") {
                    finalDataRows = finalDataRows.filter(item => item.temp_id !== change.doc.id);
                }
            }
            );

            setDataRows(finalDataRows);
            setFinalDataRows(finalDataRows);
        });

    }, [user]);

    function handleChange(e) {
        setFilterString(e.target.value);

        if (String(e.target.value).length === 0) {
            setFinalDataRows(dataRows);
        }
        else {  
            var filteredRows = dataRows.filter(item => {
                const query = String(e.target.value).toLowerCase();
                return (
                    item.sku.toLowerCase().indexOf(query) >= 0 ||
                    item.item_name.toLowerCase().indexOf(query) >= 0 ||
                    item.variation_name.toLowerCase().indexOf(query) >= 0
                )
            });
            setFinalDataRows(filteredRows);
        }
    }

    return (
        <div className="product">
            <Sidebar />
            <div className="productContainer">
                <Navbar userMetadata={userMetadata}/>
                <div className="top">
                    <div className="productTitle">
                        <p>Products</p>
                    </div>

                    <div className="filterContainer">
                        <p>Filter by SKU / Name / Variation :</p>
                        <div className="productFilter">
                            <input
                                value={filterString}
                                className="inputBox"
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                    </div>
                </div>
                <Datatable type="products" className="datatable" overrideDataRow={finalDataRows} />
            </div>
        </div>
    )
}

export default Product;
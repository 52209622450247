import "./chart.scss"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';

const Chart = ({ aspect, title, value, yAxisValue }) => {


const formatXAxis = (tickItem) => {
  return String(tickItem).substring(0,2);
}

  if (String(title).includes("SHOPEE")) {

    return (
      <div className="chart">
        <div className="title">{title}</div>
        <div className="chartContainer">
          <ResponsiveContainer width="95%" aspect={aspect}>
            <AreaChart
              data={value}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" interval={3} tickFormatter={formatXAxis}/>
              <YAxis domain={[0, yAxisValue]} />
              <Tooltip />
              <Legend />
              <Area dot={null} strokeWidth={3} type="monotone" name="Today" dataKey="today" stroke="#EE4D2D" fill="#EE4D2D" />
              <Area dot={null} type="monotone" name="Yesterday" dataKey="yesterday" stroke="#EEAC9F" fill="#EEAC9F50" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className="chart">
        <div className="title">{title}</div>
        <div className="chartContainer">
          <ResponsiveContainer width="95%" aspect={aspect}>
            <AreaChart
              data={value}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" interval={3} tickFormatter={formatXAxis}/>
              <YAxis domain={[0, yAxisValue]} />
              <Tooltip />
              <Legend />
              
              <Area dot={null} strokeWidth={3} type="monotone" name="Today" dataKey="today" stroke="#551BF0" fill="#551BF0" />
              <Area dot={null} type="monotone" name="Yesterday" dataKey="yesterday" stroke="#BDA9F3" fill="#BDA9F350" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

export default Chart;
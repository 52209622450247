import "./barcodeScanner.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useRef, useState } from "react";
import { collection, limit, query, where, onSnapshot, doc, setDoc, arrayUnion, increment, getDoc, FieldPath, documentId } from "firebase/firestore";
import { auth, db } from "../../../firebaseconfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";

const BarcodeScannerGroup = ({ userMetadata }) => {

    const navigate = useNavigate();
    const [user] = useAuthState(auth);
    const urlParams = useParams();
    const [requestRows, setRequestRows] = useState([]);
    const [blockRows, setBlockRows] = useState([]);
    const [memberRows, setMemberRows] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const [groupName, setGroupName] = useState("");

    useEffect(() => {

        async function getRequestRows() {
            const groupCollection = collection(db, "ecommerce/scanner/group");
            var searchQuery = query(groupCollection,
                where(documentId(), "==", urlParams.groupId),
                limit(1));

            onSnapshot(searchQuery, (scanSnapshot) => {

                if(scanSnapshot.size === 0){
                    navigate("/barcode_scanner");
                }
                
                scanSnapshot.docChanges().forEach(async (scanChange) => {
                    var docData = scanChange.doc.data();

                    setGroupName(docData.name);
                    if (docData.owner === user.uid) {
                        setIsOwner(true);
                        var tempRequestRows = [];
                        var tempBlockRows = [];
                        var tempMemberRows = [];

                        for (let i = 0; i < docData.members.length; i++) {

                            const userData = doc(db, "user", docData.members[i]);
                            const userSnap = await getDoc(userData);
                            {
                                if (userSnap.exists()) {

                                    var internalData = userSnap.data();

                                    tempMemberRows.push({
                                        temp_id: urlParams.groupId,
                                        uid: internalData.uid,
                                        email: internalData.email,
                                        name: internalData.name,
                                        photoURL: internalData.photoURL
                                    });
                                }
                            }
                        }

                        for (let i = 0; i < docData.block_members.length; i++) {

                            const userData = doc(db, "user", docData.block_members[i]);
                            const userSnap = await getDoc(userData);
                            {
                                if (userSnap.exists()) {

                                    var internalData = userSnap.data();

                                    tempBlockRows.push({
                                        temp_id: urlParams.groupId,
                                        uid: internalData.uid,
                                        email: internalData.email,
                                        name: internalData.name,
                                        photoURL: internalData.photoURL
                                    });
                                }
                            }
                        }

                        for (let i = 0; i < docData.request_members.length; i++) {

                            const userData = doc(db, "user", docData.request_members[i]);
                            const userSnap = await getDoc(userData);
                            {
                                if (userSnap.exists()) {

                                    var internalData = userSnap.data();

                                    tempRequestRows.push({
                                        temp_id: urlParams.groupId,
                                        uid: internalData.uid,
                                        email: internalData.email,
                                        name: internalData.name,
                                        photoURL: internalData.photoURL
                                    });
                                }
                            }
                        }
                        setRequestRows(tempRequestRows);
                        setBlockRows(tempBlockRows);
                        setMemberRows(tempMemberRows);
                    }
                    else {
                        setIsOwner(false);
                    }
                });
            });

        }
        getRequestRows();
    }, []);

    return (
        <div className="barcodeScanner">
            <Sidebar />
            <div className="barcodeScannerContainer">
                <Navbar userMetadata={userMetadata} />
                <div className="top">
                    <div className="barcodeScannerTitle">
                        <p>Barcode Scanner Group</p>
                    </div>
                    <div className="barcodeScannerSubTitle">
                        <p>Manage your groups.</p>
                    </div>

                    <div className="main">
                        <div className="scannerContainer">
                            {
                                isOwner ? <div>
                                    <Datatable overrideDataRow={requestRows} type="scanner_group_requests"></Datatable>
                                    <Datatable overrideDataRow={blockRows} type="scanner_group_blocks"></Datatable>
                                    <Datatable overrideDataRow={memberRows} type="scanner_group_members"></Datatable>
                                </div>
                                    :
                                    <div>
                                        <h3>Do you wish to quit group : </h3>
                                        <br></br>
                                        <h1>{groupName}</h1>
                                        <br></br>
                                        <button className="quitButton" onClick={async () => 
                                        { 
                                            const groupDoc = doc(db, "ecommerce/scanner/group", urlParams.groupId);
                                            const groupSnap = await getDoc(groupDoc);
                                            
                                            if (groupSnap.exists()) {

                                                var docData = groupSnap.data();
                                                docData.members = docData.members.filter(item => item !== user.uid);
                                                await setDoc(groupDoc, docData);
                                                navigate("/barcode_scanner");
                                            }

                                        }
                                        }>Quit
                                        </button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BarcodeScannerGroup;
import "../../Blogs.scss";
import HomeNavbar from "../../../../../components/homeNavbar/HomeNavbar";
import { AccessTime, RateReview, Visibility } from "@mui/icons-material";
import { collection, doc, documentId, increment, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../../../../../firebaseconfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import BlogBottomLikes from "../../../blog/BlogBottomLikes";
import { Helmet } from "react-helmet-async";


const BarcodeScannerBlog = () => {
    
    const [user, loading, error] = useAuthState(auth);
    const blogDoc = doc(db, "count", "blogs_tt_barcode_scanner");
    const [documentDetails, setDocumentDetails] = useState({});
    useEffect(() => {

        async function increaseView() {
            await setDoc(blogDoc, {
                view_count: increment(1)
            }, { merge: true });
        }

        increaseView();

        async function getDocumentDetails() {
            const docCollection = collection(db, "count");
            const docQuery = query(docCollection,
                where(documentId(), "==", "blogs_tt_barcode_scanner"));

            onSnapshot(docQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {

                    setDocumentDetails(change.doc.data());
                })
            });
        }

        getDocumentDetails();
    }, []);

    return (
        <div className="homeContainer">
        <Helmet>
            <title>TactiBots | Barcode Scanner</title>
            <meta property="description" content="Barcode Scanner feature helps Businesses to keep Track of Parcel's Scan Time, prevent Duplication and logging Remarks." />
            <meta property="og:description" content="Barcode Scanner feature helps Businesses to keep Track of Parcel's Scan Time, prevent Duplication and logging Remarks." />
            <meta property="og:url" content="https://tactibots.com/blogs/tactibots_tutorials/barcode_scanner"></meta>
            <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Ftactibots_tutorials%2Fbarcode_scanner%2Fthumbnail.PNG?alt=media&token=be5be047-bfda-478f-bc42-bc614ba2e051"></meta>
        </Helmet>
            <HomeNavbar active={"blogContainer"} />
            <div className="singleBlogContainer" name="blogContainer">
                <div className="info">
                    <div className="time">
                    <AccessTime></AccessTime>
                        2022 November 17, 8:58am    
                    </div>
                    <div className="author">
                        <RateReview></RateReview>
                        TactiBots
                    </div>
                    <div className="time">
                        <Visibility></Visibility>
                        {
                            typeof (documentDetails) !== "undefined" ? documentDetails.view_count : 0
                        } views
                    </div>
                </div>
                <h1 className="title">Barcode Scanner</h1>
                    <img className="thumbnail"
                        src="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Ftactibots_tutorials%2Fbarcode_scanner%2Fthumbnail.PNG?alt=media&token=be5be047-bfda-478f-bc42-bc614ba2e051"
                        alt=""
                    />
                <div className="content">
                    <h3>What is the point of using a Barcode Scanner?</h3>
                    <p>Barcode Scanner feature helps Businesses to</p> 
                    <ul>
                        <li>Keep Track of Parcel's Scan Time</li>
                        <li>Prevent Duplication</li>
                        <li>Remark</li>
                    </ul>
                    <br></br>Under supervision of CCTV, footage of that parcel can be pinpoint easily too.

                    <h3>How to Scan?</h3>
                    <ol>
                        <li>Select 'Barcode Scanner' Tab</li>
                        <li>Focus on 'Delivery ID' </li>
                        <li>Scan</li>
                        <ul>
                            <li>If you're using Barcode Scanner, just scan.</li>
                            <li>If you're typing, press Enter after you type or press 'Scan' button.</li>
                        </ul>
                    </ol>
                    <h3>Search Previous Record</h3>
                    <ol>
                        <li>Type in Exact Delivery ID</li>
                        <li>Press 'Search'</li>
                    </ol>
                    <p>You can refer to the image below.</p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/barcodeScanner/search.PNG')}
                        alt=""
                    />
                    <h2>Advanced Options</h2>
                    <h3>1. Working Date</h3>
                    <p>By default, Working Date is set to be Today.
                    <br></br>If your scan records is Working Date sensitive, you can select the Date.</p>
                    <h3>2. Session</h3>
                    <p>By default, it will be Session 1.
                    <br></br>If you need to differentiate them due to different nature such as Couriers, you can press 'Restart Session'.</p>
                    <h3>3. Group</h3>

                    <p>By default, every user will have their own group.
                    <br></br>If you need to collaborate with other users, you can Join a Group!</p>
                    <p>In order to Join a Group, you will need <b>your Friend's Group ID.</b>
                    <br></br> The ID can be seen at the most bottom part as shown.
                    </p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/barcodeScanner/join_steps.PNG')}
                        alt=""
                    />
                    <p>The Owner will receive a Notification regarding your request.</p>

                    <h4>Manage Group</h4>
                    <p>Press 'View' in the Manage Group section to see the requests.</p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/barcodeScanner/manage_group.PNG')}
                        alt=""
                    />
                    <p>The Owner can also Press the Notification Link.</p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/barcodeScanner/notification.PNG')}
                        alt=""
                    />
                    <br></br>
                    <p>Under this page, there is 'Requesting Users', 'Blocked Users' and 'Current Members' sections.</p>
                    <p>For 'Requesting Users', owner can decide to whether 'Accept', 'Reject' or 'Block'.</p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/barcodeScanner/manage_requests.PNG')}
                        alt=""
                    />
                    <p>For 'Blocked Users', owner can decide to whether 'Accept' them, or 'Remove' from blocked list.</p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/barcodeScanner/blocked_users.PNG')}
                        alt=""
                    />
                    <p>For 'Current Members', owner can 'Remove' them from the Group.
                        <br></br>Group Owner is not removable.</p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/barcodeScanner/current_members.PNG')}
                        alt=""
                    />
                </div>
                <BlogBottomLikes document_id={"blogs_tt_barcode_scanner"} document_details={documentDetails} user={user} />
            </div>
        </div>
    )
}

export default BarcodeScannerBlog;
const CryptoJS = require("crypto-js");

const Shopee = () =>{
}

const Signature = (timest, path, access_token = null, shop_id = null) => {
    
    const partner_id = 2004820;
    var tmp_partner_key = "4f594b4f736977794c5451677955697961747a4e5744586d43444454674b6f76";
    var tmp_base_string = "";

    if(access_token !== null)
        tmp_base_string = partner_id + path + timest + access_token + shop_id;
    else if(shop_id !== null)
        tmp_base_string = partner_id + path + timest + shop_id;
    else
        tmp_base_string = partner_id + path + timest;

    return CryptoJS.HmacSHA256(tmp_base_string, tmp_partner_key).toString();
}

module.exports = { Shopee, Signature};
import "./widget.scss"
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { ArrowDropDown } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Widget = ({ type, value, yesterday }) => {
    let data;

    const [differences, setDifferences] = useState(0);
    const [positive, setPositive] = useState(true);

    switch (type) {
        case "user":
            data = {
                title: "USERS",
                isMoney: false,
                link: "See All Users",
                href: "",
                icon: (
                    <PeopleIcon className="icon" />
                )
            }
            break;
        case "order":
            data = {
                title: "ORDERS",
                isMoney: false,
                link: "",
                href: "",
                icon: (
                    <ShoppingCartIcon className="icon" />
                )
            }
            break;
        case "sale":
            data = {
                title: "SALES",
                isMoney: true,
                link: "See Net Sales",
                href: "",
                icon: (
                    <MonetizationOnIcon className="icon" />
                )
            }
            break;
        case "payment":
            data = {
                title: "PAYMENT",
                isMoney: true,
                link: "See Details",
                href: "",
                icon: (
                    <AccountBalanceWalletIcon className="icon" />
                )
            }
            break;
        default:
            break;
    }

    useEffect(() => {


        var positivePercentage = true;

        if(String(value).indexOf(' ') === -1 || String(yesterday).indexOf(' ') === -1)
        {
            setDifferences((Number(String(value).replace(/,/g,'') - String(yesterday).replace(/,/g,'')) / Number(String(yesterday).replace(/,/g,'')) * 100).toFixed(0));
            if (Number(String(value).replace(/,/g,'')) < Number(String(yesterday).replace(/,/g,''))) {
                positivePercentage = false;
            }
            setPositive(positivePercentage);
        }
        else
        {
            setDifferences(Number((value.substring(0, value.indexOf(' ')) 
            - yesterday.substring(0, yesterday.indexOf(' '))) 
            / Number(yesterday.substring(0, yesterday.indexOf(' '))) * 100).toFixed(0));
    
            if (Number(value.substring(0, value.indexOf(' '))) < Number(yesterday.substring(0, yesterday.indexOf(' ')))) {
                positivePercentage = false;
            }
            setPositive(positivePercentage);
        }

    }, [value, yesterday]);

    return (
        <div className="widget">
            <div className="left">
                <span className="title">{data.title}</span>
                <span className="counter">{data.isMoney && "RM "}{value}</span>
                {
                    data.href === "" ?
                    <></> :
                    <Link to={data.href}>
                        {data.link}
                    </Link>
                }
            </div>
            <div className="right">
                <div className="top">
                    <p>vs Yesterday Same Period: </p>
                    {
                        positive ?
                            <div className="percentage positive">
                                {differences}%
                                <ArrowDropUpIcon />
                            </div>
                            :
                            <div className="percentage negative">
                                {differences}%
                                <ArrowDropDown />
                            </div>
                    }
                </div>
                <span className="yesterday_counter">{data.isMoney && "RM "}{yesterday}</span>
                {data.icon}
            </div>
        </div>
    )
}

export default Widget;
import Chart from "../../components/chart/Chart";
import Featured from "../../components/featured/Featured";
import Table from "../../components/table/Table";
import Widget from "../../components/widget/Widget";
import "./dashboard.scss";
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../../firebaseconfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import Datatable from "../../components/datatable/Datatable";

const Dashboard = ({ shopeeOrders, lazadaOrders }) => {

    const [user] = useAuthState(auth);
    const [tempShopeeDataRows, setTempShopeeDataRows] = useState([]);
    const [tempLazadaDataRows, setTempLazadaDataRows] = useState([]);
    const [shopeeDataRows, setShopeeDataRows] = useState([]);
    const [lazadaDataRows, setLazadaDataRows] = useState([]);
    const [orderRows, setOrderRows] = useState([]);
    const [skuRows, setSkuRows] = useState([]);
    const [sortedSkuRows, setSortedSkuRows] = useState([]);
    const [numberOfOrders, setNumberOfOrders] = useState(0);
    const [yesterdayNumberOfOrders, setYesterdayNumberOfOrders] = useState(0);

    const [saleValue, setSaleValue] = useState(0);
    const [yesterdaySaleValue, setYesterdaySaleValue] = useState(0);
    const [yesterdayPartialSaleValue, setYesterdayPartialSaleValue] = useState(0);
    const [yAxisMaxValue, setYAxisMaxValue] = useState(0);

    useEffect(() => {

        setDoc(doc(db, "user", user.uid),
        {
          last_active_at: new Date()
        }, {
        merge: true
      });

    },[user]);

    useEffect(() => {

        var skuRows = [];
        var orderRows = [];
        var startTime = new Date().setHours(-24, 0, 0, 0).valueOf() / 1000;
        var endTime = new Date().setHours(24, 0, 0, 0).valueOf() / 1000;
        var middleTime = new Date().setHours(0, 0, 0, 0).valueOf() / 1000;
        if (typeof (shopeeOrders) !== "undefined") {
            var tempShopeeDataRows = [];

            for (let i = 0; i < shopeeOrders.length; i++) {

                if(shopeeOrders[i].order_status === "CANCELLED")
                    continue;

                if(shopeeOrders[i].create_time >= middleTime)
                {
                    for (let j = 0; j < shopeeOrders[i].customer_details.item_list.length; j++) {
                        var sku = shopeeOrders[i].customer_details.item_list[j].model_sku;
                        if (sku === "")
                            sku = shopeeOrders[i].customer_details.item_list[j].item_sku;
                        const findIndex = skuRows.findIndex(obj => obj.sku === sku);

                        if (findIndex === -1) {
                            skuRows.push({
                                no: 0,
                                id: sku,
                                sku: sku,
                                name: shopeeOrders[i].customer_details.item_list[j].item_name,
                                variation: shopeeOrders[i].customer_details.item_list[j].model_name,
                                quantity: shopeeOrders[i].customer_details.item_list[j].model_quantity_purchased,
                                value: shopeeOrders[i].customer_details.item_list[j].model_quantity_purchased * shopeeOrders[i].customer_details.item_list[j].model_discounted_price,
                                image: shopeeOrders[i].customer_details.item_list[j].image_info.image_url,
                                order_id: shopeeOrders[i].order_id
                            })
                        }
                        else {
                            skuRows[findIndex].quantity = skuRows[findIndex].quantity + shopeeOrders[i].customer_details.item_list[j].model_quantity_purchased;
                            skuRows[findIndex].value = skuRows[findIndex].value + shopeeOrders[i].customer_details.item_list[j].model_quantity_purchased * shopeeOrders[i].customer_details.item_list[j].model_discounted_price;
                            skuRows[findIndex].order_id = skuRows[findIndex].order_id + ",\n" + shopeeOrders[i].order_id;
                        }
                    }
                    
                    orderRows.push(
                        {
                            id: orderRows.length + 1,
                            no: orderRows.length + 1,
                            create_time: shopeeOrders[i].create_time,
                            platform: "Shopee",
                            order_id: shopeeOrders[i].order_id,
                            tracking_number: shopeeOrders[i].tracking_number,
                            status: shopeeOrders[i].order_status,
                            value: shopeeOrders[i].order_details.order_income.buyer_total_amount 
                            - shopeeOrders[i].order_details.order_income.buyer_paid_shipping_fee
                            - shopeeOrders[i].order_details.order_income.final_product_protection
                            + shopeeOrders[i].order_details.order_income.voucher_from_shopee
                            + shopeeOrders[i].order_details.order_income.voucher_from_seller
                            + shopeeOrders[i].order_details.order_income.coins,
                        }
                    );
                }
                if (shopeeOrders[i].create_time >= startTime && shopeeOrders[i].create_time < endTime) {

                    tempShopeeDataRows.push({
                        id: shopeeOrders[i].order_id,
                        platform: "Shopee",
                        temp_id: shopeeOrders[i].order_id,
                        create_time: shopeeOrders[i].create_time,
                        shop_id: shopeeOrders[i].shop_id,
                        number: 1,
                        item_details: shopeeOrders[i].customer_details.item_list,
                        value: shopeeOrders[i].order_details.order_income.buyer_total_amount 
                        - shopeeOrders[i].order_details.order_income.buyer_paid_shipping_fee 
                        - shopeeOrders[i].order_details.order_income.final_product_protection
                        + shopeeOrders[i].order_details.order_income.voucher_from_shopee
                        + shopeeOrders[i].order_details.order_income.voucher_from_seller
                        + shopeeOrders[i].order_details.order_income.coins,
                    });
                }
            }
            
            setTempShopeeDataRows(tempShopeeDataRows);
        }


        
        if (typeof (lazadaOrders) !== "undefined") {
            var tempLazadaDataRows = [];

            for (let i = 0; i < lazadaOrders.length; i++) {
                if(lazadaOrders[i].create_time >= middleTime)
                {
                    var orderStatus = "";
                    var orderValue = 0;
                    for (let j = 0; j < lazadaOrders[i].item_details.length; j++) {
                        orderValue += lazadaOrders[i].item_details[j].paid_price;

                        var sku = lazadaOrders[i].item_details[j].sku;

                        const findIndex = skuRows.findIndex(obj => obj.sku === sku);
                        //if(orderStatus.includes())

                        if (findIndex === -1) {
                            skuRows.push({
                                no: 0,
                                id: sku,
                                sku: sku,
                                name: lazadaOrders[i].item_details[j].item_name,
                                variation: lazadaOrders[i].item_details[j].variation_name,
                                quantity: lazadaOrders[i].item_details[j].quantity,
                                value: lazadaOrders[i].item_details[j].paid_price,
                                image: lazadaOrders[i].item_details[j].image,
                                order_id: lazadaOrders[i].order_id
                            })
                        }
                        else {
                            skuRows[findIndex].quantity = skuRows[findIndex].quantity + lazadaOrders[i].item_details[j].quantity;
                            skuRows[findIndex].value = skuRows[findIndex].value + lazadaOrders[i].item_details[j].paid_price;
                            skuRows[findIndex].order_id = skuRows[findIndex].order_id + ",\n" + lazadaOrders[i].order_id
                        }
                    }
                    
                    orderRows.push(
                        {
                            id: orderRows.length + 1,
                            no: orderRows.length + 1,
                            create_time: lazadaOrders[i].create_time,
                            platform: "Lazada",
                            order_id: lazadaOrders[i].order_id,
                            status: orderStatus,
                            value: orderValue,
                        }
                    );
                }

                if (lazadaOrders[i].create_time >= startTime && lazadaOrders[i].create_time < endTime) {
                    var orderValue = 0;
                    for (let j = 0; j < lazadaOrders[i].item_details.length; j++) {
                        orderValue += lazadaOrders[i].item_details[j].paid_price;
                    }

                    tempLazadaDataRows.push({
                        id: lazadaOrders[i].order_id,
                        platform: "Lazada",
                        temp_id: lazadaOrders[i].order_id,
                        create_time: lazadaOrders[i].create_time,
                        shop_id: lazadaOrders[i].shop_id,
                        item_details: lazadaOrders[i].item_details,
                        number: 1,
                        value: orderValue,
                    });
                }
            }
            
            setTempLazadaDataRows(tempLazadaDataRows);
        }
        

        setSkuRows(skuRows);
        setOrderRows(orderRows);
    }, [shopeeOrders, lazadaOrders]);

    useEffect(() => {

        var tempSkuRows = [...skuRows].sort((a, b) => b.value - a.value);
        for(let i = 0; i < tempSkuRows.length; i++)
        {
            tempSkuRows[i].no = i + 1;
        }
        setSortedSkuRows(tempSkuRows);
    }, [skuRows]);

    const [shopeeSeed, setShopeeSeed] = useState(Math.random());
    const [lazadaSeed, setLazadaSeed] = useState(Math.random());
    const forceRefresh = () => {
        setShopeeSeed(Math.random());
        setLazadaSeed(Math.random());
    }

    useEffect(() => {
        var skuRows = [];
        var yAxisValue = 0;
        for (let i = 0; i < shopeeDataRows.length; i++) {
            shopeeDataRows[i].name = ("0" + (shopeeDataRows[i].create_time)).slice(-2) + ":00 - " + ("0" + (shopeeDataRows[i].create_time)).slice(-2) + ":59";

            shopeeDataRows[i].yesterday = Number(shopeeDataRows[i].yesterday).toFixed(2);
            shopeeDataRows[i].today = Number(shopeeDataRows[i].today).toFixed(2);


            if (Number(shopeeDataRows[i].yesterday) > Number(yAxisValue)) {
                yAxisValue = shopeeDataRows[i].yesterday;
            }

            if (Number(shopeeDataRows[i].today) > Number(yAxisValue)) {
                yAxisValue = shopeeDataRows[i].today;
            }
        }

        for (let i = 0; i < lazadaDataRows.length; i++) {
            lazadaDataRows[i].name = ("0" + (lazadaDataRows[i].create_time)).slice(-2) + ":00 - " + ("0" + (lazadaDataRows[i].create_time)).slice(-2) + ":59";
            lazadaDataRows[i].yesterday = Number(lazadaDataRows[i].yesterday).toFixed(2);
            lazadaDataRows[i].today = Number(lazadaDataRows[i].today).toFixed(2);

            if (Number(lazadaDataRows[i].yesterday) > Number(yAxisValue)) {
                yAxisValue = lazadaDataRows[i].yesterday;
            }

            if (Number(lazadaDataRows[i].today) > Number(yAxisValue)) {
                yAxisValue = lazadaDataRows[i].today;
            }
        }

        yAxisValue = Math.ceil(Number(yAxisValue) / 500) * 500;
        setYAxisMaxValue(yAxisValue);


        forceRefresh();
    }, [shopeeDataRows, lazadaDataRows]);

    useEffect(() => {

        forceRefresh();
    }, [lazadaDataRows]);


    useEffect(() => {

        var startTime = new Date().setHours(-24, 0, 0, 0).valueOf() / 1000;
        var endTime = new Date().setHours(24, 0, 0, 0).valueOf() / 1000;

        var shopeeNumber = 0;
        var lazadaNumber = 0;
        var yesterdayShopeeNumber = 0;
        var yesterdayLazadaNumber = 0;
        var value = 0;
        var yesterdayValue = 0;
        var yesterdayPartialValue = 0;
        // Check if Hour Record Existed
        // Ignore if Order Value is 0 which means Cancelled Orders
        // If yes, increment by 1 order and order value
        // Else Initialize

        var startTime = new Date().setHours(-24, 0, 0, 0).valueOf() / 1000;
        var endTime = new Date().setHours(24, 0, 0, 0).valueOf() / 1000;

        var shopeeData = [];
        var lazadaData = [];
        const currentDate = new Date();
        const currentHours = currentDate.getHours();

        for (let hours = 0; hours <= 23; hours++) {
            if (currentHours < hours) {
                shopeeData.push({ id: hours, create_time: hours, yesterday: 0 });
                lazadaData.push({ id: hours, create_time: hours, yesterday: 0 });
            }
            else {
                shopeeData.push({ id: hours, create_time: hours, yesterday: 0, today: 0 });
                lazadaData.push({ id: hours, create_time: hours, yesterday: 0, today: 0 });
            }
        }

        // Process Shopee Rows
        for (let i = 0; i < tempShopeeDataRows.length; i++) {

            var currentTime = new Date(Number(tempShopeeDataRows[i].create_time) * 1000);

            const findIndex = shopeeData.findIndex(obj => obj.create_time === currentTime.getHours());
            if (findIndex !== -1) {

                if (tempShopeeDataRows[i].create_time < startTime + 86400) {

                    if (currentHours >= new Date(Number(tempShopeeDataRows[i].create_time) * 1000).getHours()) {
                        yesterdayShopeeNumber += tempShopeeDataRows[i].number;
                        yesterdayValue += tempShopeeDataRows[i].value;
                    }
                    shopeeData[findIndex].yesterday += tempShopeeDataRows[i].value;

                }
                else {
                    shopeeNumber += tempShopeeDataRows[i].number;
                    value += tempShopeeDataRows[i].value;
                    shopeeData[findIndex].today += tempShopeeDataRows[i].value;
                }
            }


            // Check Yesterday Same Time
            if (tempShopeeDataRows[i].create_time < Math.floor(new Date().valueOf() / 1000 - 86400)) {
                yesterdayPartialValue += tempShopeeDataRows[i].value;
            }
        };

        // Do Cummulative
        var currentTodayValue = 0;
        var currentYesterdayValue = 0;
        for (let i = 0; i < shopeeData.length; i++) {
            shopeeData[i].today = currentTodayValue + shopeeData[i].today;
            currentTodayValue = shopeeData[i].today;

            shopeeData[i].yesterday = currentYesterdayValue + shopeeData[i].yesterday;
            currentYesterdayValue = shopeeData[i].yesterday;
        }
        // Process Lazada Rows
        for (let i = 0; i < tempLazadaDataRows.length; i++) {

            var currentTime = new Date(Number(tempLazadaDataRows[i].create_time) * 1000);

            const findIndex = lazadaData.findIndex(obj => obj.create_time === currentTime.getHours());
            if (tempLazadaDataRows[i].create_time < startTime + 86400) {
                if (currentHours >= new Date(Number(tempLazadaDataRows[i].create_time) * 1000).getHours()) {
                    yesterdayLazadaNumber += tempLazadaDataRows[i].number;
                    yesterdayValue += tempLazadaDataRows[i].value;
                }

                lazadaData[findIndex].yesterday += tempLazadaDataRows[i].value;

            }
            else {
                lazadaNumber += tempLazadaDataRows[i].number;
                value += tempLazadaDataRows[i].value;
                lazadaData[findIndex].today += tempLazadaDataRows[i].value;
            }

            // Check Yesterday Same Time
            if (tempLazadaDataRows[i].create_time < Math.floor(new Date().valueOf() / 1000 - 86400)) {
                yesterdayPartialValue += tempLazadaDataRows[i].value;
            }
        };

        currentTodayValue = 0;
        currentYesterdayValue = 0;
        for (let i = 0; i < lazadaData.length; i++) {
            lazadaData[i].today = currentTodayValue + lazadaData[i].today;
            currentTodayValue = lazadaData[i].today;

            lazadaData[i].yesterday = currentYesterdayValue + lazadaData[i].yesterday;
            currentYesterdayValue = lazadaData[i].yesterday;
        }


        setShopeeDataRows(shopeeData);
        setLazadaDataRows(lazadaData);

        setNumberOfOrders((shopeeNumber+lazadaNumber).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
        + " (" + shopeeNumber.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, "," ) + " Shopee, " 
        + lazadaNumber.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, "," ) + " Lazada)");
        setSaleValue(value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        
        setYesterdayNumberOfOrders((yesterdayShopeeNumber+yesterdayLazadaNumber).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
        + " (" + yesterdayShopeeNumber.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, "," ) + " Shopee, " 
        + yesterdayLazadaNumber.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, "," ) + " Lazada)");
        
        setYesterdaySaleValue(yesterdayValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setYesterdayPartialSaleValue(yesterdayPartialValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));

    }, [tempShopeeDataRows, tempLazadaDataRows]);

    return (
        <div className="dashboard">
            <div className="widgets">
                <Widget type="order" value={numberOfOrders} yesterday={yesterdayNumberOfOrders} />
                <Widget type="sale" value={saleValue} yesterday={yesterdaySaleValue} />
            </div>
            <div className="charts">
                <Chart title="SHOPEE REVENUE" aspect={2 / 1} value={shopeeDataRows} key={shopeeSeed} yAxisValue={yAxisMaxValue} />
                <Chart title="LAZADA REVENUE" aspect={2 / 1} value={lazadaDataRows} key={lazadaSeed} yAxisValue={yAxisMaxValue} />
            </div>
            <div className="charts">
                <Featured todayAmount={saleValue} yesterdayAmount={yesterdayPartialSaleValue} yesterdayOrder={yesterdayNumberOfOrders} />
            </div>
            <div className="tables">
                <Datatable type={"dashboard_order"} overrideDataRow={orderRows} selectedRow={orderRows} setSelectedRows={setOrderRows}/>
                <Datatable type={"dashboard_sku"} overrideDataRow={sortedSkuRows} selectedRow={sortedSkuRows} setSelectedRows={setSortedSkuRows}/>
            </div>
        </div>
    )
}

export default Dashboard;
import "./datatable.scss"
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../../firebaseconfig";
import { CSVLink } from "react-csv";
import { Done } from "@mui/icons-material";
import { useAuthState } from "react-firebase-hooks/auth";

const Datatable = ({ type, orderStatus, orderCreatedDate, overrideDataRow, selectedRows, setSelectedRows }) => {

  const [user] = useAuthState(auth);
  var [info, setInfo] = useState({
    title: "",
    addNewButton: "none",
    addNewLink: "",
    exportButton: "none",
    checkboxSelection: false
  });
  const [finalUserRows, setFinalUserRows] = useState([]);
  const [finalUserRowsWithoutID, setFinalUserRowsWithoutID] = useState([]);
  const [userColumns, setUserColumns] = useState([]);

  // Filter Authorized Accounts for specific Users
  useEffect(() => {
    var tempInfo = {};

    async function processData() {
      switch (type) {
        case "dashboard":
          tempInfo = {
            title: "Sales (Yesterday Vs Today)",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "create_time", headerName: "Hours", width: 170,
              valueGetter: ({ value }) => {
                return ('0' + value).slice(-2) + ":00"
              },
            },
            {
              field: "yesterday", headerName: "Sales (Yesterday)", width: 170,
              valueGetter: ({ value }) => {
                return "RM " + value
              },
            },
            {
              field: "today", headerName: "Sales (Today)", width: 170,
              valueGetter: ({ value }) => {
                return "RM " + value
              },
            }
          ]);
          break;
        case "scanner_record":
          tempInfo = {
            title: "Scanned Records",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "block"
          };

          for (let i = 0; i < overrideDataRow.length; i++) {
            overrideDataRow[i].id = i + 1;
          }
          setFinalUserRows(overrideDataRow);
          setUserColumns(
            [
              { field: "id", headerName: "ID", minWidth: 50, flex: 0.5 },
              { field: "delivery_id", headerName: "Delivery ID", minWidth: 100, flex: 2 },
              {
                field: "created_at", headerName: "Created At", type: 'string', minWidth: 130, flex: 1.5,
                valueGetter: ({ value }) => {
                  var currentDate = String(value).substring(18, 28);
                  return new Date(Number(currentDate) * 1000).toLocaleString('en-US',
                    {
                      timeZone: 'Asia/Kuala_Lumpur',
                      year: 'numeric', month: 'short', day: 'numeric',
                      hour: '2-digit', minute: '2-digit', second: '2-digit', hourCycle: 'h23'
                    }
                  )
                },
              },
              { field: "created_by", headerName: "Created By", minWidth: 100, flex: 1 },
              {
                field: "remark",
                headerName: "Remark (Editable)",
                headerClassName: "highlight",
                cellClassName: "highlight",
                editable: true,
                minWidth: 180,
                flex: 2
              },

              {
                field: "action", headerName: "Action", minWidth: 70, flex: 1,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      <div className="viewButton" onClick={async () => {
                        const scanCollection = doc(db, "ecommerce/scanner/records", params.row.temp_id);
                        const docSnap = await getDoc(scanCollection);

                        if (docSnap.exists()) {
                          var scanData = docSnap.data();
                          scanData.records = scanData.records.filter(item => item.delivery_id !== params.row.delivery_id);
                          scanData.delivery_id_array = scanData.delivery_id_array.filter(item => item !== params.row.delivery_id);
                          scanData.total_count = scanData.total_count - 1;
                          await setDoc(scanCollection, scanData);
                        }
                      }
                      }>Delete</div>
                    </div>
                  )
                }
              },
            ]
          );
          break;
        case "scanner_group_members":
          tempInfo = {
            title: "Current Members",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };

          for (let i = 0; i < overrideDataRow.length; i++) {
            overrideDataRow[i].id = i + 1;
          }
          setFinalUserRows(overrideDataRow);
          setUserColumns(
            [
              { field: "id", headerName: "ID", minWidth: 50, flex: 0.5 },
              { field: "email", headerName: "Email", minWidth: 100, flex: 2 },
              { field: "name", headerName: "Name", minWidth: 100, flex: 2 },
              {
                field: "photoURL",
                headerName: "Photo",
                minWidth: 170, flex: 2,
                renderCell: (params) => {
                  return (
                    <div className="cellWithImg">
                      <img className="cellBiggerImg" src={params.row.photoURL} alt="" />
                    </div>
                  );
                },
              },
              {
                field: "reject_action", headerName: "Action", minWidth: 70, flex: 1,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      <div className="viewButton" onClick={async () => {
                        const groupCollection = doc(db, "ecommerce/scanner/group", params.row.temp_id);
                        const docSnap = await getDoc(groupCollection);

                        if (docSnap.exists()) {
                          var groupData = docSnap.data();

                          if (params.row.uid !== groupData.owner) {
                            groupData.members = groupData.members.filter(item => item !== params.row.uid);
                            await setDoc(groupCollection, groupData);
                          }
                          else {
                            alert("Owner cannot be removed.");
                          }
                        }
                      }
                      }>Remove</div>
                    </div>
                  )
                }
              },
            ]
          );
          break;
        case "scanner_group_requests":
          tempInfo = {
            title: "Requesting Users",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };

          for (let i = 0; i < overrideDataRow.length; i++) {
            overrideDataRow[i].id = i + 1;
          }
          setFinalUserRows(overrideDataRow);
          setUserColumns(
            [
              { field: "id", headerName: "ID", minWidth: 50, flex: 0.5 },
              { field: "email", headerName: "Email", minWidth: 100, flex: 2 },
              { field: "name", headerName: "Name", minWidth: 100, flex: 2 },
              {
                field: "photoURL",
                headerName: "Photo",
                minWidth: 170, flex: 2,
                renderCell: (params) => {
                  return (
                    <div className="cellWithImg">
                      <img className="cellBiggerImg" src={params.row.photoURL} alt="" />
                    </div>
                  );
                },
              },
              {
                field: "accept_action", headerName: "Action", minWidth: 70, flex: 1,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      <div className="viewButton" onClick={async () => {
                        console.log(params.row.temp_id + " " + params.row.uid);

                        const groupCollection = doc(db, "ecommerce/scanner/group", params.row.temp_id);
                        const docSnap = await getDoc(groupCollection);

                        if (docSnap.exists()) {
                          var groupData = docSnap.data();
                          groupData.request_members = groupData.request_members.filter(item => item !== params.row.uid);
                          groupData.members.push(params.row.uid);
                          await setDoc(groupCollection, groupData);
                        }
                      }
                      }>Accept</div>
                    </div>
                  )
                }
              },
              {
                field: "reject_action", headerName: "Action", minWidth: 70, flex: 1,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      <div className="viewButton" onClick={async () => {
                        console.log(params.row.temp_id + " " + params.row.uid);

                        const groupCollection = doc(db, "ecommerce/scanner/group", params.row.temp_id);
                        const docSnap = await getDoc(groupCollection);

                        if (docSnap.exists()) {
                          var groupData = docSnap.data();
                          groupData.request_members = groupData.request_members.filter(item => item !== params.row.uid);
                          await setDoc(groupCollection, groupData);
                        }
                      }
                      }>Reject</div>
                    </div>
                  )
                }
              },
              {
                field: "block_action", headerName: "Action", minWidth: 70, flex: 1,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      <div className="viewButton" onClick={async () => {
                        console.log(params.row.temp_id + " " + params.row.uid);

                        const groupCollection = doc(db, "ecommerce/scanner/group", params.row.temp_id);
                        const docSnap = await getDoc(groupCollection);

                        if (docSnap.exists()) {
                          var groupData = docSnap.data();
                          groupData.request_members = groupData.request_members.filter(item => item !== params.row.uid);
                          groupData.block_members.push(params.row.uid);
                          await setDoc(groupCollection, groupData);
                        }
                      }
                      }>Block</div>
                    </div>
                  )
                }
              },
            ]
          );
          break;
        case "scanner_group_blocks":
          tempInfo = {
            title: "Blocked Users",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };

          for (let i = 0; i < overrideDataRow.length; i++) {
            overrideDataRow[i].id = i + 1;
          }
          setFinalUserRows(overrideDataRow);
          setUserColumns(
            [
              { field: "id", headerName: "ID", minWidth: 50, flex: 0.5 },
              { field: "email", headerName: "Email", minWidth: 100, flex: 2 },
              { field: "name", headerName: "Name", minWidth: 100, flex: 2 },
              {
                field: "photoURL",
                headerName: "Photo",
                minWidth: 170, flex: 2,
                renderCell: (params) => {
                  return (
                    <div className="cellWithImg">
                      <img className="cellBiggerImg" src={params.row.photoURL} alt="" />
                    </div>
                  );
                },
              },
              {
                field: "accept_action", headerName: "Action", minWidth: 70, flex: 1,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      <div className="viewButton" onClick={async () => {
                        console.log(params.row.temp_id + " " + params.row.uid);

                        const groupCollection = doc(db, "ecommerce/scanner/group", params.row.temp_id);
                        const docSnap = await getDoc(groupCollection);

                        if (docSnap.exists()) {
                          var groupData = docSnap.data();
                          groupData.block_members = groupData.block_members.filter(item => item !== params.row.uid);
                          groupData.members.push(params.row.uid);
                          await setDoc(groupCollection, groupData);
                        }
                      }
                      }>Accept</div>
                    </div>
                  )
                }
              },
              {
                field: "reject_action", headerName: "Action", minWidth: 70, flex: 1,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      <div className="viewButton" onClick={async () => {
                        console.log(params.row.temp_id + " " + params.row.uid);

                        const groupCollection = doc(db, "ecommerce/scanner/group", params.row.temp_id);
                        const docSnap = await getDoc(groupCollection);

                        if (docSnap.exists()) {
                          var groupData = docSnap.data();
                          groupData.block_members = groupData.block_members.filter(item => item !== params.row.uid);
                          await setDoc(groupCollection, groupData);
                        }
                      }
                      }>Remove</div>
                    </div>
                  )
                }
              },
            ]
          );
          break;
        case "scanner_group":
          tempInfo = {
            title: "Your Groups",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };

          setFinalUserRows(overrideDataRow);
          setUserColumns(
            [
              {
                field: "action", headerName: "Action", width: 70,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      {
                        params.row.owner === true ?
                          <Link to={"/barcode_scanner/group/" + encodeURIComponent(params.row.temp_id)} style={{ textDecoration: "none" }}>
                            <div className="viewButton">View</div>
                          </Link>
                          :
                          <div className="viewButton" onClick={async () => {
                            const groupDoc = doc(db, "ecommerce/scanner/group", params.row.temp_id);
                            const groupSnap = await getDoc(groupDoc);

                            if (groupSnap.exists()) {

                              var docData = groupSnap.data();
                              docData.members = docData.members.filter(item => item !== user.uid);
                              await setDoc(groupDoc, docData);
                            }

                          }
                          }>Quit</div>
                      }
                    </div>
                  )
                }
              },
              { field: "temp_id", headerName: "ID", minWidth: 200, flex: 1 },
              { field: "name", headerName: "Name", minWidth: 180, flex: 1 },
              {
                field: "owner", headerName: "Owned by You", minWidth: 180, flex: 1,
                renderCell: (params) => {
                  return (
                    <div>
                      {
                        params.row.owner === true ? <Done></Done> : <div></div>
                      }
                    </div>
                  )
                }
              },
              { field: "request_members", headerName: "Requests", minWidth: 180, flex: 1 },
            ]
          );
          break;
        case "products":
          tempInfo = {
            title: "Filtered Products",
            addNewButton: "block",
            addNewLink: "new",
            exportButton: "block"
          };

          setFinalUserRows(overrideDataRow);
          setUserColumns(
            [
              {
                field: "action", headerName: "Action", width: 70,
                renderCell: (params) => {
                  return (
                    <div className="cellAction">
                      <Link to={"/products/edit/" + encodeURIComponent(params.row.sku)} style={{ textDecoration: "none" }}>
                        <div className="viewButton">View</div>
                      </Link>
                    </div>
                  )
                }
              },
              { field: "sku", headerName: "SKU", minWidth: 180, flex: 1 },
              {
                field: "image",
                headerName: "Image",

                width: 80,
                renderCell: (params) => {
                  return (
                    <div className="cellWithImg">
                      <img className="cellImg" src={params.row.image} alt="" />
                    </div>
                  );
                },
              },
              { field: "item_name", headerName: "Name", width: 300, flex: 2 },
              { field: "variation_name", headerName: "Variation", width: 200, flex: 1 },
              {
                field: "quantity", headerName: "Quantity", width: 70,
                valueGetter: ({ value }) => {
                  return Number(+(Math.round(value + "e+2") + "e-2"))
                },
              },
              {
                field: "purchase_price", headerName: "Purchase Price (Editable)",
                headerClassName: "highlight",
                cellClassName: "highlight",
                type: 'number',
                editable: true, width: 180,
                valueGetter: ({ value }) => {
                  return Number(+(Math.round(value + "e+2") + "e-2"));
                },
              },
              {
                field: "sale_price", headerName: "Sale Price", width: 100,
                valueGetter: ({ value }) => {
                  return Number(+(Math.round(value + "e+2") + "e-2"))
                },
              },
              {
                field: "created_at", headerName: "Created At", type: 'string', width: 180, flex: 1,
                valueGetter: ({ value }) => {
                  var currentDate = String(value).substring(18, 28);
                  return new Date(Number(currentDate) * 1000).toLocaleString('en-US',
                    {
                      timeZone: 'Asia/Kuala_Lumpur',
                      year: 'numeric', month: 'short', day: 'numeric',
                      hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                    }
                  )
                },
              },
            ]
          );
          break;
        case "products_transfer":
          tempInfo = {
            title: "Filtered Products",
            addNewButton: "none",
            addNewLink: "none",
            exportButton: "block"
          };

          setFinalUserRows(overrideDataRow);

          var tempRows = JSON.parse(JSON.stringify(overrideDataRow));
          for (let i = 0; i < tempRows.length; i++) {
            tempRows[i].transfered_at = new Date(tempRows[i].transfered_at * 1000).toLocaleString();
            tempRows[i].created_at = new Date(tempRows[i].created_at.seconds * 1000).toLocaleString();
          }

          tempRows.map(function (item) {
            delete item.id;

            return item;
          });

          setFinalUserRowsWithoutID(tempRows);
          setUserColumns(
            [
              { field: "platform", headerName: "Platform", minWidth: 80, flex: 1 },
              { field: "store", headerName: "Store", minWidth: 160, flex: 2 },
              { field: "reference_number", headerName: "Reference", minWidth: 160, flex: 2 },
              { field: "sku", headerName: "SKU", minWidth: 160, flex: 2 },
              {
                field: "quantity", headerName: "Quantity", minWidth: 80, flex: 1,
                valueGetter: ({ value }) => {
                  return Number(+(Math.round(value + "e+2") + "e-2"))
                },
              },
              {
                field: "created_at", headerName: "Created At", type: 'string', minWidth: 160, flex: 2,
                valueGetter: ({ value }) => {
                  var currentDate = String(value).substring(18, 28);
                  return new Date(Number(currentDate) * 1000).toLocaleString('en-US',
                    {
                      timeZone: 'Asia/Kuala_Lumpur',
                      year: 'numeric', month: 'short', day: 'numeric',
                      hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                    }
                  )
                },
              },
              {
                field: "transfered_at",
                headerName: "Transfer Date",
                minWidth: 160, flex: 2,
                valueGetter: ({ value }) => {
                  return new Date(Number(value) * 1000).toLocaleString('en-US',
                    {
                      timeZone: 'Asia/Kuala_Lumpur',
                      year: 'numeric', month: 'short', day: 'numeric',
                      hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                    }
                  )
                },
              },
            ]
          );
          break;
        case "balance":
          tempInfo = {
            title: "Latest Transactions",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };
          break;
        case "sales":
          tempInfo = {
            title: "Add New Sales",
            addNewButton: "block",
            addNewLink: "/sales/new",
            exportButton: "none"
          };
          break;
        case "dashboard_order":
          tempInfo = {
            title: "TODAY'S ORDER DETAILS",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "block"
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "no",
              headerName: "No",
              minWidth: 25,
              flex: 0.5,
            },
            {
              field: "platform",
              headerName: "Platform",
              minWidth: 50,
              flex: 1,
              valueGetter: (value) => {
                return (value.row.platform)
              },
            },
            {
              field: "create_time",
              headerName: "Created At",
              minWidth: 100,
              flex: 2,
              valueGetter: (value) => 
              {return new Date(Number(value.row.create_time) * 1000).toLocaleString('en-US',
              {
                timeZone: 'Asia/Kuala_Lumpur',
                year: 'numeric', month: 'short', day: 'numeric',
                hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
              }
            )
              },
            },
            {
              field: "order_id",
              headerName: "Order ID",
              minWidth: 100,
              flex: 2,
              valueGetter: (value) => {
                return (value.row.order_id)
              },
            },
            {
              field: "status",
              headerName: "Status",
              minWidth: 100,
              flex: 2,
              valueGetter: (value) => {
                return (value.row.status)
              },
            },
            {
              field: "value",
              headerName: "Value",
              minWidth: 50,
              flex: 1,
              valueGetter: (value) => {
                return Number(value.row.value.toFixed(2))
              },
            },
          ]);
          break;
        case "dashboard_sku":
          tempInfo = {
            title: "TODAY'S SKU DETAILS",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "block"
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "no",
              headerName: "No",
              minWidth: 25,
              flex: 0.5,
            },
            {
              field: "sku",
              headerName: "SKU",
              minWidth: 50,
              flex: 1,
              valueGetter: (value) => {
                return (value.row.sku)
              },
            },
            {
              field: "name",
              headerName: "Name",
              minWidth: 200,
              flex: 4,
              valueGetter: (value) => {
                return (value.row.name)
              },
            },
            {
              field: "variation",
              headerName: "Variation",
              minWidth: 100,
              flex: 2,
              valueGetter: (value) => {
                return (value.row.variation)
              },
            },
            {
              field: "image",
              headerName: "Image",
              width: 50,
              flex: 1,
              renderCell: (params) => {
                return (
                  <div className="cellWithImg">
                    <img className="cellImg" src={params.row.image} alt="avatar" style={{ marginRight: "5px" }} />
                  </div>
                );
              },
            },
            {
              field: "quantity",
              headerName: "Quantity",
              minWidth: 50,
              flex: 1,
              valueGetter: (value) => {
                return Number(value.row.quantity)
              },
            },
            {
              field: "value",
              headerName: "Value",
              minWidth: 50,
              flex: 1,
              valueGetter: (value) => {
                return Number(value.row.value.toFixed(2))
              },
            },
          ]);
          break;
        case "orders_single":
          tempInfo = {
            title: "",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "no",
              headerName: "No",
              minWidth: 30,
              flex: 1,
            },
            {
              field: "item_details",
              headerName: "Products",
              width: 300,
              flex: 8,
              renderCell: (params) => {
                return (
                  <div className="cellWithImg">
                    <img className="cellImg" src={params.row.item_details.image_info.image_url} alt="avatar" style={{ marginRight: "5px" }} />
                    <div style={{ display: "block" }}>

                      <p style={{ wordBreak: "break-all", whiteSpace: "normal", fontSize: "16px" }}>{params.row.item_details.item_name}</p>
                      <p style={{ wordBreak: "break-all", whiteSpace: "normal", fontSize: "12px" }}>{"Variation : " + params.row.item_details.model_name}</p>
                      {
                        params.row.item_details.model_sku !== "" ?
                          <p style={{ wordBreak: "break-all", whiteSpace: "normal", fontSize: "12px" }}>{"SKU : " + params.row.item_details.model_sku}</p>
                          :
                          <p style={{ wordBreak: "break-all", whiteSpace: "normal", fontSize: "12px" }}>{"SKU : " + params.row.item_details.item_sku}</p>
                      }

                    </div>
                  </div>
                );
              },
            },
            {
              field: "item_details.model_discounted_price",
              headerName: "Unit Price",
              minWidth: 80,
              flex: 1,
              valueGetter: (value) => {
                return (value.row.item_details.model_discounted_price)
              },
            },
            {
              field: "item_details.model_quantity_purchased",
              headerName: "Quantity",
              minWidth: 80,
              flex: 1,
              valueGetter: (value) => {
                return (value.row.item_details.model_quantity_purchased)
              },
            },
          ]);
          break;
        case "reports_orders":
          tempInfo = {
            title: "Order Details",
            addNewButton: "none",
            addNewLink: "/orders/new"
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "action", headerName: "Action", width: 70,
              renderCell: (params) => {
                return (
                  <div className="cellAction">
                    <Link to={"/reports/orders/" + encodeURIComponent(params.row.platform + "_" + params.row.shop_id + "_" + params.row.order_id)} style={{ textDecoration: "none" }}>
                      <div className="viewButton">View</div>
                    </Link>
                  </div>
                )
              }
            },
            {
              field: "shop_id",
              headerName: "shop_id",
              width: 80
            },
            {
              field: "platform",
              headerName: "Platform",
              width: 80
            },
            {
              field: "name",
              headerName: "Name",
              width: 150
            },
            {
              field: "order_id",
              headerName: "Order ID",
              width: 170
            },
            {
              field: "tracking_number",
              headerName: "Tracking Number",
              width: 180
            },
            {
              field: "remark",
              headerName: "Remark (Editable)",
              headerClassName: "highlight",
              cellClassName: "highlight",
              editable: true,
              width: 150
            },
            {
              field: "order_status",
              headerName: "Order Status",
              width: 130
            },
            {
              field: "order_amount",
              headerName: "Order Amount",
              width: 130,
              valueGetter: ({ value }) => {
                return Number(+(Math.round(value + "e+2") + "e-2"))
              },
            },
            {
              field: "commission_fee",
              headerName: "Commission Fee",
              width: 160,
              valueGetter: ({ value }) => {
                return Math.abs(+(Math.round(value + "e+2") + "e-2"))
              },
            },
            {
              field: "service_fee",
              headerName: "Service Fee",
              width: 160,
              valueGetter: ({ value }) => {
                return Math.abs(+(Math.round(value + "e+2") + "e-2"))
              },
            },
            {
              field: "shipping_fee",
              headerName: "Shipping Fee",
              width: 160,
              valueGetter: ({ value }) => {
                return Math.abs(+(Math.round(value + "e+2") + "e-2"))
              },
            },
            {
              field: "transaction_fee",
              headerName: "Transaction Fee",
              width: 160,
              valueGetter: ({ value }) => {
                return Math.abs(+(Math.round(value + "e+2") + "e-2"))
              },
            },
            {
              field: "create_time",
              headerName: "Created Date",
              width: 190,
              valueGetter: ({ value }) => {
                return new Date(Number(value) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
            {
              field: "status",
              headerName: "Status",
              width: 170
            },
          ]);
          break;
        case "reports_sku":
          tempInfo = {
            title: "SKU Details",
            addNewButton: "none",
            addNewLink: "/orders/new"
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "sku",
              headerName: "SKU",
              minWidth: 100,
              flex: 1
            },
            {
              field: "item_name",
              headerName: "Item Name",
              minWidth: 300,
              flex: 3
            },
            {
              field: "variation_name",
              headerName: "Variation Name",
              width: 200,
              flex: 2
            },
            {
              field: "quantity",
              headerName: "Quantity",
              minWidth: 50,
              flex: 0.5
            },
            {
              field: "purchase_price", headerName: "Purchase Price (Editable)",
              headerClassName: "highlight",
              cellClassName: "highlight",
              type: 'number',
              editable: true, width: 180,
              valueGetter: ({ value }) => {
                return Number(+(Math.round(value + "e+2") + "e-2"));
              },
              flex: 1
            },
            {
              field: "total_cost",
              headerName: "Total Cost",
              minWidth: 50,
              flex: 0.5
            },
          ]);
          break;
        case "shipment":
          tempInfo = {
            title: "Mass Shipping Orders",
            addNewButton: "none",
            addNewLink: "",
            checkboxSelection: true
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "shop_id",
              headerName: "shop_id",
              width: 80
            },
            {
              field: "platform",
              headerName: "Platform",
              width: 80
            },
            {
              field: "name",
              headerName: "Name",
              width: 150
            },
            {
              field: "order_id",
              headerName: "Order ID",
              width: 170
            },
            {
              field: "shipping_carrier",
              headerName: "Courier",
              width: 180
            },
            {
              field: "message_to_seller",
              headerName: "Message To Seller",
              width: 180
            },
            {
              field: "note",
              headerName: "Note",
              width: 180
            },
            {
              field: "tracking_number",
              headerName: "Tracking Number",
              width: 180
            },
            {
              field: "order_status",
              headerName: "Order Status",
              width: 130
            },
            {
              field: "create_time",
              headerName: "Created Date",
              width: 190,
              valueGetter: ({ value }) => {
                return new Date(Number(value) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
          ]);
          setInfo(tempInfo);
          break;
          tempInfo = {
            title: "Mass Shipping Orders",
            addNewButton: "none",
            addNewLink: "",
            checkboxSelection: true
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "shop_id",
              headerName: "shop_id",
              width: 80
            },
            {
              field: "platform",
              headerName: "Platform",
              width: 80
            },
            {
              field: "name",
              headerName: "Name",
              width: 150
            },
            {
              field: "order_id",
              headerName: "Order ID",
              width: 170
            },
            {
              field: "shipping_carrier",
              headerName: "Courier",
              width: 180
            },
            {
              field: "tracking_number",
              headerName: "Tracking Number",
              width: 180
            },
            {
              field: "note",
              headerName: "Note",
              width: 180
            },
            {
              field: "message_to_seller",
              headerName: "Message To Seller",
              width: 180
            },
            {
              field: "order_status",
              headerName: "Order Status",
              width: 130
            },
            {
              field: "create_time",
              headerName: "Created Date",
              width: 190,
              valueGetter: ({ value }) => {
                return new Date(Number(value) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
          ]);
          setInfo(tempInfo);
          break;
        case "shipment_orders":
          tempInfo = {
            title: "Shipment Orders",
            addNewButton: "none",
            addNewLink: "",
            checkboxSelection: false
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "shipment_status",
              headerName: "Status",
              width: 80
            },
            {
              field: "shop_id",
              headerName: "shop_id",
              width: 80
            },
            {
              field: "platform",
              headerName: "Platform",
              width: 80
            },
            {
              field: "name",
              headerName: "Name",
              width: 150
            },
            {
              field: "order_id",
              headerName: "Order ID",
              width: 170
            },
            {
              field: "shipping_carrier",
              headerName: "Courier",
              width: 180
            },
            {
              field: "tracking_number",
              headerName: "Tracking Number",
              width: 180
            },
            {
              field: "note",
              headerName: "Note",
              width: 180
            },
            {
              field: "message_to_seller",
              headerName: "Message To Seller",
              width: 180
            },
            {
              field: "create_time",
              headerName: "Created Date",
              width: 190,
              valueGetter: ({ value }) => {
                return new Date(Number(value) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
          ]);
          setInfo(tempInfo);
          break;
        case "orders":
          tempInfo = {
            title: "Filtered Orders",
            addNewButton: "none",
            addNewLink: "/orders/new"
          };
          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "action", headerName: "Action", width: 70,
              renderCell: (params) => {
                return (
                  <div className="cellAction">
                    <Link to={"/reports/orders/" + encodeURIComponent(params.row.platform + "_" + params.row.shop_id + "_" + params.row.order_id)} style={{ textDecoration: "none" }}>
                      <div className="viewButton">View</div>
                    </Link>
                  </div>
                )
              }
            },
            {
              field: "shop_id",
              headerName: "shop_id",
              width: 80
            },
            {
              field: "platform",
              headerName: "Platform",
              width: 80
            },
            {
              field: "name",
              headerName: "Name",
              width: 150
            },
            {
              field: "order_id",
              headerName: "Order ID",
              width: 170
            },
            {
              field: "tracking_number",
              headerName: "Tracking Number",
              width: 180
            },
            {
              field: "remark",
              headerName: "Remark (Editable)",
              headerClassName: "highlight",
              cellClassName: "highlight",
              editable: true,
              width: 150
            },
            {
              field: "order_status",
              headerName: "Order Status",
              width: 130
            },
            {
              field: "order_amount",
              headerName: "Order Amount",
              width: 130,
              valueGetter: ({ value }) => {
                return Number(+(Math.round(value + "e+2") + "e-2"))
              },
            },
            {
              field: "payment",
              headerName: "Payment",
              width: 130,
              valueGetter: ({ value }) => {
                return Number(+(Math.round(value + "e+2") + "e-2"))
              },
            },
            {
              field: "shipping_overcharge",
              headerName: "Shipping Tally",
              width: 160,
              valueGetter: ({ value }) => {
                return Number(+(Math.round(value + "e+2") + "e-2"))
              },

            },
            {
              field: "create_time",
              headerName: "Created Date",
              width: 190,
              valueGetter: ({ value }) => {
                return new Date(Number(value) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
            {
              field: "status",
              headerName: "Status",
              width: 170
            },
          ]);
          break;
        case "users":
          tempInfo = {
            title: "Add New Users",
            addNewButton: "block",
            addNewLink: "/users/new",
            exportButton: "none"
          };
          break;
        case "authorization":
          tempInfo = {
            title: "Existing Authorizations",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };

          setFinalUserRows(overrideDataRow);
          setUserColumns([
            { field: "platform", headerName: "Platform" },
            { field: "name", headerName: "Name", width: 200 },
            {
              field: "data_started_at", headerName: "Data Started At", type: 'string', width: 180,
              valueGetter: ({ value }) => {
                var currentDate = String(value).substring(18, 28);
                return new Date(Number(currentDate) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
            {
              field: "data_ended_at", headerName: "Data Ended At", type: 'string', width: 180,
              valueGetter: ({ value }) => {
                var currentDate = String(value).substring(18, 28);
                return new Date(Number(currentDate) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
            {
              field: "refresh_expire_in", headerName: "Expired At", type: 'string', width: 180,
              valueGetter: ({ value }) => {
                var currentDate = String(value).substring(18, 28);
                return new Date(Number(currentDate) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
            {
              field: "sync_status",
              headerName: "Status",
              width: 60,
              renderCell: (params) => {
                return (
                  <div className={`cellWithStatus ${params.row.sync_status}`} style={{ textTransform: "capitalize" }}>
                    {params.row.sync_status.replace("-", " ")}
                  </div>
                );
              },
            },
          ]);
          break;
        case "feedback":
          tempInfo = {
            title: "Feedback within the Past 6 Months",
            addNewButton: "none",
            addNewLink: "",
            exportButton: "none"
          };

          setFinalUserRows(overrideDataRow);
          setUserColumns([
            {
              field: "no", headerName: "No",
              width: 50,
              minWidth: 50
            },
            {
              field: "title", headerName: "Title",
              minWidth: 150, flex: 1
            },
            {
              field: "details", headerName: "Details",
              minWidth: 150,
              flex: 3,
              valueGetter: ({ value }) => {
                var snippet = value;
                if (value.length >= 300)
                  snippet = String(value).substring(0, 300) + "...";
                return snippet
              }
            },
            {
              field: "created_at", headerName: "Created At", type: 'string', minWidth: 150,
              valueGetter: ({ value }) => {
                var currentDate = String(value).substring(18, 28);
                return new Date(Number(currentDate) * 1000).toLocaleString('en-US',
                  {
                    timeZone: 'Asia/Kuala_Lumpur',
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                  }
                )
              },
            },
            {
              field: "status",
              headerName: "Status",
              minWidth: 100,
              renderCell: (params) => {
                return (

                  <div className={`cellWithStatus ${params.row.status}`} style={{ textTransform: "capitalize" }}>
                    {params.row.status.replace("-", " ")}
                  </div>
                );
              },
            },
            {
              field: "action", headerName: "Action", minWidth: 70,
              renderCell: (params) => {
                return (
                  <div className="cellAction">
                    <Link to={"/feedback/" + encodeURIComponent(params.row.id)} style={{ textDecoration: "none" }}>
                      <div className="viewButton">View</div>
                    </Link>
                  </div>
                )
              }
            },
          ]);
          break;
        default:
          tempInfo = {
            title: "",
            addNewButton: "none",
            addNewLink: ""
          };
          setUserColumns([
            { field: "id", headerName: "ID", width: 70 },
            {
              field: "user",
              headerName: "User",
              width: 230,
              renderCell: (params) => {
                return (
                  <div className="cellWithImg">
                    <img className="cellImg" src={params.row.img} alt="avatar" />
                    {params.row.username}
                  </div>
                );
              },
            },
            {
              field: "email",
              headerName: "Email",
              width: 230,
            },

            {
              field: "age",
              headerName: "Age",
              width: 100,
            },
            {
              field: "status",
              headerName: "Status",
              width: 160,
              renderCell: (params) => {
                return (
                  <div className={`cellWithStatus ${params.row.status}`}>
                    {params.row.status}
                  </div>
                );
              },
            },
          ]);
          break;
      }

      setInfo(tempInfo);
    };
    processData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus, orderCreatedDate, overrideDataRow]);


  async function handleDataChange(params) {
    switch (type) {
      case "scanner_record":
        if (params.field === "remark") {
          const scanCollection = doc(db, "ecommerce/scanner/records", params.row.temp_id);
          const docSnap = await getDoc(scanCollection);

          if (docSnap.exists()) {
            var scanData = docSnap.data();
            for (let i = 0; i < scanData.records.length; i++) {
              if (scanData.records[i].delivery_id === params.row.delivery_id) {
                scanData.records[i].remark = params.value;
                break;
              }
            }
            await setDoc(scanCollection, scanData);
          }
        }
        break;
      case "reports_sku":
      case "products":
        if (params.field === "purchase_price") {
          if (Number(params.value) === false || params.value == null) {
            alert("Invalid value.")
            return;
          }

          const docId = params.row.id.split('_')[0] + "_" + params.row.id.split('_')[1];
          const productDocsCollection = doc(db, "inventory", docId);
          const previousString =
            params.row.id.split('_')[0] + "_" +
            params.row.id.split('_')[1] + "_" +
            params.row.id.split('_')[2] + "_";

          const sku = params.row.id.substring(previousString.length);


          const docSnap = await getDoc(productDocsCollection);

          if (docSnap.exists()) {
            var productData = docSnap.data();

            for (let i = 0; i < productData.inventory.length; i++) {

              if (productData.inventory[i].sku === sku) {
                productData.inventory[i].purchase_price = params.value;
                break;
              }
            }
            await setDoc(productDocsCollection, productData);
          }
        }
        break;
      case "reports_orders":
      case "orders":
        if (params.field === "remark") {
          const docId = params.row.id.replace("_" + params.row.id.split('_')[3], "");
          const orderDocsCollection = doc(db, "ecommerce/shopee/order", docId);
          const docSnap = await getDoc(orderDocsCollection);

          if (docSnap.exists()) {
            var orderData = docSnap.data();

            for (let i = 0; i < orderData.order_list.length; i++) {

              if (orderData.order_list[i].order_id === params.row.id.split('_')[3]) {
                orderData.order_list[i].remark = params.value;
                break;
              }
            }
            await setDoc(orderDocsCollection, orderData);
          }
        }
        break;
      default:
        break;
    }
  }

  function generateCsvRows() {
    switch (type) {
      case "scanner_record":

        var tempRows = JSON.parse(JSON.stringify(finalUserRows));
        for (let i = 0; i < tempRows.length; i++) {
          tempRows[i].created_at = new Date(tempRows[i].created_at.seconds * 1000).toLocaleString();
        }
        let scannerRecordAray = JSON.parse(JSON.stringify(tempRows));
        scannerRecordAray.map(function (item) {
          delete item.id;
          delete item.temp_id;

          return item;
        });
        setFinalUserRowsWithoutID(scannerRecordAray);
        break;
      case "dashboard":
        let newDashboardArray = JSON.parse(JSON.stringify(finalUserRows));
        newDashboardArray.map(function (item) {
          delete item.id;
          return item;
        });
        setFinalUserRowsWithoutID(newDashboardArray);
        break;
      case "products":
        for (let i = 0; i < finalUserRows.length; i++) {
          finalUserRows[i].sku = String(finalUserRows[i].sku).replace(/"/g, "\"\"");
          finalUserRows[i].item_name = String(finalUserRows[i].item_name).replace(/"/g, "\"\"");
          finalUserRows[i].variation_name = String(finalUserRows[i].variation_name).replace(/"/g, "\"\"");
          finalUserRows[i].created_at = new Date(Number(finalUserRows[i].created_at.seconds) * 1000).toLocaleString('en-US',
            {
              timeZone: 'Asia/Kuala_Lumpur',
              year: 'numeric', month: 'short', day: 'numeric',
              hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
            });
        };

        let newProductArray = JSON.parse(JSON.stringify(finalUserRows));
        newProductArray.map(function (item) {
          delete item.id;
          delete item.temp_id;
          return item;
        });
        setFinalUserRowsWithoutID(newProductArray);
        break;
      case "balance":
        break;
      case "sales":
        break;
      case "dashboard_sku":
        let dashboardSkuArray = JSON.parse(JSON.stringify(finalUserRows));
        dashboardSkuArray.map(function (item) {
          delete item.id;
          return item;
        });
        setFinalUserRowsWithoutID(dashboardSkuArray);
        break;
      case "dashboard_order":
        for (let i = 0; i < finalUserRows.length; i++) {
          finalUserRows[i].create_time = new Date(Number(finalUserRows[i].create_time) * 1000).toLocaleString('en-US',
            {
              timeZone: 'Asia/Kuala_Lumpur',
              year: 'numeric', month: 'short', day: 'numeric',
              hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
            });
        };
        let dashboardOrderArray = JSON.parse(JSON.stringify(finalUserRows));
        dashboardOrderArray.map(function (item) {
          delete item.id;
          return item;
        });
        setFinalUserRowsWithoutID(dashboardOrderArray);
        break;
      case "reports_sku":

        for (let i = 0; i < finalUserRows.length; i++) {
          finalUserRows[i].sku = String(finalUserRows[i].sku).replace(/"/g, "\"\"");
          finalUserRows[i].item_name = String(finalUserRows[i].item_name).replace(/"/g, "\"\"");
          finalUserRows[i].variation_name = String(finalUserRows[i].variation_name).replace(/"/g, "\"\"");
        };
        let reportArray = JSON.parse(JSON.stringify(finalUserRows));
        reportArray.map(function (item) {
          delete item.id;
          delete item.temp_id;
          delete item.shop_id;
          return item;
        });

        setFinalUserRowsWithoutID(reportArray);
        break;
      case "reports_orders":
      case "orders":

        for (let i = 0; i < finalUserRows.length; i++) {
          finalUserRows[i].create_time = new Date(Number(finalUserRows[i].create_time) * 1000).toLocaleString('en-US',
            {
              timeZone: 'Asia/Kuala_Lumpur',
              year: 'numeric', month: 'short', day: 'numeric',
              hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
            });
        };

        let newOrderArray = JSON.parse(JSON.stringify(finalUserRows));
        newOrderArray.map(function (item) {
          delete item.id;
          delete item.temp_id;
          delete item.shop_id;
          return item;
        });

        setFinalUserRowsWithoutID(newOrderArray);
        break;
      case "users":
        break;
      case "authorization":
        break;
      default:
        break;
    }
  }

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <p>{info.title}</p>
        <div className="buttonSections">
          <div className="linkContainer" style={{ display: info.addNewButton }}>
            <Link to={info.addNewLink} style={{ textDecoration: "none" }} className="addNewLink" >
              Add New
            </Link>
          </div>
          <CSVLink className="exportButton"
            onClick={generateCsvRows}
            data={finalUserRowsWithoutID}
            style={{ display: info.exportButton }}
            filename={(type + "_export_" + new Date().toLocaleString('en-US',
              {
                timeZone: 'Asia/Kuala_Lumpur',
                year: 'numeric', month: 'numeric', day: 'numeric',
                hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
              }
            )).replace(',', '').replace(' ', '_')}>Export</CSVLink>
        </div>
      </div>
      <DataGrid
        className="datagrid"
        getRowHeight={() => 'auto'}

        initialState={{
          columns: {
            columnVisibilityModel:
            {
              shop_id: false,
              sale_price: false,
            },
          }
        }}
        rows={finalUserRows}
        columns={userColumns}
        pageSize={100}
        onCellEditCommit={(params, event) => {
          handleDataChange(params);
        }}
        rowsPerPageOptions={[100]}
        checkboxSelection={info.checkboxSelection}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedRows(newSelectionModel);
        }}
        selectionModel={selectedRows}
      />
    </div>
  )
}

export default Datatable;
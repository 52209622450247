import "../../Blogs.scss";
import HomeNavbar from "../../../../../components/homeNavbar/HomeNavbar";
import { AccessTime, RateReview, Visibility } from "@mui/icons-material";
import BlogBottomLikes from "../../../blog/BlogBottomLikes";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../../../firebaseconfig";
import { useEffect, useState } from "react";
import { collection, doc, documentId, increment, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { Helmet } from "react-helmet-async";


const OrderReportBlog = () => {

    const [user, loading, error] = useAuthState(auth);
    const blogDoc = doc(db, "count", "blogs_tt_order_report");
    const [documentDetails, setDocumentDetails] = useState({});
    useEffect(() => {

        async function increaseView() {
            await setDoc(blogDoc, {
                view_count: increment(1)
            }, { merge: true });
        }

        increaseView();

        async function getDocumentDetails() {
            const docCollection = collection(db, "count");
            const docQuery = query(docCollection,
                where(documentId(), "==", "blogs_tt_order_report"));

            onSnapshot(docQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {

                    setDocumentDetails(change.doc.data());
                })
            });
        }

        getDocumentDetails();
    }, []);

    return (
        <div className="homeContainer">
        <Helmet>
            <title>TactiBots | Order Report</title>
            <meta property="description" content="Order Report is a report to overview all your orders within specified period. You can detect abnormal orders easily and make remark on each of them." />
            <meta property="og:description" content="Order Report is a report to overview all your orders within specified period. You can detect abnormal orders easily and make remark on each of them." />
            <meta property="og:url" content="https://tactibots.com/blogs/tactibots_tutorials/order_report"></meta>
            <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Ftactibots_tutorials%2Forder_report%2Fthumbnail.PNG?alt=media&token=08807829-92dd-4ca4-9282-c99cac7a34f5"></meta>
        </Helmet>
            <HomeNavbar active={"blogContainer"} />
            <div className="singleBlogContainer" name="blogContainer">
                <div className="info">
                    <div className="time">
                    <AccessTime></AccessTime>
                        2022 November 13, 5:12pm
                    </div>
                    <div className="author">
                        <RateReview></RateReview>
                        TactiBots
                    </div>
                    <div className="time">
                        <Visibility></Visibility>
                        {
                            typeof (documentDetails) !== "undefined" ? documentDetails.view_count : 0
                        } views
                    </div>
                </div>
                <h1 className="title">Order Report</h1>
                    <img className="thumbnail"
                        src="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Ftactibots_tutorials%2Forder_report%2Fthumbnail.PNG?alt=media&token=08807829-92dd-4ca4-9282-c99cac7a34f5"
                        alt=""
                    />
                <div className="content">
                    <h3>What is Order Report?</h3>
                    <p>Order Report is a report to overview all your orders within specified period.
                    <br></br>You can detect abnormal orders easily and make remark on each of them.
                    </p>
                    <h3>How to Generate Order Report?</h3>
                    <h4>By Order Creation Date</h4>
                    <ol>
                        <li>Select 'Orders' Tab</li>
                        <li>Select the Order Creation Date (Recommended: 60 days up to today)</li>
                        <li>Generate</li>
                        <li>Filter Order Status</li>
                    </ol>
                    <p>You can refer to the image below.</p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/orderReport/steps.PNG')}
                        alt=""
                    />
                    <h4>By Search</h4>
                    <ol>
                        <li>Select 'Orders' Tab</li>
                        <li>Select 'Order ID' or 'Tracking Number'</li>
                        <li>Type minimum of 3 Characters</li>
                        <li>Generate</li>
                    </ol>
                    <p>You can refer to the image below.</p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/orderReport/steps_2.PNG')}
                        alt=""
                    />

                    <h3>For Order Creation Date, you can type N number of days up to today as such.</h3>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/orderReport/days.PNG')}
                        alt=""
                    />
                    <h3>For Order Status, we currently have</h3>
                    <ol>
                        <li>All</li>
                        <li>Overcharged</li>
                        <li>Undercharged</li>
                        <li>Cancel</li>
                        <li>Return</li>
                        <li>Unpaid</li>
                        <li>Adjustment</li>
                    </ol>
                    <p>Orders that are recommended to be reviewed are <b>Overcharged, Return and Adjustment</b>.</p>
                    <h3>Example</h3>
                    <p>This is a Shopee Order which has Overcharge Shipping Fee of -RM6.70.
                    <br></br> You can make a remark after you have progress on the report.
                    </p>
                    <img
                        src={require('../../../../../pages/general/blogs/tactibotsTutorials/orderReport/example.PNG')}
                        alt=""
                    />

                </div>
                <BlogBottomLikes document_id={"blogs_tt_order_report"} document_details={documentDetails} user={user} />
            </div>
        </div>
    )
}

export default OrderReportBlog;
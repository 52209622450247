import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { useState } from "react";
import { arrayRemove, arrayUnion, doc, increment, setDoc } from "firebase/firestore";
import { Tooltip } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../firebaseconfig";

const BlogBottomLikes = ({ document_id, document_details }) => {

    const [user, loading, error] = useAuthState(auth);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("")
    const [feedback, setFeedback] = useState("");
    const blogDoc = doc(db, "count", document_id);

    return (
        <div className="bottom">
            <br></br>
            <h2>Is this post helpful ?</h2>

            {
                user === null ? <div></div> :
                <div className="likes">
                    <Tooltip className="icon" title="Like" style={{ fontSize: "40px" }} onClick={async () => {
    
                        if(typeof(document_details.like_by) === "undefined"){
                            await setDoc(blogDoc, {
                                like_by: arrayUnion(user.uid),
                                like_count: increment(1)
                            }, { merge: true });
                            return;
                        }
    
                        if (document_details.like_by.includes(user.uid)) {
                            await setDoc(blogDoc, {
                                like_by: arrayRemove(user.uid),
                                like_count: increment(-1)
                            }, { merge: true });
                        }
                        else {
                            if (document_details.dislike_by.includes(user.uid)) {
                                await setDoc(blogDoc, {
                                    like_by: arrayUnion(user.uid),
                                    dislike_by: arrayRemove(user.uid),
                                    like_count: increment(2)
                                }, { merge: true });
                            }
                            else {
                                await setDoc(blogDoc, {
                                    like_by: arrayUnion(user.uid),
                                    like_count: increment(1)
                                }, { merge: true });
                            }
                        }
                    }}>
                        <ThumbUp />
                    </Tooltip>
                    <p style={{padding: "5px", borderRadius: "5px", backgroundColor: "#0071bd28"}}>
                        {
                            typeof (document_details.like_count) !== "undefined" ? document_details.like_count : 0
                        }
                    </p>
                    <Tooltip className="icon" title="Dislike" style={{ fontSize: "40px" }} onClick={async () => {
                        if(typeof(document_details.dislike_by) === "undefined"){
                            await setDoc(blogDoc, {
                                dislike_by: arrayUnion(user.uid),
                                like_count: increment(-1)
                            }, { merge: true });
                            return;
                        }
    
                        if (document_details.like_by.includes(user.uid)) {
                            await setDoc(blogDoc, {
                                like_by: arrayRemove(user.uid),
                                dislike_by: arrayUnion(user.uid),
                                like_count: increment(-2)
                            }, { merge: true });
                        }
                        else {
                            if (document_details.dislike_by.includes(user.uid)) {
                                await setDoc(blogDoc, {
                                    dislike_by: arrayRemove(user.uid),
                                    like_count: increment(1)
                                }, { merge: true });
                            }
                            else {
                                await setDoc(blogDoc, {
                                    dislike_by: arrayUnion(user.uid),
                                    like_count: increment(-1)
                                }, { merge: true });
                            }
                        }
                    }}>
                        <ThumbDown />
                    </Tooltip>
                </div>
            }
            <br></br>
            <h3>Name : </h3>
            <div className="name">
                <input onInput={(e) => { setName(e.target.value) }} ></input>
            </div>
            <h3>Phone Number : </h3>
            <div className="phoneNumber">
                <input onInput={(e) => { setPhoneNumber(e.target.value) }} ></input>
            </div>
            <h3>Email : </h3>
            <div className="email">
                <input onInput={(e) => { setEmail(e.target.value) }} ></input>
            </div>
            <h3>Feedback : </h3>
            <div className="feedback">
                <textarea onInput={(e) => { setFeedback(e.target.value) }} ></textarea>
            </div>
            <br></br>
            <button onClick={async () => {

                var uid = "";
                if(user !== null)
                    uid = user.uid;

                if(feedback === ""){
                    alert("Please fill in your Feedback!");
                    return;
                }
                
                await setDoc(blogDoc, {
                    feedback: arrayUnion(
                        {
                            details: feedback,
                            email: email,
                            name: name,
                            phone: phoneNumber,
                            uid: uid,
                            created_at: new Date()
                        })
                }, { merge: true });
                window.location.reload();
            }}>Submit</button>
        </div>
    )
}

export default BlogBottomLikes;
const DarkModeReducer = (state, action) => {
    var darkMode = window.localStorage.getItem('darkMode');
    switch (action.type) {
        case "LIGHT": {
            darkMode = 'false';
        }
        break;
        case "DARK": {
            darkMode = 'true';
        }
        break;
        default:
            darkMode = 'true';
    }
    window.localStorage.setItem('darkMode' , darkMode);

    return{
        darkMode: darkMode
    };

    switch (action.type) {
        case "LIGHT": {
            return {
                darkMode: false,
            }
        }
        case "DARK": {
            return {
                darkMode: true,
            }
        }
        case "TOGGLE": {
            return {
                darkMode: !state.darkMode,
            }
        }
        default:
            return {
                darkMode: false,
            };
    }
};

export default DarkModeReducer;
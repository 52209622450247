import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { DarkModeContextProvider } from './context/darkModeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

/*
<React.StrictMode>

</React.StrictMode>
*/
const helmetContext = {};

root.render(
  <DarkModeContextProvider>
    <HelmetProvider context={helmetContext}>
      <App />
      </HelmetProvider>
  </DarkModeContextProvider>
);
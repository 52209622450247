import { getBytes, uploadBytes, getStorage, ref } from "firebase/storage";
//import PDFJS from 'pdfjs-dist/webpack';
import { useEffect, useState } from "react";
import { PDFDocument, ParseSpeeds, rgb } from 'pdf-lib'
import { useLocation } from "react-router-dom";
import { collection, documentId, onSnapshot, query, where, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../../firebaseconfig";
import { useAuthState } from "react-firebase-hooks/auth";
import Datatable from "../../../components/datatable/Datatable";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import * as ShopeeFunctions from "../../../utils/shopee";
import * as LazadaFunctions from "../../../utils/lazada";
import convertToNearestDay from "../../../utils/convertToNearestDay";
import { Buffer } from "buffer";



const ShipmentPrintAWB = ({ shopList, userMetadata }) => {

    const storage = getStorage();
    const [user] = useAuthState(auth);
    const [shipmentDocument, setShipmentDocument] = useState({});
    const [processReady, setProcessReady] = useState(false);
    const [imageBlob, setImageBlob] = useState(new ArrayBuffer);
    const [pdfBlob, setPdfBlob] = useState(new Blob);
    const [awbOrderList, setAwbOrderList] = useState([]);
    const [randomNumber, setRandomNumber] = useState(0);

    const location = useLocation();
    const shipmentDocumentId = location.search.substring(1);

    var awb_list = [];


    useEffect(() => {
        async function getShipment() {
            const shipmentCollection = collection(db, "ecommerce/shipment/shipment");
            const shipmentQuery = query(shipmentCollection,
                where(documentId(), "==", shipmentDocumentId));

            onSnapshot(shipmentQuery, (snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    var shippingDocument = change.doc.data();

                    for(let i = 0; i < shippingDocument.order_details.length; i++)
                    {
                        if(shippingDocument.order_details[i].platform === "Lazada")
                        {
                            shippingDocument.order_details[i].create_time = shippingDocument.order_details[i].create_time.seconds * 1000;
                        }
                    }
                    setShipmentDocument(shippingDocument);
                    setProcessReady(true);
                })
            });
        }


        async function getShipmentOrders() {
            var awb_order_list = [];

            const shopeeShipmentOrderCollection = collection(db, "ecommerce/shopee/airway_bill");
            const shopeeShipmentOrderQuery = query(shopeeShipmentOrderCollection,
                where("shipment_id", "==", shipmentDocumentId));

            onSnapshot(shopeeShipmentOrderQuery, (snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    awb_order_list.push(change.doc.data());
                });

                setAwbOrderList(awb_order_list);
                setRandomNumber(Math.random());
            });

            const lazadaShipmentOrderCollection = collection(db, "ecommerce/lazada/airway_bill");
            const lazadaShipmentOrderQuery = query(lazadaShipmentOrderCollection,
                where("shipment_id", "==", shipmentDocumentId));

            onSnapshot(lazadaShipmentOrderQuery, (snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    awb_order_list.push(change.doc.data());
                });

                setAwbOrderList(awb_order_list);
                setRandomNumber(Math.random());
            });
        }

        getShipment();
        getShipmentOrders();
    }, []);

    useEffect(() => {


        async function getPdf() {
            if (typeof (shipmentDocument.order_details) === "undefined")
                return;

            const mergedPdf = await PDFDocument.create();
            for (let i = 0; i < shipmentDocument.order_details.length; i++) {
                var findIndex = awbOrderList.findIndex(ord =>
                    String(ord.order_id) === String(shipmentDocument.order_details[i].order_id) &&
                    String(ord.shop_id) === String(shipmentDocument.order_details[i].shop_id));

                let currentPdfBytes;
                var validPdfFile = false;
                if (findIndex !== -1) {
                    currentPdfBytes = Buffer.from(awbOrderList[findIndex].awb, "base64");
                    validPdfFile = true;
                }

                if (validPdfFile === false) {
                    shipmentDocument.order_details[i].shipment_status = "Pending";
                    continue;
                }

                if (currentPdfBytes.byteLength >= 30000) {
                    shipmentDocument.order_details[i].shipment_status = "OK";
                }
                else {
                    shipmentDocument.order_details[i].shipment_status = "Invalid";
                    continue;
                }

                let pageNumber = Math.floor(shipmentDocument.order_details[i].item_details.length / 7) + 1;
                var totalQuantity = 0;
                for (let j = 0; j < shipmentDocument.order_details[i].item_details.length; j++) {
                    totalQuantity += Number(shipmentDocument.order_details[i].item_details[j].quantity);
                }

                // Draw first Page with Airway Bill
                const currentPdf = await drawAirwayBill(currentPdfBytes, shipmentDocument.order_details[i], pageNumber, totalQuantity);
                const modifiedPdf = await PDFDocument.load(currentPdf);
                const modifiedPdfPages = await mergedPdf.copyPages(modifiedPdf, modifiedPdf.getPageIndices());
                modifiedPdfPages.forEach((page) => mergedPdf.addPage(page));

                for (let newPage = 2; newPage <= pageNumber; newPage++) {
                    const skuPage = mergedPdf.addPage();
                    skuPage.setSize(297.6378, 419.52756);

                    skuPage.drawText('Platform : ' + shipmentDocument.order_details[i].platform, {
                        x: 10,
                        y: 400,
                        size: 15,
                    });
                    
                    skuPage.drawText('Shop Name : ' + shipmentDocument.order_details[i].name, {
                        x: 10,
                        y: 380,
                        size: 15,
                    });
                    
                    skuPage.drawText('Order ID : ' + shipmentDocument.order_details[i].order_id, {
                        x: 10,
                        y: 360,
                        size: 15,
                    });


                    // Total Qty
                    skuPage.drawRectangle({
                        x: 240,
                        y: 60,
                        width: 50,
                        height: 40,
                        color: rgb(1, 1, 1),
                        opacity: 0.5,
                        borderOpacity: 0.75,
                        borderColor: rgb(0, 0, 0),
                        borderWidth: 2
                    });
                    skuPage.drawText('Total Qty',
                        {
                            x: 244,
                            y: 88,
                            size: 10,
                            lineHeight: 30,
                        });

                    skuPage.drawText(totalQuantity.toFixed(), {
                        x: 258,
                        y: 65,
                        size: 20,
                        lineHeight: 30,
                    })

                    skuPage.drawText("Pg " + newPage + " / " + pageNumber,
                        {
                            x: 244,
                            y: 8,
                            size: 10,
                            lineHeight: 30,
                        });


                        skuPage.drawText('#',
                        {
                            x: 5,
                            y: 340,
                            size: 8,
                            lineHeight: 30,
                        });

                        skuPage.drawText('SKU',
                        {
                            x: 15,
                            y: 340,
                            size: 8,
                            lineHeight: 30,
                        });

                        skuPage.drawText('Variation',
                        {
                            x: 100,
                            y: 340,
                            size: 8,
                            lineHeight: 30,
                        });

                        skuPage.drawText('Qty',
                        {
                            x: 200,
                            y: 340,
                            size: 8,
                            lineHeight: 30,
                        });

                    var currentPageNumberSequence = -1;
                    for (let currentSkuSequence = 6; currentSkuSequence < shipmentDocument.order_details[i].item_details.length; currentSkuSequence++) {
                        currentPageNumberSequence++;
                        skuPage.drawLine({
                            start: { x: 2, y: 335 + currentPageNumberSequence * -15 },
                            end: { x: 230, y: 335 + currentPageNumberSequence * -15 },
                            thickness: 1,
                        })

                        var finalSku = shipmentDocument.order_details[i].item_details[currentSkuSequence].sku;

                        // Write Number
                        skuPage.drawText((currentSkuSequence + 1).toFixed(),
                            {
                                x: 5,
                                y: 325 + currentPageNumberSequence * -15,
                                size: 8,
                                lineHeight: 30,
                            });

                        // Write SKU
                        if (finalSku.length >= 20) {
                            finalSku = finalSku.substring(0, 20) + "...";
                        }

                        skuPage.drawText(finalSku,
                            {
                                x: 15,
                                y: 325 + currentPageNumberSequence * -15,
                                size: 8,
                                lineHeight: 30,
                            });

                        // Write Variation
                        var variation_name = shipmentDocument.order_details[i].item_details[currentSkuSequence].variation;
                        if (variation_name.length >= 20) {
                            variation_name = variation_name.substring(0, 20) + "...";
                        }

                        skuPage.drawText(variation_name,
                            {
                                x: 100,
                                y: 325 + currentPageNumberSequence * -15,
                                size: 8,
                                lineHeight: 30,
                            });

                        // Write Quantity
                        skuPage.drawText(shipmentDocument.order_details[i].item_details[currentSkuSequence].quantity.toFixed(),
                            {
                                x: 200,
                                y: 325 + currentPageNumberSequence * -15,
                                size: 8,
                                lineHeight: 30,
                            });
                    }
                }
                // Draw remaining pages if theres any

            }

            const mergedPdfFile = await mergedPdf.save();
            var blob = new Blob([mergedPdfFile], { type: "application/pdf" });
            var link = window.URL.createObjectURL(blob);
            setPdfBlob(link);
            setShipmentDocument(shipmentDocument);
        }
        getPdf();
    }, [randomNumber, awbOrderList])

    useEffect(() => {

        if (typeof (shipmentDocument.order_details) === "undefined")
            return;

        async function processOrders() {




            var tempRows = shipmentDocument.order_details;
            var currentPlatformShop = "";
            var currentShop = {};
            var currentPickupAddress = {};

            for (let i = 0; i < tempRows.length; i++) {
                if (currentPlatformShop !== tempRows[i].platform + tempRows[i].shop_id) {
                    currentPlatformShop = tempRows[i].platform + tempRows[i].shop_id;
                    for (let j = 0; j < shopList.length; j++) {
                        if (shopList[j].platform === tempRows[i].platform && shopList[j].shop_id === tempRows[i].shop_id) {
                            currentShop = shopList[j];

                            switch (tempRows[i].platform) {
                                case "Shopee":
                                    const shopeeAddressListTimestamp = Math.floor(new Date().getTime() / 1000);
                                    const shopeeAddressListSign = ShopeeFunctions.Signature(shopeeAddressListTimestamp, "/api/v2/logistics/get_address_list", currentShop.access_token, currentShop.shop_id);

                                    await fetch(
                                        "https://partner.shopeemobile.com/api/v2/logistics/get_address_list?" +
                                        "access_token=" + currentShop.access_token +
                                        "&partner_id=2004820" +
                                        "&sign=" + shopeeAddressListSign +
                                        "&shop_id=" + currentShop.shop_id +
                                        "&timestamp=" + shopeeAddressListTimestamp
                                        ,
                                        {
                                            method: 'GET',
                                        }
                                    )
                                        .then((response) => response.json())
                                        .then((data) => {

                                            for (let addressIndex = 0; addressIndex < data.response.address_list.length; addressIndex++) {
                                                var pickUpAddressFound = false;
                                                for (let addressTypeIndex = 0; addressTypeIndex < data.response.address_list[addressIndex].address_type.length; addressTypeIndex++) {
                                                    if (data.response.address_list[addressIndex].address_type[addressTypeIndex] === "PICKUP_ADDRESS") {
                                                        pickUpAddressFound = true;
                                                        currentPickupAddress = data.response.address_list[addressIndex];
                                                        break;
                                                    }
                                                }

                                                if (pickUpAddressFound === true)
                                                    break;
                                            }
                                        });
                                    break;
                                default:
                                    break;
                            }

                            break;
                        }
                    }
                }

                switch (tempRows[i].platform) {
                    case "Shopee":
                        var dropoffParameterDocument = {};
                        var pickupParameterDocument = {};
                        var createShippingDocument = {};
                        var shippingDocumentResultDocument = {};

                        if (tempRows[i].trackingNumber === "") {
                            var shipping_mode = "";
                            const shopeeShippingParameterTimestamp = Math.floor(new Date().getTime() / 1000);
                            const shopeeShippingParameterSign = ShopeeFunctions.Signature(shopeeShippingParameterTimestamp, "/api/v2/logistics/get_shipping_parameter", currentShop.access_token, currentShop.shop_id);
                            await fetch(
                                "https://partner.shopeemobile.com/api/v2/logistics/get_shipping_parameter?" +
                                "access_token=" + currentShop.access_token +
                                "&order_sn=" + tempRows[i].order_id +
                                "&partner_id=2004820" +
                                "&sign=" + shopeeShippingParameterSign +
                                "&shop_id=" + currentShop.shop_id +
                                "&timestamp=" + shopeeShippingParameterTimestamp
                                ,
                                {
                                    method: 'GET',
                                }
                            )
                                .then((response) => response.json())
                                .then((data) => {

                                    if (data.response.info_needed.dropoff.length > 0) {
                                        shipping_mode = "dropoff";
                                        dropoffParameterDocument = data.response.dropoff;
                                    }
                                    else if (data.response.info_needed.pickup.length > 0) {
                                        shipping_mode = "pickup";

                                        for (let i = 0; i < data.response.pickup.address_list.length; i++) {
                                            if (data.response.pickup.address_list[i].address_flag.includes("pickup_address")) {
                                                pickupParameterDocument = data.response.pickup.address_list[i];
                                                break;
                                            }
                                        }
                                    }
                                });

                            const shopeeShipOrderTimestamp = Math.floor(new Date().getTime() / 1000);
                            const shopeeShipOrderSign = ShopeeFunctions.Signature(shopeeShipOrderTimestamp, "/api/v2/logistics/ship_order", currentShop.access_token, currentShop.shop_id);
                            var shopeeShipOrderDocument = {};
                            switch (shipping_mode) {
                                case "pickup":
                                    await fetch(
                                        "https://partner.shopeemobile.com/api/v2/logistics/ship_order?" +
                                        "access_token=" + currentShop.access_token +
                                        "&order_sn=" + tempRows[i].order_id +
                                        "&partner_id=2004820" +
                                        "&sign=" + shopeeShipOrderSign +
                                        "&shop_id=" + currentShop.shop_id +
                                        "&timestamp=" + shopeeShipOrderTimestamp
                                        ,
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                            },
                                            body:
                                                JSON.stringify({
                                                    order_sn: tempRows[i].order_id,
                                                    pickup: {
                                                        address_id: pickupParameterDocument.address_id,
                                                        pickup_time_id: pickupParameterDocument.time_slot_list[0].pickup_time_id,
                                                    },
                                                    dropoff: {
                                                    }
                                                })
                                        }
                                    )
                                        .then((response) => response.json())
                                        .then((data) => {
                                            shopeeShipOrderDocument = data;
                                        });
                                    break;
                                case "dropoff":
                                    await fetch(
                                        "https://partner.shopeemobile.com/api/v2/logistics/ship_order?" +
                                        "access_token=" + currentShop.access_token +
                                        "&order_sn=" + tempRows[i].order_id +
                                        "&partner_id=2004820" +
                                        "&sign=" + shopeeShipOrderSign +
                                        "&shop_id=" + currentShop.shop_id +
                                        "&timestamp=" + shopeeShipOrderTimestamp
                                        ,
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                            },
                                            body:
                                                JSON.stringify({
                                                    order_sn: tempRows[i].order_id,
                                                    pickup: {
                                                    },
                                                    dropoff: {
                                                        sender_real_name: currentShop.name
                                                    }
                                                })
                                        }
                                    )
                                        .then((response) => response.json())
                                        .then((data) => {
                                            shopeeShipOrderDocument = data;
                                        });
                                    break;
                                default:
                                    break;
                            }

                            do {
                                const shopeeGetTrackingNumberTimestamp = Math.floor(new Date().getTime() / 1000);
                                const shopeeGetTrackingNumberSign = ShopeeFunctions.Signature(shopeeGetTrackingNumberTimestamp, "/api/v2/logistics/get_tracking_number", currentShop.access_token, currentShop.shop_id);

                                await fetch(
                                    "https://partner.shopeemobile.com/api/v2/logistics/get_tracking_number?" +
                                    "access_token=" + currentShop.access_token +
                                    "&order_sn=" + tempRows[i].order_id +
                                    "&partner_id=2004820" +
                                    "&sign=" + shopeeGetTrackingNumberSign +
                                    "&shop_id=" + currentShop.shop_id +
                                    "&timestamp=" + shopeeGetTrackingNumberTimestamp
                                    ,
                                    {
                                        method: 'GET',
                                    }
                                )
                                    .then((response) => response.json())
                                    .then((data) => {
                                        tempRows[i].trackingNumber = data.response.tracking_number;
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });

                            } while (tempRows[i].trackingNumber === "");
                        }

                        const shopeeCreateShippingDocumentTimestamp = Math.floor(new Date().getTime() / 1000);
                        const shopeeCreateShippingDocumentSign = ShopeeFunctions.Signature(shopeeCreateShippingDocumentTimestamp, "/api/v2/logistics/create_shipping_document", currentShop.access_token, currentShop.shop_id);

                        await fetch(
                            "https://partner.shopeemobile.com/api/v2/logistics/create_shipping_document?" +
                            "access_token=" + currentShop.access_token +
                            "&order_sn=" + tempRows[i].order_id +
                            "&partner_id=2004820" +
                            "&sign=" + shopeeCreateShippingDocumentSign +
                            "&shop_id=" + currentShop.shop_id +
                            "&timestamp=" + shopeeCreateShippingDocumentTimestamp
                            ,
                            {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                                body:
                                    JSON.stringify({
                                        order_list: [{
                                            order_sn: tempRows[i].order_id,
                                            tracking_number: tempRows[i].tracking_number
                                        }]
                                    })
                            }
                        )
                            .then((response) => response.json())
                            .then((data) => createShippingDocument = data);

                        const shopeeShippingDocumentResultTimestamp = Math.floor(new Date().getTime() / 1000);
                        const shopeeShippingDocumentResultSign = ShopeeFunctions.Signature(shopeeShippingDocumentResultTimestamp, "/api/v2/logistics/get_shipping_document_result", currentShop.access_token, currentShop.shop_id);
                        await fetch(
                            "https://partner.shopeemobile.com/api/v2/logistics/get_shipping_document_result?" +
                            "access_token=" + currentShop.access_token +
                            "&order_sn=" + tempRows[i].order_id +
                            "&partner_id=2004820" +
                            "&sign=" + shopeeShippingDocumentResultSign +
                            "&shop_id=" + currentShop.shop_id +
                            "&timestamp=" + shopeeShippingDocumentResultTimestamp
                            ,
                            {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                                body:
                                    JSON.stringify({
                                        order_list: [{
                                            order_sn: tempRows[i].order_id,
                                            shipping_document_type: "THERMAL_AIR_WAYBILL",
                                        }]
                                    })
                            }
                        )
                            .then((response) => response.json())
                            .then((data) => shippingDocumentResultDocument = data);

                        const shopeeDownloadShippingDocumentTimestamp = Math.floor(new Date().getTime() / 1000);
                        const shopeeDownloadShippingDocumentSign = ShopeeFunctions.Signature(shopeeDownloadShippingDocumentTimestamp, "/api/v2/logistics/download_shipping_document", currentShop.access_token, currentShop.shop_id);
                        await fetch(
                            "https://partner.shopeemobile.com/api/v2/logistics/download_shipping_document?" +
                            "access_token=" + currentShop.access_token +
                            "&order_sn=" + tempRows[i].order_id +
                            "&partner_id=2004820" +
                            "&sign=" + shopeeDownloadShippingDocumentSign +
                            "&shop_id=" + currentShop.shop_id +
                            "&timestamp=" + shopeeDownloadShippingDocumentTimestamp
                            ,
                            {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                                body:
                                    JSON.stringify({
                                        shipping_document_type: "THERMAL_AIR_WAYBILL",
                                        order_list: [{
                                            order_sn: tempRows[i].order_id
                                        }]
                                    })
                            }
                        )
                            .then((response) => response.blob())
                            .then(async (data) => {
                                const file = new Blob([data], { type: 'application/pdf' });
                                const bucket = "gs://tactibots-1605018905444.appspot.com/shopee/airway_bill/"
                                    + convertToNearestDay(tempRows[i].create_time, 8) + '/'
                                    + currentShop.shop_id + "/"
                                    + tempRows[i].order_id + ".pdf";
                                const storageRef = ref(storage, bucket);
                                await uploadBytes(storageRef, file);
                            });

                        break;
                    case "Lazada":
                        //Lazada API

                        const lazadaGetAwbDocumentPdfTimestamp = Math.floor(new Date().getTime());
                        var orderItemIdString = "[";
                        for (let j = 0; j < tempRows[i].item_details.length; j++) {
                            orderItemIdString += tempRows[i].item_details[j].order_item_id + ",";
                        }

                        orderItemIdString = orderItemIdString.slice(0, -1) + "]";

                        const lazadaGetAwbDocumentPdfParams =
                            ["access_token" + currentShop.access_token,
                                "app_key118185",
                            "order_item_ids" + orderItemIdString,
                                "sign_methodsha256",
                            "timestamp" + lazadaGetAwbDocumentPdfTimestamp]
                        const lazadaGetAwbDocumentPdfSign = LazadaFunctions.Signature(lazadaGetAwbDocumentPdfParams, "/order/document/awb/pdf/get");

                        console.log(
                            "https://api.lazada.com.my/rest/order/document/awb/pdf/get?" +
                            "access_token=" + currentShop.access_token +
                            "&app_key=118185" +
                            "&order_item_ids=" + orderItemIdString + "" +
                            "&timestamp=" + lazadaGetAwbDocumentPdfTimestamp +
                            "&sign=" + lazadaGetAwbDocumentPdfSign +
                            "&sign_method=sha256");

                        await fetch(
                            "https://api.lazada.com.my/rest/order/document/awb/pdf/get?" +
                            "access_token=" + currentShop.access_token +
                            "&app_key=118185" +
                            "&order_item_ids=" + orderItemIdString + "" +
                            "&timestamp=" + lazadaGetAwbDocumentPdfTimestamp +
                            "&sign=" + lazadaGetAwbDocumentPdfSign +
                            "&sign_method=sha256"
                            ,
                            {
                                method: 'GET',
                            },

                        )
                            .then((response) => response.json())
                            .then((data) => {
                                console.log(data);
                            });
                        break;
                    default:
                        break;
                }
            }

            setProcessReady(true);
        }

        //processOrders();



    }, [shipmentDocument]);

    async function drawAirwayBill(currentOrderPdfBytes, order_details, pageNumber, totalQuantity) {

        const pdfDoc = await PDFDocument.load(currentOrderPdfBytes, {
            parseSpeed: ParseSpeeds.Fastest,
        });

        const pages = pdfDoc.getPages();
        pages.forEach(page => {
            page.scaleContent(1, 0.76);
            page.translateContent(0, 99);
        });

        const savePdf = await pdfDoc.save();

        const resizedPdfDoc = await PDFDocument.load(savePdf, {
            parseSpeed: ParseSpeeds.Fastest,
        });

        const afterScalePages = resizedPdfDoc.getPages();
        afterScalePages.forEach(page => {

            if (pageNumber !== 1) {
                page.drawText("Pg 1 / " + pageNumber,
                    {
                        x: 244,
                        y: 8,
                        size: 10,
                        lineHeight: 30,
                    });
            }

            // Total Qty
            page.drawRectangle({
                x: 240,
                y: 60,
                width: 50,
                height: 40,
                color: rgb(1, 1, 1),
                opacity: 0.5,
                borderOpacity: 0.75,
                borderColor: rgb(0, 0, 0),
                borderWidth: 2
            });
            page.drawText('Total Qty',
                {
                    x: 244,
                    y: 88,
                    size: 10,
                    lineHeight: 30,
                });
            page.drawText(totalQuantity.toFixed(), {
                x: 258,
                y: 65,
                size: 20,
                lineHeight: 30,
            })

            page.drawText('#',
                {
                    x: 5,
                    y: 93,
                    size: 8,
                    lineHeight: 30,
                });

            page.drawText('SKU',
                {
                    x: 15,
                    y: 93,
                    size: 8,
                    lineHeight: 30,
                });

            page.drawText('Variation',
                {
                    x: 100,
                    y: 93,
                    size: 8,
                    lineHeight: 30,
                });

            page.drawText('Qty',
                {
                    x: 200,
                    y: 93,
                    size: 8,
                    lineHeight: 30,
                });

            for (let i = 0; i < order_details.item_details.length; i++) {
                if(i === 6)
                    break;

                page.drawLine({
                    start: { x: 2, y: 88 + i * -15 },
                    end: { x: 230, y: 88 + i * -15 },
                    thickness: 1,
                })

                var finalSku = order_details.item_details[i].sku;

                // Write Number
                page.drawText((i + 1).toFixed(),
                    {
                        x: 5,
                        y: 78 + i * -15,
                        size: 8,
                        lineHeight: 30,
                    });

                // Write SKU
                if (finalSku.length >= 20) {
                    finalSku = finalSku.substring(0, 20) + "...";
                }

                page.drawText(finalSku,
                    {
                        x: 15,
                        y: 78 + i * -15,
                        size: 8,
                        lineHeight: 30,
                    });

                // Write Variation
                var variation_name = order_details.item_details[i].variation;
                if (variation_name.length >= 20) {
                    variation_name = variation_name.substring(0, 20) + "...";
                }

                page.drawText(variation_name,
                    {
                        x: 100,
                        y: 78 + i * -15,
                        size: 8,
                        lineHeight: 30,
                    });

                // Write Quantity
                page.drawText(order_details.item_details[i].quantity.toFixed(),
                    {
                        x: 200,
                        y: 78 + i * -15,
                        size: 8,
                        lineHeight: 30,
                    });
            }
        });

        return await resizedPdfDoc.save();
    }

    return (

        <div className="printShipment">
            <Sidebar />
            <div className="printShipmentContainer">
                <Navbar userMetadata={userMetadata} />
                <div className="printShipmentDetails">
                    <div className="printDetails">
                        {
                            typeof (shipmentDocument.order_details) !== "undefined" ?
                                <Datatable type={"shipment_orders"} overrideDataRow={shipmentDocument.order_details} />
                                : <p></p>
                        }
                    </div>
                    <div style={{ height: "100vh" }} className="printContainer">
                        <iframe src={pdfBlob}
                            type="application/pdf" width="100%" height="100%"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShipmentPrintAWB;
import "./report.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Datatable from "../../../components/datatable/Datatable";
import { DateRangePicker } from "react-date-range";
import format from 'date-fns/format'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "../../../components/daterangepicker/dateRangePickerComp.scss";
import { useEffect, useState } from "react";
import Popup from "../../../components/popup/Popup";
import { collection, limit, orderBy, query, where, getDocs, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../../firebaseconfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { Center, ChakraProvider, Spinner } from "@chakra-ui/react";
import convertToNearestDay from "../../../utils/convertToNearestDay";
import { Link, useLocation } from "react-router-dom";
import { FormControl, MenuItem, Select } from "@mui/material";

const Report = ({ shopList, userMetadata }) => {
    const location = useLocation();
    const shopeeShopList = shopList.filter(item => item.platform === "Shopee");
    const lazadaShopList = shopList.filter(item => item.platform === "Lazada");

    const [rowCount, setRowCount] = useState(0);
    const [totalOrderAmount, setTotalOrderAmount] = useState(0);
    const [totalShippingOverchargeAmount, setShippingOverChargeAmount] = useState(0);
    const [totalShippingUnderchargeAmount, setShippingUnderChargeAmount] = useState(0);

    const [user] = useAuthState(auth);
    const [minimumDate, setMinimumDate] = useState(new Date());
    const [reportCreatedDate, setReportCreatedDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [processingText, setProcessingText] = useState("Processing ...");
    const [orderCreatedDate, setOrderCreatedDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [previousShopeeOrderCreatedDate, setPreviousShopeeOrderCreatedDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [previousLazadaOrderCreatedDate, setPreviousLazadaOrderCreatedDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [platform, setPlatform] = useState('Shopee');
    const [orderSearch, setOrderSearch] = useState('order_id');
    const [orderSearchString, setOrderSearchString] = useState('');
    const orderSearchStringOnChange = event => {
        setOrderSearchString(event.target.value);
    }

    const handleOrderStatusChange = (event) => {
        setOrderStatus(event.target.value);
    };
    const handlePlatformChange = (event) => {
        setPlatform(event.target.value);
    };

    const handleOrderSearchChange = (event) => {
        setOrderSearch(event.target.value);
    };

    const [profitLossStatistics, setProfitLossStatistics] = useState({});
    const [netProfit, setNetProfit] = useState(0);
    const [totalCogs, setTotalCogs] = useState(0);
    const [itemTable, setItemTable] = useState([]);


    // open close
    const [runningReport, setRunningReport] = useState(false);
    const [orderStatus, setOrderStatus] = useState('');
    const [openPopup, setOpenPopup] = useState(false);

    const [isHoveringConnectButton, setisHoveringConnectButton] = useState(false);
    const handleMouseEnterConnectButton = () => {
        setisHoveringConnectButton(true);
    };
    const handleMouseLeaveConnectButton = () => {
        setisHoveringConnectButton(false);
    };

    const [finalSkuRows, setFinalSkuRows] = useState([]);
    const [finalOrderRows, setFinalOrderRows] = useState([]);
    const [fullRows, setFullRows] = useState([]);
    const [finalRows, setFinalRows] = useState([]);

    async function getProfitLossDataRows() {
        var endDate = convertToNearestDay(reportCreatedDate[0].endDate.getTime() / 1000, 8) + 86400;
        var startDate = convertToNearestDay(reportCreatedDate[0].startDate.getTime() / 1000, 8);

        let shopeeShopId = shopeeShopList.map(item => item.shop_id);

        const shopeeReportDocsCollection = collection(db, "ecommerce/shopee/order");
        const shopeeOrderQuery = query(shopeeReportDocsCollection,
            where("shop_id", "in", shopeeShopId),
            where("create_time", ">=", startDate),
            where("create_time", "<=", endDate),
            orderBy("create_time", "desc"),
            limit(100));

        var currentOrderRows = [];
        var createdInventory = [];

        const shopeeOrderQuerySnapshot = await getDocs(shopeeOrderQuery);

        var shopeeCount = 0;

        shopeeOrderQuerySnapshot.forEach((orderChange) => {
            var shopName = "";
            for (let index = 0; index < shopeeShopList.length; index++) {
                if (shopeeShopList[index].shop_id === orderChange.data().shop_id) {
                    shopName = shopeeShopList[index].name;
                    break;
                }
            };

            
            if (orderChange.data().order_status !== "CANCELLED") 
            {
                shopeeCount++;

                var trackingNumber = "";
                if (typeof (orderChange.data().logistic_details) !== "undefined") {
                    trackingNumber = orderChange.data().logistic_details.tracking_number;
                }

                let payment = 0;
                let order_amount =
                    orderChange.data().order_details.order_income.buyer_total_amount +
                    orderChange.data().order_details.order_income.voucher_from_shopee +
                    orderChange.data().order_details.order_income.coins -
                    orderChange.data().order_details.order_income.final_product_protection;

                if (typeof (orderChange.data().transaction_details) !== "undefined") {
                    for (let transactionIndex = 0;
                        transactionIndex < orderChange.data().transaction_details.length;
                        transactionIndex++) {
                        payment += orderChange.data().transaction_details[transactionIndex].amount;
                    }
                }

                currentOrderRows.push({
                    id: orderChange.id + "_" + orderChange.data().order_id,
                    temp_id: orderChange.id,
                    platform: "Shopee",
                    shop_id: orderChange.data().shop_id,
                    name: shopName,
                    remark: orderChange.data().remark,
                    order_id: orderChange.data().order_id,
                    tracking_number: trackingNumber,
                    create_time: orderChange.data().customer_details.create_time,
                    order_status: orderChange.data().customer_details.order_status,
                    status: orderChange.data().status,
                    shipping_overcharge: orderChange.data().order_details.order_income.buyer_paid_shipping_fee
                        + orderChange.data().order_details.order_income.final_shipping_fee,
                    shipping_fee: -orderChange.data().order_details.order_income.final_shipping_fee,
                    commission_fee: orderChange.data().order_details.order_income.commission_fee,
                    service_fee: orderChange.data().order_details.order_income.service_fee,
                    transaction_fee: orderChange.data().order_details.order_income.seller_transaction_fee,
                    payment: payment,
                    order_amount: order_amount
                });

                for (let skuIndex = 0; skuIndex < orderChange.data().customer_details.item_list.length; skuIndex++) {
                    // Use Child SKU first
                    var sku = orderChange.data().customer_details.item_list[skuIndex].model_sku;

                    // If NA, use Parent SKU
                    if (sku === "")
                        sku = orderChange.data().customer_details.item_list[skuIndex].item_sku;

                    // If NA, use ItemID_VariationID
                    if (sku === "")
                        sku = orderChange.data().customer_details.item_list[skuIndex].item_id + "_" 
                        + orderChange.data().customer_details.item_list[skuIndex].model_id;


                    // Get SKU before Space Bar
                    // Example: ABCDE(X5),ABCD(X4)
                    sku = sku.split(' ')[0];
                    sku = String(sku).toUpperCase();

                    // Split by Comma
                    var final_sku = "";
                    for (let k = 0; k < sku.split(',').length; k++) {
                        // Example : ABCDE(X5)
                        final_sku = sku.split(',')[k];

                        // Check Multiplier Special Rules
                        var multiplier = 1;
                        if (final_sku.endsWith(')') && final_sku.includes('(X')) {
                            // Get the string between '(X' and ')'
                            // Example : ABCDE(X5) will return 5
                            var tempMultiplier = final_sku.split('(')[final_sku.split('(').length - 1];
                            if (tempMultiplier.startsWith('X')) {
                                tempMultiplier = tempMultiplier.substring(1, tempMultiplier.length - 1);
                            }

                            // If valid number, get Multiplier
                            // Reset SKU and excludes Multiplier Part
                            if (isFinite(Number(tempMultiplier)) === true) {
                                multiplier = Number(tempMultiplier);
                                final_sku = final_sku.split('(')[final_sku.split('(').length - 2];
                            }
                        }


                        const findIndex = createdInventory.findIndex(obj => obj.sku === final_sku);
                        if (findIndex !== -1) {
                            createdInventory[findIndex].quantity +=
                                orderChange.data().customer_details.item_list[skuIndex].model_quantity_purchased * multiplier;
                        }
                        else {
                            createdInventory.push({
                                id: orderChange.id + "_" + orderChange.data().order_id + "_" + final_sku,
                                sku: final_sku,
                                item_name: orderChange.data().customer_details.item_list[skuIndex].item_name,
                                variation_name: orderChange.data().customer_details.item_list[skuIndex].model_name,
                                purchase_price: 0,
                                total_cost: 0,
                                quantity: (orderChange.data().customer_details.item_list[skuIndex].model_quantity_purchased * multiplier),
                            });
                        }
                    }
                }
            }

        });

        let lazadaShopId = lazadaShopList.map(item => item.shop_id);
        const lazadaReportDocsCollection = collection(db, "ecommerce/lazada/order");
        const lazadaOrderQuery = query(lazadaReportDocsCollection,
            where("shop_id", "in", lazadaShopId),
            where("create_time", ">=", startDate),
            where("create_time", "<=", endDate),
            orderBy("create_time", "desc"),
            limit(100));

        const lazadaOrderQuerySnapshot = await getDocs(lazadaOrderQuery);

        lazadaOrderQuerySnapshot.forEach((orderChange) => {
            var shopName = "";
            for (let index = 0; index < shopList.length; index++) {
                if (shopList[index].shop_id === orderChange.data().shop_id) {
                    shopName = shopList[index].name;
                    break;
                }
            };

            
            var trackingNumberString = "";
            var trackingNumberArray = [];
            let payment = 0;
            let order_amount = 0;

            for (let i = 0; i < orderChange.data().item_details.length; i++) {
                order_amount += orderChange.data().item_details[i].paid_price;

                if (trackingNumberArray.includes(orderChange.data().item_details[i].tracking_code) === false) {
                    if (trackingNumberString !== "") {
                        trackingNumberString += ", " + orderChange.data().item_details[i].tracking_code
                    }
                    else {
                        trackingNumberString = orderChange.data().item_details[i].tracking_code;
                    }

                    trackingNumberArray.push(orderChange.data().item_details[i].tracking_code);
                }
            }

            let transaction_fee = 0;
            let commission_fee = 0;
            let service_fee = 0;
            
            if(typeof(orderChange.data().transaction_details) !== "undefined")
            {
                for (let i = 0; i < orderChange.data().transaction_details.length; i++) {
                    switch (orderChange.data().transaction_details[i].fee_name) {
                        case "Free Shipping Max Fee":
                            service_fee -= Number(orderChange.data().transaction_details[i].amount);
                            break;
                        case "Commission":
                            commission_fee -= Number(orderChange.data().transaction_details[i].amount);
                            break;
                        case "Payment Fee":
                            transaction_fee -= Number(orderChange.data().transaction_details[i].amount);
                            break;
                        default:
                            break;
                    }
                }
            }

            currentOrderRows.push({
                id: orderChange.id + "_" + orderChange.data().order_id,
                temp_id: orderChange.id,
                platform: "Lazada",
                shop_id: orderChange.data().shop_id,
                name: shopName,
                remark: orderChange.data().remark,
                order_id: orderChange.data().order_id,
                tracking_number: trackingNumberString,
                create_time: new Date(orderChange.data().created_at.seconds * 1000).valueOf() / 1000,
                order_status: orderChange.data().status,
                status: orderChange.data().status,
                shipping_overcharge: 0,
                shipping_fee: 0,
                commission_fee: commission_fee,
                service_fee: service_fee,
                transaction_fee: transaction_fee,
                payment: payment,
                order_amount: order_amount
            });

            for (let skuIndex = 0; skuIndex < orderChange.data().item_details.length; skuIndex++) {
                // Use Child SKU first
                var sku = orderChange.data().item_details[skuIndex].sku;

                // Get SKU before Space Bar
                // Example: ABCDE(X5),ABCD(X4)
                sku = sku.split(' ')[0];
                sku = String(sku).toUpperCase();

                // Split by Comma
                var final_sku = "";
                for (let k = 0; k < sku.split(',').length; k++) {
                    // Example : ABCDE(X5)
                    final_sku = sku.split(',')[k];

                    // Check Multiplier Special Rules
                    var multiplier = 1;
                    if (final_sku.endsWith(')') && final_sku.includes('(X')) {
                        // Get the string between '(X' and ')'
                        // Example : ABCDE(X5) will return 5
                        var tempMultiplier = final_sku.split('(')[final_sku.split('(').length - 1];
                        if (tempMultiplier.startsWith('X')) {
                            tempMultiplier = tempMultiplier.substring(1, tempMultiplier.length - 1);
                        }

                        // If valid number, get Multiplier
                        // Reset SKU and excludes Multiplier Part
                        if (isFinite(Number(tempMultiplier)) === true) {
                            multiplier = Number(tempMultiplier);
                            final_sku = final_sku.split('(')[final_sku.split('(').length - 2];
                        }
                    }


                    const findIndex = createdInventory.findIndex(obj => obj.sku === final_sku);
                    if (findIndex !== -1) {
                        createdInventory[findIndex].quantity += 1 * multiplier;
                    }
                    else {
                        createdInventory.push({
                            id: orderChange.id + "_" + orderChange.data().order_id + "_" + final_sku,
                            sku: final_sku,
                            item_name: orderChange.data().item_details[skuIndex].name,
                            variation_name: orderChange.data().item_details[skuIndex].variation,
                            purchase_price: 0,
                            total_cost: 0,
                            quantity: (1 * multiplier),
                        });
                    }
                }
            }
        });

        if (shopeeOrderQuerySnapshot.size === 0 && lazadaOrderQuerySnapshot.size === 0)
            alert("There's no Orders in the selected period.");

        setFinalOrderRows(currentOrderRows);
        calculateCosting(createdInventory);
        calculateStatistics(currentOrderRows);
        setRunningReport(false);
    }



    function calculateStatistics(rows) {
        var currentPlatform = "";
        var currentName = "";
        var totalOrderAmount = 0;
        var totalShippingAmount = 0;
        var totalServiceAmount = 0;
        var totalCommissionAmount = 0;
        var totalTransactionAmount = 0;
        var statisticList = [];

        var currentNetProfit = 0;
        for (let i = 0; i < rows.length; i++) {
            // Reset when Store or Platform Changed
            if (currentPlatform !== rows[i].platform || currentName !== rows[i].name) {
                if (currentPlatform !== "" && currentName !== "") {
                    statisticList.push(
                        {
                            platform: currentPlatform,
                            name: currentName,
                            order_amount: totalOrderAmount,
                            shipping_amount: totalShippingAmount,
                            service_amount: totalServiceAmount,
                            commision_amount: totalCommissionAmount,
                            transaction_amount: totalTransactionAmount
                        }
                    )
                }
                totalOrderAmount = 0;
                totalShippingAmount = 0;
                totalServiceAmount = 0;
                totalCommissionAmount = 0;
                totalTransactionAmount = 0;
            }

            currentPlatform = rows[i].platform;
            currentName = rows[i].name;

            totalOrderAmount += Number(rows[i].order_amount);
            totalShippingAmount += Number(rows[i].shipping_fee);
            totalServiceAmount += Number(rows[i].service_fee);
            totalCommissionAmount += Number(rows[i].commission_fee);
            totalTransactionAmount += Number(rows[i].transaction_fee);

            currentNetProfit = currentNetProfit + Number(rows[i].order_amount)
                - Number(rows[i].shipping_fee) - Number(rows[i].service_fee) - Number(rows[i].commission_fee) - Number(rows[i].transaction_fee);
        }

        // Push after last row
        if (rows.length > 0) {
            statisticList.push(
                {
                    platform: currentPlatform,
                    name: currentName,
                    order_amount: totalOrderAmount,
                    shipping_amount: totalShippingAmount,
                    service_amount: totalServiceAmount,
                    commision_amount: totalCommissionAmount,
                    transaction_amount: totalTransactionAmount
                });
        }

        setNetProfit(currentNetProfit);
        setProfitLossStatistics(statisticList);
    }

    useEffect(() => {
        if (runningReport === true) {
            switch (location.pathname) {
                case "/reports/profit_loss":
                    getProfitLossDataRows();
                    break;
                case "/reports/orders":
                    generateOrderReport();
                    break;
                default:
                    break;
            }

        }
    }, [runningReport]);


    function generateReport() {
        setRunningReport(true);
    }

    
    function generateOrderReport() {
        var endDate = convertToNearestDay(orderCreatedDate[0].endDate.getTime() / 1000, 8);
        var startDate = convertToNearestDay(orderCreatedDate[0].startDate.getTime() / 1000, 8);

        switch (platform) {
            case "Shopee":
                const shopeeOrderDocsCollection = collection(db, "ecommerce/shopee/order");

                if (orderSearchString !== "" && orderSearchString.length >= 3) {
                    var orderSearchStringArray = [];
                    for (let i = 0; i < shopeeShopList.length; i++) {
                        orderSearchStringArray.push(shopeeShopList[i].shop_id + "_" + orderSearchString.toUpperCase());
                    }

                    var searchQuery;
                    if (orderSearch === "order_id") {
                        searchQuery = query(shopeeOrderDocsCollection,
                            where("order_id_array", "array-contains-any", orderSearchStringArray),
                            orderBy("create_time", "desc"),
                            limit(10));
                    }
                    else {
                        searchQuery = query(shopeeOrderDocsCollection,
                            where("tracking_number_array", "array-contains-any", orderSearchStringArray),
                            orderBy("create_time", "desc"),
                            limit(10));
                    }
                    getShopeeDataRows(searchQuery, "Search");
                    setPreviousShopeeOrderCreatedDateRange([
                        {
                            startDate: new Date(),
                            endDate: new Date(),
                            key: 'selection'
                        }
                    ]);
                }
                else {
                    // If Date Changed, refresh full Data
                    if (orderCreatedDate !== previousShopeeOrderCreatedDate && shopeeShopList.length > 0) {
                        let shopeeShopId = shopeeShopList.map(item => item.shop_id);

                        setPreviousShopeeOrderCreatedDateRange(orderCreatedDate);
                        var fullQuery = query(shopeeOrderDocsCollection,
                            where("shop_id", "in", shopeeShopId),
                            where("create_time", ">=", startDate),
                            where("create_time", "<=", endDate),
                            orderBy("create_time", "desc"),
                            limit(100));
                        getShopeeDataRows(fullQuery, orderStatus);

                    }
                    else {
                        filterOrderDataRows(fullRows, orderStatus);
                    }
                }
                break;
            case "Lazada":
                const lazadaOrderDocsCollection = collection(db, "ecommerce/lazada/order");
                var lazadaShopListArray = [];
                for (let i = 0; i < shopList.length; i++) {
                    if (shopList[i].platform === "Lazada")
                        lazadaShopListArray.push(shopList[i].shop_id);
                }

                if (orderSearchString !== "" && orderSearchString.length >= 3) {

                    var orderSearchStringArray = [];
                    for (let i = 0; i < lazadaShopListArray.length; i++) {
                        orderSearchStringArray.push(lazadaShopListArray[i] + "_" + orderSearchString.toUpperCase());
                    }

                    var searchQuery;
                    if (orderSearch === "order_id") {
                        searchQuery = query(lazadaOrderDocsCollection,
                            where("order_id_array", "array-contains-any", orderSearchStringArray),
                            orderBy("create_time", "desc"),
                            limit(10));
                    }
                    else {
                        searchQuery = query(lazadaOrderDocsCollection,
                            where("tracking_number_array", "array-contains-any", orderSearchStringArray),
                            orderBy("create_time", "desc"),
                            limit(10));
                    }
                    getLazadaDataRows(searchQuery, "Search");
                    setPreviousLazadaOrderCreatedDateRange([
                        {
                            startDate: new Date(),
                            endDate: new Date(),
                            key: 'selection'
                        }
                    ]);
                }
                else {
                    // If Date Changed, refresh full Data
                    if (orderCreatedDate !== previousLazadaOrderCreatedDate && lazadaShopListArray.length > 0) {
                        let lazadaShopId = lazadaShopList.map(item => item.shop_id);

                        setPreviousLazadaOrderCreatedDateRange(orderCreatedDate);
                        var fullQuery = query(lazadaOrderDocsCollection,
                            where("shop_id", "in", lazadaShopId),
                            where("create_time", ">=", startDate),
                            where("create_time", "<=", endDate),
                            orderBy("create_time", "desc"),
                            limit(100));
                        getLazadaDataRows(fullQuery, orderStatus);

                    }
                    else {
                        filterOrderDataRows(fullRows, orderStatus);
                    }
                }
                break;
            default:
                break;
        }

        setRunningReport(false);
    }

    function filterOrderDataRows(currentRows, filterOrderStatus) {
        var tempRows = [];

        if (currentRows.length === 0)
            currentRows = fullRows;

        for (let index = 0; index < currentRows.length; index++) {
            var trackingNumber = "";
            switch (platform) {
                case "Shopee":
                    if (typeof (currentRows[index].logistic_details) !== "undefined") {
                        trackingNumber = currentRows[index].logistic_details.tracking_number;
                    }
                    break;
                case "Lazada":
                    if (typeof (currentRows[index].tracking_number) !== "undefined") {
                        trackingNumber = currentRows[index].tracking_number;
                    }
                    break;
                default:
                    break;
            }

            if (filterOrderStatus === "Search") {
                if (orderSearch === "order_id"
                    && String(currentRows[index].order_id).includes(orderSearchString) === false) {
                    continue;
                }
                else if (orderSearch === "tracking_number"
                    && trackingNumber.includes(orderSearchString) === false) {
                    continue;
                }
            }
            else if (filterOrderStatus === "" || filterOrderStatus === "All") {

            }
            else if (currentRows[index].status !== filterOrderStatus) {
                continue;
            }
            tempRows.push(currentRows[index]);
        }

        setFinalRows(tempRows);
        setStatistics(tempRows);
    }
    
    function setStatistics(rows) {
        var totalAmount = 0;
        var overchargeAmount = 0;
        var underchargeAmount = 0;
        for (let i = 0; i < rows.length; i++) {
            totalAmount += Number(rows[i].order_amount);

            if (Number(rows[i].shipping_overcharge) > 0)
                underchargeAmount += Math.abs(Number(rows[i].shipping_overcharge));
            else
                overchargeAmount += Math.abs(Number(rows[i].shipping_overcharge));
        }
        setRowCount(rows.length);
        setTotalOrderAmount(totalAmount);
        setShippingOverChargeAmount(overchargeAmount);
        setShippingUnderChargeAmount(underchargeAmount);
    }
    
    function getShopeeDataRows(orderDocsQuery, filterOrderStatus) {
        var currentRows = [];
        onSnapshot(orderDocsQuery, (orderShopSnapshot) => {

            if (orderShopSnapshot.size === 0)
                alert("There's no Orders available.");

            if (orderSearchString !== "" && orderSearchString.length >= 3) {

                orderShopSnapshot.docChanges().forEach((orderChange) => {


                    if (orderChange.type === "added") {
                        var shopName = "";
                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopList[index].name;
                                break;
                            }
                        };


                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            var trackingNumber = "";
                            if (typeof (orderChange.doc.data().logistic_details) !== "undefined") {
                                trackingNumber = orderChange.doc.data().logistic_details.tracking_number;
                            }

                            if (orderSearch === "order_id") {
                                if (orderChange.doc.data().order_id.includes(orderSearchString) === false) {
                                    continue;
                                }
                            }
                            else {
                                if (trackingNumber.includes(orderSearchString) === false) {
                                    continue;
                                }
                            }

                            let payment = 0;
                            let order_amount =
                                orderChange.doc.data().order_details.order_income.buyer_total_amount +
                                orderChange.doc.data().order_details.order_income.voucher_from_shopee +
                                orderChange.doc.data().order_details.order_income.coins -
                                orderChange.doc.data().order_details.order_income.final_product_protection;

                            if (typeof (orderChange.doc.data().transaction_details) !== "undefined") {
                                for (let transactionIndex = 0; transactionIndex < orderChange.doc.data().transaction_details.length; transactionIndex++) {
                                    payment += orderChange.doc.data().transaction_details[transactionIndex].amount;
                                }
                            }

                            currentRows.push({
                                id: orderChange.doc.id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Shopee",
                                shop_id: orderChange.doc.data().shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().remark,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumber,
                                create_time: orderChange.doc.data().customer_details.create_time,
                                order_status: orderChange.doc.data().customer_details.order_status,
                                status: orderChange.doc.data().status,
                                shipping_overcharge: orderChange.doc.data().order_details.order_income.buyer_paid_shipping_fee
                                    - Math.abs(orderChange.doc.data().order_details.order_income.final_shipping_fee),
                                payment: payment,
                                order_amount: order_amount
                            });
                        }
                    }


                    if (orderChange.type === "modified") {
                        // Remove old rows from current data first
                        currentRows = currentRows.filter(id => id.temp_id !== orderChange.doc.id);

                        var shopName = "";
                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopList[index].name;
                                break;
                            }
                        };

                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            //console.log(orderChange.doc.data().order_list[index]);

                            var trackingNumber = "";
                            if (typeof (orderChange.doc.data().logistic_details) !== "undefined") {
                                trackingNumber = orderChange.doc.data().logistic_details.tracking_number;
                            }

                            if (orderSearch === "order_id") {
                                if (orderChange.doc.data().order_id.includes(orderSearchString) === false) {
                                    continue;
                                }
                            }
                            else {
                                if (trackingNumber.includes(orderSearchString) === false) {
                                    continue;
                                }
                            }

                            let payment = 0;
                            let order_amount =
                                orderChange.doc.data().order_details.order_income.buyer_total_amount +
                                orderChange.doc.data().order_details.order_income.voucher_from_shopee +
                                orderChange.doc.data().order_details.order_income.coins -
                                orderChange.doc.data().order_details.order_income.final_product_protection;

                            for (let transactionIndex = 0; transactionIndex < orderChange.doc.data().transaction_details.length; transactionIndex++) {
                                payment += orderChange.doc.data().transaction_details[transactionIndex].amount;
                            }

                            let shipping_overcharge = 0;
                            if(orderChange.doc.data().order_details.order_income.final_shipping_fee !== 0){
                                shipping_overcharge = orderChange.doc.data().order_details.order_income.buyer_paid_shipping_fee
                                - Math.abs(orderChange.doc.data().order_details.order_income.final_shipping_fee);
                            }

                            currentRows.push({
                                id: orderChange.doc.id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Shopee",
                                shop_id: orderChange.doc.data().shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().remark,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumber,
                                create_time: orderChange.doc.data().customer_details.create_time,
                                order_status: orderChange.doc.data().customer_details.order_status,
                                status: orderChange.doc.data().status,
                                shipping_overcharge: shipping_overcharge,
                                payment: payment,
                                order_amount: order_amount
                            });
                        }
                    }
                });
            }
            else {
                orderShopSnapshot.docChanges().forEach((orderChange) => {
                    if (orderChange.type === "added") {
                        var shopName = "";
                        for (let index = 0; index < shopeeShopList.length; index++) {
                            if (typeof(orderChange.doc.data().order_list[0]) !== "undefined" && 
                                shopeeShopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopeeShopList[index].name;
                                break;
                            }
                        };

                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            var trackingNumber = "";
                            if (typeof (orderChange.doc.data().logistic_details) !== "undefined") {
                                trackingNumber = orderChange.doc.data().logistic_details.tracking_number;
                            }

                            let payment = 0;
                            let order_amount =
                                orderChange.doc.data().order_details.order_income.buyer_total_amount +
                                orderChange.doc.data().order_details.order_income.voucher_from_shopee +
                                orderChange.doc.data().order_details.order_income.coins -
                                orderChange.doc.data().order_details.order_income.final_product_protection;

                            if (typeof (orderChange.doc.data().transaction_details) !== "undefined") {
                                for (let transactionIndex = 0; transactionIndex < orderChange.doc.data().transaction_details.length; transactionIndex++) {
                                    payment += orderChange.doc.data().transaction_details[transactionIndex].amount;
                                }
                            }
                            
                            let shipping_overcharge = 0;
                            if(orderChange.doc.data().order_details.order_income.final_shipping_fee !== 0){
                                shipping_overcharge = orderChange.doc.data().order_details.order_income.buyer_paid_shipping_fee
                                - Math.abs(orderChange.doc.data().order_details.order_income.final_shipping_fee);
                            }

                            currentRows.push({
                                id: orderChange.doc.id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Shopee",
                                shop_id: orderChange.doc.data().shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().remark,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumber,
                                create_time: orderChange.doc.data().customer_details.create_time,
                                order_status: orderChange.doc.data().customer_details.order_status,
                                status: orderChange.doc.data().status,
                                shipping_overcharge: shipping_overcharge,
                                payment: payment,
                                order_amount: order_amount
                            });
                        }
                    }


                    if (orderChange.type === "modified") {
                        // Remove old rows from current data first
                        currentRows = currentRows.filter(id => id.temp_id !== orderChange.doc.id);

                        var shopName = "";
                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopList[index].name;
                                break;
                            }
                        };

                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            //console.log(orderChange.doc.data().order_list[index]);

                            var trackingNumber = "";
                            if (typeof (orderChange.doc.data().logistic_details) !== "undefined") {
                                trackingNumber = orderChange.doc.data().logistic_details.tracking_number;
                            }

                            let payment = 0;
                            let order_amount =
                                orderChange.doc.data().order_details.order_income.buyer_total_amount +
                                orderChange.doc.data().order_details.order_income.voucher_from_shopee +
                                orderChange.doc.data().order_details.order_income.coins -
                                orderChange.doc.data().order_details.order_income.final_product_protection;

                            for (let transactionIndex = 0; transactionIndex < orderChange.doc.data().transaction_details.length; transactionIndex++) {
                                payment += orderChange.doc.data().transaction_details[transactionIndex].amount;
                            }
                            
                            let shipping_overcharge = 0;
                            if(orderChange.doc.data().order_details.order_income.final_shipping_fee !== 0){
                                shipping_overcharge = orderChange.doc.data().order_details.order_income.buyer_paid_shipping_fee
                                - Math.abs(orderChange.doc.data().order_details.order_income.final_shipping_fee);
                            }

                            currentRows.push({
                                id: orderChange.doc.id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Shopee",
                                shop_id: orderChange.doc.data().shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().remark,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumber,
                                create_time: orderChange.doc.data().customer_details.create_time,
                                order_status: orderChange.doc.data().customer_details.order_status,
                                status: orderChange.doc.data().status,
                                shipping_overcharge: shipping_overcharge,
                                payment: payment,
                                order_amount: order_amount
                            });
                        }
                    }
                });
            }

            setFullRows(currentRows);
            filterOrderDataRows(currentRows, filterOrderStatus);
        }
        );

    }

    
    function getLazadaDataRows(orderDocsQuery, filterOrderStatus) {
        var currentRows = [];
        onSnapshot(orderDocsQuery, (orderShopSnapshot) => {

            if (orderShopSnapshot.size === 0)
                alert("There's no Orders available.");

            if (orderSearchString !== "" && orderSearchString.length >= 3) {

                orderShopSnapshot.docChanges().forEach((orderChange) => {
                    if (orderChange.type === "added") {
                        var shopName = "";
                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopList[index].name;
                                break;
                            }
                        };

                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            var trackingNumberString = "";
                            var trackingNumberArray = [];
                            let payment = 0;
                            let order_amount = 0;

                            for (let i = 0; i < orderChange.doc.data().item_details.length; i++) {
                                order_amount += orderChange.doc.data().item_details[i].paid_price;

                                if (trackingNumberArray.includes(orderChange.doc.data().item_details[i].tracking_code) === false) {
                                    if (trackingNumberString !== "") {
                                        trackingNumberString += ", " + orderChange.doc.data().item_details[i].tracking_code
                                    }
                                    else {
                                        trackingNumberString = orderChange.doc.data().item_details[i].tracking_code;
                                    }

                                    trackingNumberArray.push(orderChange.doc.data().item_details[i].tracking_code);
                                }
                            }

                            
                            if (orderSearch === "order_id") {
                                if (String(orderChange.doc.data().order_id).includes(orderSearchString) === false) {
                                    continue;
                                }
                            }
                            else {
                                var anyTrackingNumberMatch = false;
                                for(let i = 0; i < trackingNumberArray.length; i++){
                                    if(trackingNumberArray[i].includes(orderSearchString) === true)
                                        anyTrackingNumberMatch = true;
                                }
                                if (anyTrackingNumberMatch === false) {
                                    continue;
                                }
                            }

                            if (typeof (orderChange.doc.data().transaction_details) !== "undefined") {
                                let transactionFeeNameArray = [];
                                for (let transactionIndex = 0; transactionIndex < orderChange.doc.data().transaction_details.length; transactionIndex++) {

                                    if(transactionFeeNameArray.includes(orderChange.doc.data().transaction_details[transactionIndex].transaction_number + orderChange.doc.data().transaction_details[transactionIndex].fee_name) === false)
                                    {
                                        payment += Number(orderChange.doc.data().transaction_details[transactionIndex].amount);
                                        transactionFeeNameArray.push(orderChange.doc.data().transaction_details[transactionIndex].transaction_number + orderChange.doc.data().transaction_details[transactionIndex].fee_name);
                                    }
                                }
                            }

                            currentRows.push({
                                id: orderChange.doc.id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Lazada",
                                shop_id: orderChange.doc.data().shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().remark,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumberString,
                                create_time: new Date(orderChange.doc.data().created_at).valueOf(),
                                order_status: orderChange.doc.data().statuses[0],
                                status: orderChange.doc.data().status,
                                shipping_overcharge: 0,
                                payment: payment,
                                order_amount: order_amount
                            });
                        }
                    }


                    if (orderChange.type === "modified") {
                        // Remove old rows from current data first
                        currentRows = currentRows.filter(id => id.temp_id !== orderChange.doc.id);

                        var shopName = "";
                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopList[index].name;
                                break;
                            }
                        };

                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            //console.log(orderChange.doc.data().order_list[index]);

                            var trackingNumberString = "";
                            var trackingNumberArray = [];
                            let payment = 0;
                            let order_amount = 0;

                            for (let i = 0; i < orderChange.doc.data().item_details.length; i++) {
                                order_amount += orderChange.doc.data().item_details[i].paid_price;

                                if (trackingNumberArray.includes(orderChange.doc.data().item_details[i].tracking_code) === false) {
                                    if (trackingNumberString !== "") {
                                        trackingNumberString += ", " + orderChange.doc.data().item_details[i].tracking_code
                                    }
                                    else {
                                        trackingNumberString = orderChange.doc.data().item_details[i].tracking_code;
                                    }

                                    trackingNumberArray.push(orderChange.doc.data().item_details[i].tracking_code);
                                }
                            }

                            
                            if (orderSearch === "order_id") {
                                if (String(orderChange.doc.data().order_id).includes(orderSearchString) === false) {
                                    continue;
                                }
                            }
                            else {
                                var anyTrackingNumberMatch = false;
                                for(let i = 0; i < trackingNumberArray.length; i++){
                                    if(trackingNumberArray[i].includes(orderSearchString) === true)
                                        anyTrackingNumberMatch = true;
                                }
                                if (anyTrackingNumberMatch === false) {
                                    continue;
                                }
                            }

                            for (let transactionIndex = 0; transactionIndex < orderChange.doc.data().transaction_details.length; transactionIndex++) {
                                payment += orderChange.doc.data().transaction_details[transactionIndex].amount;
                            }

                            currentRows.push({
                                id: orderChange.doc.id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Lazada",
                                shop_id: orderChange.doc.data().shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().remark,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumberString,
                                create_time: new Date(orderChange.doc.data().created_at).valueOf(),
                                order_status: orderChange.doc.data().statuses[0],
                                status: orderChange.doc.data().status,
                                shipping_overcharge: 0,
                                payment: payment,
                                order_amount: order_amount
                            });
                        }
                    }
                });
            }
            else {
                orderShopSnapshot.docChanges().forEach((orderChange) => {
                    if (orderChange.type === "added") {

                        var shopName = "";
                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopList[index].name;
                                break;
                            }
                        };

                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            var trackingNumberString = "";
                            var trackingNumberArray = [];
                            let payment = 0;
                            let order_amount = 0;

                            for (let i = 0; i < orderChange.doc.data().item_details.length; i++) {
                                order_amount += orderChange.doc.data().item_details[i].paid_price;

                                if (trackingNumberArray.includes(orderChange.doc.data().item_details[i].tracking_code) === false) {
                                    if (trackingNumberString !== "") {
                                        trackingNumberString += ", " + orderChange.doc.data().item_details[i].tracking_code
                                    }
                                    else {
                                        trackingNumberString = orderChange.doc.data().item_details[i].tracking_code;
                                    }

                                    trackingNumberArray.push(orderChange.doc.data().item_details[i].tracking_code);
                                }
                            }
                            
                            if (typeof (orderChange.doc.data().transaction_details) !== "undefined") {
                                let transactionFeeNameArray = [];
                                for (let transactionIndex = 0; transactionIndex < orderChange.doc.data().transaction_details.length; transactionIndex++) {

                                    if(transactionFeeNameArray.includes(orderChange.doc.data().transaction_details[transactionIndex].transaction_number + orderChange.doc.data().transaction_details[transactionIndex].fee_name) === false)
                                    {
                                        payment += Number(orderChange.doc.data().transaction_details[transactionIndex].amount);
                                        transactionFeeNameArray.push(orderChange.doc.data().transaction_details[transactionIndex].transaction_number + orderChange.doc.data().transaction_details[transactionIndex].fee_name);
                                    }
                                }
                            }

                            currentRows.push({
                                id: orderChange.doc.id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Lazada",
                                shop_id: orderChange.doc.data().shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().remark,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumberString,
                                create_time: new Date(orderChange.doc.data().created_at).valueOf(),
                                order_status: orderChange.doc.data().statuses[0],
                                status: orderChange.doc.data().status,
                                shipping_overcharge: 0,
                                payment: payment,
                                order_amount: order_amount
                            });
                        }
                    }


                    if (orderChange.type === "modified") {
                        // Remove old rows from current data first
                        currentRows = currentRows.filter(id => id.temp_id !== orderChange.doc.id);

                        var shopName = "";
                        for (let index = 0; index < shopList.length; index++) {
                            if (shopList[index].shop_id === orderChange.doc.data().order_list[0].shop_id) {
                                shopName = shopList[index].name;
                                break;
                            }
                        };

                        for (let index = 0; index < orderChange.doc.data().order_list.length; index++) {
                            //console.log(orderChange.doc.data().order_list[index]);

                            var trackingNumber = "";
                            if (typeof (orderChange.doc.data().logistic_details) !== "undefined") {
                                trackingNumber = orderChange.doc.data().logistic_details.tracking_number;
                            }

                            let payment = 0;
                            let order_amount = 0;

                            for (let i = 0; i < orderChange.doc.data().item_details.length; i++) {
                                order_amount += orderChange.doc.data().item_details[i].paid_price;
                            }

                            for (let transactionIndex = 0; transactionIndex < orderChange.doc.data().transaction_details.length; transactionIndex++) {
                                payment += orderChange.doc.data().transaction_details[transactionIndex].amount;
                            }

                            currentRows.push({
                                id: orderChange.doc.id + "_" + orderChange.doc.data().order_id,
                                temp_id: orderChange.doc.id,
                                platform: "Lazada",
                                shop_id: orderChange.doc.data().shop_id,
                                name: shopName,
                                remark: orderChange.doc.data().remark,
                                order_id: orderChange.doc.data().order_id,
                                tracking_number: trackingNumberString,
                                create_time: new Date(orderChange.doc.data().created_at).valueOf(),
                                order_status: orderChange.doc.data().statuses[0],
                                status: orderChange.doc.data().status,
                                shipping_overcharge: 0,
                                payment: payment,
                                order_amount: order_amount
                            });
                        }
                    }
                });
            }

            setFullRows(currentRows);
            filterOrderDataRows(currentRows, filterOrderStatus);
        }
        );

    }
    

    useEffect(() => {

        setOrderCreatedDateRange(orderCreatedDate);
        filterOrderDataRows(fullRows, orderStatus);
    }, [shopList, fullRows, orderStatus, orderCreatedDate, orderSearch, orderSearchString]);

    useEffect(() => {
        calculateCosting();
    }, [itemTable]);

    function calculateCosting(reportSkuTable) {

        var currentCost = 0;
        if (typeof (reportSkuTable) === "undefined") {
            var finalDataRows = Object.assign([], finalSkuRows);
            for (let i = 0; i < finalDataRows.length; i++) {
                for (let j = 0; j < itemTable.length; j++) {
                    if (finalDataRows[i].sku === itemTable[j].sku) {
                        finalDataRows[i].id = itemTable[j].id;

                        finalDataRows[i].purchase_price = itemTable[j].purchase_price;
                        finalDataRows[i].total_cost = Number((Math.round(
                            (Number(finalDataRows[i].purchase_price) * Number(finalDataRows[i].quantity))
                            + "e+2") + "e-2"));

                        currentCost = currentCost + Number((Math.round(
                            (Number(finalDataRows[i].purchase_price) * Number(finalDataRows[i].quantity))
                            + "e+2") + "e-2"));
                        break;
                    }
                }
            }

            if (finalDataRows.length > 0)
                setFinalSkuRows(finalDataRows);
        }
        else {
            for (let i = 0; i < reportSkuTable.length; i++) {
                for (let j = 0; j < itemTable.length; j++) {
                    if (reportSkuTable[i].sku === itemTable[j].sku) {
                        reportSkuTable[i].id = itemTable[j].id;

                        reportSkuTable[i].purchase_price = itemTable[j].purchase_price;
                        reportSkuTable[i].total_cost = Number((Math.round(
                            (Number(reportSkuTable[i].purchase_price) * Number(reportSkuTable[i].quantity))
                            + "e+2") + "e-2"));

                        currentCost = currentCost + Number((Math.round(
                            (Number(reportSkuTable[i].purchase_price) * Number(reportSkuTable[i].quantity))
                            + "e+2") + "e-2"));
                        break;
                    }
                }
            }

            if (reportSkuTable.length > 0)
                setFinalSkuRows(reportSkuTable);
        }


        setTotalCogs(currentCost);
    }


    useEffect(() => {

        function calculateMinimumDate() {
            var minimumDate = new Date().valueOf();
            for (let i = 0; i < shopList.length; i++) {
                if (minimumDate >= shopList[i].data_started_at.seconds)
                    minimumDate = shopList[i].data_started_at.seconds;
            }
            setMinimumDate(new Date(Number(minimumDate) * 1000));
        }
        calculateMinimumDate();

        // Initialize one Listener only

        function getItemTable() {
            const inventoryDocsCollection = collection(db, "inventory");
            var inventoryQuery = query(inventoryDocsCollection,
                where("uid", "==", user.uid));

            onSnapshot(inventoryQuery, (snapshot) => {
                var finalDataRows = [];

                finalDataRows = Object.assign([], itemTable);

                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const fullInventoryData = change.doc.data().inventory;
                        Object.keys(fullInventoryData).map((key, index) => {
                            finalDataRows.push({
                                id: change.doc.id + "_" + key + "_" + fullInventoryData[key].sku,
                                temp_id: change.doc.id,
                                image: fullInventoryData[key].image,
                                sku: fullInventoryData[key].sku,
                                item_name: fullInventoryData[key].item_name,
                                variation_name: fullInventoryData[key].variation_name,
                                quantity: fullInventoryData[key].quantity,
                                sale_price: fullInventoryData[key].sale_price,
                                purchase_price: fullInventoryData[key].purchase_price,
                                created_at: fullInventoryData[key].created_at,
                            });

                            return true;
                        });
                    }

                    if (change.type === "modified") {
                        finalDataRows = finalDataRows.filter(item => item.temp_id !== change.doc.id);
                        const fullInventoryData = change.doc.data().inventory;
                        Object.keys(fullInventoryData).map((key, index) => {
                            finalDataRows.push({
                                id: change.doc.id + "_" + key + "_" + fullInventoryData[key].sku,
                                temp_id: change.doc.id,
                                image: fullInventoryData[key].image,
                                sku: fullInventoryData[key].sku,
                                item_name: fullInventoryData[key].item_name,
                                variation_name: fullInventoryData[key].variation_name,
                                quantity: fullInventoryData[key].quantity,
                                sale_price: fullInventoryData[key].sale_price,
                                purchase_price: fullInventoryData[key].purchase_price,
                                created_at: fullInventoryData[key].created_at,
                            });

                            return true;
                        });
                    }

                    if (change.type === "removed") {
                        finalDataRows = finalDataRows.filter(item => item.temp_id !== change.doc.id);
                    }
                }
                );

                setItemTable(finalDataRows);
            });
        }

        getItemTable();


    }, [shopList, user]);

    switch (location.pathname) {
        case "/reports/profit_loss":
            return (
                <div className="report">
                    <Sidebar />
                    <div className="reportContainer">
                        <Navbar userMetadata={userMetadata} />
                        <div className="topButtons">
                            <Link to="/reports/profit_loss">
                                <button className="active">Profit Loss</button>
                            </Link>
                            <Link to="/reports/orders">
                                <button>Orders</button>
                            </Link>
                        </div>
                        <div className="top">
                            <div className="reportTitle">
                                <p>Reports - Profit Loss</p>
                            </div>
                            <div className="reportSubTitle">
                            </div>

                            <div className="main">

                                <div className="filterContainer">
                                    <h1>Filter</h1>
                                    <div className="dateFilter">
                                        <p>Report Date : </p>
                                        <div className="calendarWrap">

                                            <input
                                                value={`${format(reportCreatedDate[0].startDate, "MM/dd/yyyy")} to ${format(reportCreatedDate[0].endDate, "MM/dd/yyyy")}`}
                                                readOnly
                                                className="inputBox"
                                                onClick={() => { setOpenPopup(true); }}
                                            />

                                            <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}
                                            >
                                                <DateRangePicker
                                                    minDate={minimumDate}
                                                    onChange={item => {
                                                        if (item.selection.startDate < minimumDate)
                                                            item.selection.startDate = minimumDate;

                                                        if (item.selection.endDate < minimumDate)
                                                            item.selection.endDate = minimumDate;

                                                        setReportCreatedDateRange([item.selection]);
                                                    }}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={reportCreatedDate}
                                                    direction="horizontal"
                                                    className="calendarElement"
                                                    style={{ width: "300px" }}
                                                /><div className="bottom" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                                                    <div
                                                        onMouseEnter={handleMouseEnterConnectButton}
                                                        onMouseLeave={handleMouseLeaveConnectButton}
                                                        style={{
                                                            transition: "all 0.2s ease", padding: "10px", width: "300px", textAlign: "center", cursor: "pointer", WebkitBoxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", breportRadius: "10px",
                                                            backgroundColor: isHoveringConnectButton ? "#77b2ff" : "#3d91ff", color: "white"
                                                        }} onClick={() => { setOpenPopup(false); }} >
                                                        Close
                                                    </div>
                                                </div>
                                            </Popup>

                                        </div>
                                    </div>
                                    <button className="generateButton" onClick={() => generateReport()}>Generate</button>
                                </div>
                                <div className="statisticsContainer">
                                    <h1>Statistics</h1>
                                    <div className="numberOfOrders">
                                        <p>Number of Orders : </p>
                                        <p>{finalOrderRows.length}</p>
                                    </div>
                                    <div className="numberOfOrders">
                                        <p>Total Revenue : </p>
                                        <p>{"RM " + netProfit.toFixed(2)}</p>
                                    </div>
                                    <div className="numberOfOrders">
                                        <p>Net Profit : </p>
                                        <p>{"RM " + (netProfit - totalCogs).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="profitContainer">
                            <h3 style={{ marginBottom: "20px" }}>{"Estimated Profit Loss"}</h3>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }}></hr>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }}></hr>
                            <div className="individualContainer">
                                {
                                    typeof(profitLossStatistics.length) !== "undefined" ?
                                    Array.from(profitLossStatistics).map((item, i, row) => {
                                        return (
                                            <div key={item.platform + "_" + item.name}>
                                                <h4 style={{ marginBottom: "10px" }}>{item.platform + " | " + item.name}</h4>
                                                <div className="detailsContainer">
                                                    <div className="subtitle">Order Amount</div>
                                                    <div className="details">{"RM " + item.order_amount.toFixed(2)}</div>
                                                </div>
                                                {
                                                    item.shipping_amount !== 0 ?
                                                        <div className="detailsContainer">
                                                            <div className="subtitle">Shipping Fee</div>
                                                            <div className="details">{"RM (" + item.shipping_amount.toFixed(2) + ")"}</div>
                                                        </div>
                                                        : <p></p>
                                                }
                                                <div className="detailsContainer">
                                                    <div className="subtitle">Service Fee</div>
                                                    <div className="details">{"RM (" + item.service_amount.toFixed(2) + ")"}</div>
                                                </div>
                                                <div className="detailsContainer">
                                                    <div className="subtitle">Commission Fee</div>
                                                    <div className="details">{"RM (" + item.commision_amount.toFixed(2) + ")"}</div>
                                                </div>
                                                <div className="detailsContainer">
                                                    <div className="subtitle">Transaction Fee</div>
                                                    <div className="details">{"RM (" + item.transaction_amount.toFixed(2) + ")"}</div>
                                                </div>
                                                <div className="detailsContainer" style={{ fontWeight: "bold", backgroundColor: "rgba(128,128,128,0.3)", marginTop: "10px" }}>
                                                    <div className="subtitle">Subtotal Revenue</div>
                                                    <div className="details">{"RM " +
                                                        (item.order_amount - item.shipping_amount - item.service_amount - item.commision_amount - item.transaction_amount).toFixed(2)}</div>
                                                </div>

                                                {(row.length - 1 !== i) ?
                                                    <hr style={{ marginTop: "10px", marginBottom: "10px" }}></hr>
                                                    : <p></p>
                                                }
                                            </div>
                                        )
                                    })
                                    :
                                    <p></p>
                                }

                            </div>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }}></hr>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }}></hr>
                            <div className="detailsContainer">
                                <div className="subtitle">Total Revenue</div>
                                <div className="details">{"RM " + (netProfit).toFixed(2)}</div>
                            </div>
                            <div className="detailsContainer">
                                <div className="subtitle">Total Cost of Goods Sold</div>
                                <div className="details">{"RM (" + (totalCogs).toFixed(2) + ")"}</div>
                            </div>
                            <div className="detailsContainer" style={{ fontWeight: "bold", backgroundColor: "rgba(128,128,128,0.3)", marginTop: "10px" }}>
                                <div className="subtitle">Net Profit</div>
                                <div className="details">{"RM " + (netProfit - totalCogs).toFixed(2)}</div>
                            </div>
                        </div>
                        <Datatable type={"reports_orders"} overrideDataRow={finalOrderRows} />
                        <div style={{ height: "20px" }}></div>
                        <Datatable type={"reports_sku"} overrideDataRow={finalSkuRows} />

                        <Popup openPopup={runningReport} setOpenPopup={setRunningReport}>
                            <ChakraProvider>
                                <Center h="50vh" w="50vh" >

                                    <div>
                                        <div style={{ marginLeft: "30px" }}>
                                            <Spinner />
                                        </div>
                                        <div>
                                            <p>{processingText}</p>
                                        </div>

                                    </div>
                                </Center>
                            </ChakraProvider>
                        </Popup>
                    </div>
                </div>
            )
        case "/reports/orders":
            return (
                <div className="order">
                    <Sidebar />
                    <div className="orderContainer">
                        <Navbar userMetadata={userMetadata} />
                        <div className="topButtons">
                            <Link to="/reports/profit_loss">
                                <button>Profit Loss</button>
                            </Link>
                            <Link to="/reports/orders">
                                <button className="active">Orders</button>
                            </Link>
                        </div>
                        <div className="top">
                            <div className="orderTitle">
                                <p>Reports - Orders</p>
                            </div>
                            <div className="orderSubTitle">
                            </div>

                            <div className="main">

                                <div className="filterContainer">
                                    <h1>Filter</h1>
                                    <div className="dateFilter">
                                        <p>Order Creation Date : </p>
                                        <div className="calendarWrap">

                                            <input
                                                value={`${format(orderCreatedDate[0].startDate, "MM/dd/yyyy")} to ${format(orderCreatedDate[0].endDate, "MM/dd/yyyy")}`}
                                                readOnly
                                                className="inputBox"
                                                onClick={() => { setOpenPopup(true); }}
                                            />

                                            <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}
                                            >
                                                <DateRangePicker
                                                    minDate={minimumDate}
                                                    onChange={item => {
                                                        if (item.selection.startDate < minimumDate)
                                                            item.selection.startDate = minimumDate;

                                                        if (item.selection.endDate < minimumDate)
                                                            item.selection.endDate = minimumDate;

                                                        setOrderCreatedDateRange([item.selection]);
                                                    }}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={orderCreatedDate}
                                                    direction="horizontal"
                                                    className="calendarElement"
                                                    style={{ width: "300px" }}
                                                /><div className="bottom" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                                                    <div
                                                        onMouseEnter={handleMouseEnterConnectButton}
                                                        onMouseLeave={handleMouseLeaveConnectButton}
                                                        style={{
                                                            transition: "all 0.2s ease", padding: "10px", width: "300px", textAlign: "center", cursor: "pointer", WebkitBoxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px",
                                                            backgroundColor: isHoveringConnectButton ? "#77b2ff" : "#3d91ff", color: "white"
                                                        }} onClick={() => { setOpenPopup(false); }} >
                                                        Close
                                                    </div>
                                                </div>
                                            </Popup>

                                        </div>
                                    </div>

                                    <div className="statusFilter">
                                        <p>Platform : </p>
                                        <div >
                                            <FormControl sx={{ m: 1, minWidth: 150 }} className="comboBoxWrap"  >
                                                <Select
                                                    className="selectComboBoxWrap"
                                                    labelId="platform-combobox-label"
                                                    id="platform-combobox"
                                                    value={platform}
                                                    style={{ height: "40px" }}
                                                    onChange={handlePlatformChange}
                                                >
                                                    <MenuItem className="menuItem" value="Shopee">Shopee</MenuItem>
                                                    <MenuItem className="menuItem" value="Lazada">Lazada</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="statusFilter">
                                        <p>Order Status : </p>
                                        <div >
                                            <FormControl sx={{ m: 1, minWidth: 150 }} className="comboBoxWrap"  >
                                                <Select
                                                    className="selectComboBoxWrap"
                                                    labelId="order-status-combobox-label"
                                                    id="order-status-combobox"
                                                    value={orderStatus}
                                                    style={{ height: "40px" }}
                                                    onChange={handleOrderStatusChange}
                                                >
                                                    <MenuItem className="menuItem" value="All">All</MenuItem>
                                                    <MenuItem className="menuItem" value="Shipping Overcharged">Overcharged</MenuItem>
                                                    <MenuItem className="menuItem" value="Shipping Undercharged">Undercharged</MenuItem>
                                                    <MenuItem className="menuItem" value="Cancelled">Cancel</MenuItem>
                                                    <MenuItem className="menuItem" value="Return">Return</MenuItem>
                                                    <MenuItem className="menuItem" value="Unpaid">Unpaid</MenuItem>
                                                    <MenuItem className="menuItem" value="Adjustment">Adjustment</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="orderFilter">
                                        <div>
                                            <FormControl sx={{ m: 1, minWidth: 150 }} className="comboBoxWrap"  >
                                                <Select
                                                    className="selectComboBoxWrap"
                                                    labelId="order-status-combobox-label"
                                                    id="order-status-combobox"
                                                    value={orderSearch}
                                                    style={{ height: "40px" }}
                                                    onChange={handleOrderSearchChange}
                                                >
                                                    <MenuItem className="menuItem" value="order_id">Order ID</MenuItem>
                                                    <MenuItem className="menuItem" value="tracking_number">Tracking Number</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <input
                                                value={orderSearchString}
                                                onChange={orderSearchStringOnChange}
                                                className="inputBox"
                                            />
                                        </div>
                                    </div>

                                    <button className="generateButton" onClick={() => setRunningReport(true)}>Generate</button>
                                </div>
                                <div className="statisticsContainer">
                                    <h1>Statistics</h1>
                                    <div className="numberOfOrders">
                                        <p>Number of Filtered Orders : </p>
                                        <p>{rowCount}</p>
                                    </div>
                                    <div className="numberOfOrders">
                                        <p>Total Order Amount : </p>
                                        <p>RM {totalOrderAmount.toFixed(2)}</p>
                                    </div>
                                    <div className="numberOfOrders">
                                        <p>Total Shipping Overcharged : </p>
                                        <p>RM {totalShippingOverchargeAmount.toFixed(2)}</p>
                                    </div>
                                    <div className="numberOfOrders">
                                        <p>Total Shipping Undercharged : </p>
                                        <p>RM {totalShippingUnderchargeAmount.toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Datatable type={"orders"} overrideDataRow={finalRows} />


                        <Popup openPopup={runningReport} setOpenPopup={setRunningReport}>
                            <ChakraProvider>
                                <Center h="50vh" w="50vh" >

                                    <div>
                                        <div style={{ marginLeft: "30px" }}>
                                            <Spinner />
                                        </div>
                                        <div>
                                            <p>{processingText}</p>
                                        </div>

                                    </div>
                                </Center>
                            </ChakraProvider>
                        </Popup>
                    </div>
                </div>
            )
        default:
            return (<p></p>)
    }

}

export default Report;
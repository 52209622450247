import "../../Blogs.scss";
import HomeNavbar from "../../../../../components/homeNavbar/HomeNavbar";
import { AccessTime, RateReview, Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, doc, documentId, increment, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "../../../../../firebaseconfig";
import { useAuthState } from "react-firebase-hooks/auth";
import BlogBottomLikes from "../../../blog/BlogBottomLikes";
import { Helmet } from "react-helmet-async";


const ShopeeOrderIncome = () => {

    const [user, loading, error] = useAuthState(auth);
    const blogDoc = doc(db, "count", "blogs_st_shopee_order_income");
    const [documentDetails, setDocumentDetails] = useState({});
    useEffect(() => {

        async function increaseView() {
            await setDoc(blogDoc, {
                view_count: increment(1)
            }, { merge: true });
        }

        increaseView();

        async function getDocumentDetails() {
            const docCollection = collection(db, "count");
            const docQuery = query(docCollection,
                where(documentId(), "==", "blogs_st_shopee_order_income"));

            onSnapshot(docQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {

                    setDocumentDetails(change.doc.data());
                })
            });
        }

        getDocumentDetails();
    }, []);



    return (
        <div className="homeContainer">
        <Helmet>
            <title>TactiBots | Shopee Order Income</title>
            <meta property="description" content="Shopee Order Income calculation can be tricky. Let's go through an example today!" />
            <meta property="og:description" content="Shopee Order Income calculation can be tricky. Let's go through an example today!" />
            <meta property="og:url" content="https://tactibots.com/blogs/seller_tips/shopee_order_income"></meta>
            <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshopee_order_income%2Fthumbnail.PNG?alt=media&token=7442caff-9dc2-4774-a607-a425638f534d"></meta>
        </Helmet>
            <HomeNavbar active={"blogContainer"} />
            <div className="singleBlogContainer" name="blogContainer">
                <div className="info">
                    <div className="time">
                        <AccessTime></AccessTime>
                        2022 November 29, 1:52pm
                    </div>
                    <div className="author">
                        <RateReview></RateReview>
                        TactiBots
                    </div>
                    <div className="time">
                        <Visibility></Visibility>
                        {
                            typeof (documentDetails) !== "undefined" ? documentDetails.view_count : 0
                        } views
                    </div>
                </div>
                <h1 className="title">Shopee Order Income</h1>
                <img className="thumbnail"
                    src="https://firebasestorage.googleapis.com/v0/b/tactibots-1605018905444.appspot.com/o/blog%2Fseller_tips%2Fshopee_order_income%2Fthumbnail.PNG?alt=media&token=7442caff-9dc2-4774-a607-a425638f534d"
                    alt=""
                />
                <div className="content">
                    <h3>How to Calculate Order Income?</h3>
                    <p>The formula is : <b>Original Price of Merchandise - Shopee's Fee - Seller's Voucher</b>.
                        <br></br>Buyer Payment is irrelevant to Order Income. </p>
                    <p>Let's go through an example today!</p>
                    <h3>Example for Shopee Order:</h3>
                    <img
                        src={require('../../../../../pages/general/blogs/sellerTips/shopee/shopee_order.PNG')}
                        style={{ width: "70%" }}
                        alt=""
                    />
                    <p>Note that the <b>Merchandise Subtotal RM 44.00</b> is NOT always equivalent to Original Price or Merchandise.
                        <br></br>For this Order, Original Price of Merchandise is </p>
                    <ul>
                        <li>RM 44.00 Merchandise Subtotal</li>
                        <li>RM 1.80 Product discount Rebate (only exists after joining campaign)</li>
                    </ul>
                    <p>which is <b>RM 45.80</b> in total.</p>

                    <p>Note that the <b>Fee & Charges Subtotal -RM 3.82</b> is NOT always equivalent to Shopee's Fee.
                        <br></br>For this order, Shopee's Fee is</p>
                    <ul>
                        <li>-RM 0.93 Commission Fee</li>
                        <li>-RM 2.32 Service Fee</li>
                        <li>-RM 0.57 Transaaction Fee</li>
                        <li><b>-RM 6.30 Actual Shipping Fee</b></li>
                        <li><b>+RM 7.00 Shipping Fee Paid by Buyer</b></li>
                    </ul>
                    <p>which is <b>-RM 3.12</b> in total.</p>
                    <p>Wonder why Actual Shipping Fee is different from Shipping Fee Paid by Buyer?
                        <br></br> Click
                        <Link to="/blogs/seller_tips/shipping_overcharge" style={{ whiteSpace: "nowrap", marginLeft: "5px", marginRight: "5px" }}>
                            here
                        </Link> to learn more about <b>Shipping Overcharge / Undercharge</b>.
                    </p>
                    <br></br>
                    <p><b>Original Price of Merchandise - Shopee's Fee - Seller's Voucher</b></p>
                    <p>= (RM 45.80) - RM 3.12 - RM 2.00 = <b>RM 40.68</b></p>

                    <p>This is how we get the <b>Order Income RM 40.68</b>.
                        <br></br>Buyer Payment of RM 26.83 is <b>IRRELEVANT</b> to Seller.</p>
                </div>

                <BlogBottomLikes document_id={"blogs_st_shopee_order_income"} document_details={documentDetails} user={user} />
            </div>
        </div>
    )
}

export default ShopeeOrderIncome;
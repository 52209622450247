import "./Questions.scss";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../../firebaseconfig";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useState } from "react";
import RichTextEditor from "../../../components/richTextEditor/RichTextEditor";
import { getDownloadURL, getStorage, ref, uploadBytes, refFromURL, deleteObject } from "firebase/storage";
import { arrayUnion, collection, doc, getDoc, increment, setDoc } from "firebase/firestore";


const AskQuestion = ({ userMetadata }) => {

    const location = useLocation();
    const storage = getStorage();
    const navigate = useNavigate();

    const [questionId, setQuestionId] = useState("");
    const [answerId, setAnswerId] = useState("");
    const [user] = useAuthState(auth);
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [images, setImages] = useState([]);

    const [existedImagesURL, setExistedImagesURL] = useState([]);

    const [tagArrays, setTagArrays] = useState([]);
    const [existingQuestion, setExistingQuestion] = useState({});
    const [existingAnswer, setExistingAnswer] = useState({});
    const url = location.pathname;

    useEffect(() => {

        if (questionId !== "") {
            async function getQuestion() {
                const questionDoc = doc(db, "question", questionId);
                const questionDocsSnapshot = await getDoc(questionDoc);
                if (questionDocsSnapshot.exists()) {
                    const titleInput = document.getElementById("titleInput");

                    if (titleInput !== null)
                        titleInput.value = questionDocsSnapshot.data().title;

                    const tagInput = document.getElementById("tagInput");
                    if (tagInput !== null) {
                        var tagString = "";
                        for (let i = 0; i < questionDocsSnapshot.data().tags.length; i++) {
                            tagString += questionDocsSnapshot.data().tags[i] + " ";
                        }
                        tagInput.value = tagString;
                    }


                    var originalQuestion = questionDocsSnapshot.data().question;
                    var imageCount = originalQuestion.split("src=\"https://firebasestorage.googleapis.com/v0/b/tactibots").length - 1;

                    var startingImageIndex = 0;
                    var tempExistedImageUrl = [];
                    var tempExistedImageReference = [];
                    for (let i = 0; i < imageCount; i++) {

                        startingImageIndex = originalQuestion.substring(startingImageIndex).indexOf("<img class=\"tiptap-image-class\" src=\"")
                            + 32 + startingImageIndex;
                        var endingImageIndex = originalQuestion.substring(startingImageIndex).indexOf("alt=\"") + startingImageIndex;
                        var finalEndingImageIndex = originalQuestion.substring(endingImageIndex).indexOf(">") + endingImageIndex;
                        var imageReference = originalQuestion.substring(endingImageIndex, finalEndingImageIndex);

                        if (imageReference.split("\"").length === 3) {
                            tempExistedImageUrl.push(originalQuestion.substring(startingImageIndex + 5, endingImageIndex - 2));
                        }
                    }
                    setExistedImagesURL(tempExistedImageUrl);

                    setTagArrays(questionDocsSnapshot.data().tags);
                    setTitle(questionDocsSnapshot.data().title);
                    setExistingQuestion(questionDocsSnapshot.data());
                }
            }
            getQuestion();
        }

        if (questionId !== "" && answerId !== "") {
            async function getAnswer() {
                const answerDoc = doc(db, "question/" + questionId + "/answer", answerId);
                const answerDocSnapshot = await getDoc(answerDoc);
                if (answerDocSnapshot.exists()) {
                    var originalAnswer = answerDocSnapshot.data().answer;
                    var imageCount = originalAnswer.split("src=\"https://firebasestorage.googleapis.com/v0/b/tactibots").length - 1;

                    var startingImageIndex = 0;
                    var tempExistedImageUrl = [];
                    for (let i = 0; i < imageCount; i++) {

                        startingImageIndex = originalAnswer.substring(startingImageIndex).indexOf("<img class=\"tiptap-image-class\" src=\"")
                            + 32 + startingImageIndex;
                        var endingImageIndex = originalAnswer.substring(startingImageIndex).indexOf("alt=\"") + startingImageIndex;
                        var finalEndingImageIndex = originalAnswer.substring(endingImageIndex).indexOf(">") + endingImageIndex;
                        var imageReference = originalAnswer.substring(endingImageIndex, finalEndingImageIndex);

                        if (imageReference.split("\"").length === 3) {
                            tempExistedImageUrl.push(originalAnswer.substring(startingImageIndex + 5, endingImageIndex - 2));
                        }
                    }
                    setExistedImagesURL(tempExistedImageUrl);

                    setExistingAnswer(answerDocSnapshot.data());
                }
            }
            getAnswer();

        }

    }, [questionId, answerId]);

    useEffect(() => {

        if (typeof (location.pathname.split('/')[3]) !== "undefined") {
            setQuestionId(location.pathname.split('/')[3]);
        }

        if (typeof (location.pathname.split('/')[4]) !== "undefined") {
            setAnswerId(location.pathname.split('/')[4]);
        }
    }, []);

    function toggleFilterEvent(event) {
        tabBtns.forEach(f => f.classList.remove('active'));

        const latestButton = document.getElementById("latestButton");
        const activeButton = document.getElementById("activeButton");
        const unansweredButton = document.getElementById("unansweredButton");
        switch (event) {
            case "latestButton":
                latestButton.classList.toggle("active");
                activeButton.classList.remove("active");
                unansweredButton.classList.remove("active");
                break;
            case "activeButton":
                latestButton.classList.remove("active");
                activeButton.classList.toggle("active");
                unansweredButton.classList.remove("active");
                break;
            case "unansweredButton":
                latestButton.classList.remove("active");
                activeButton.classList.remove("active");
                unansweredButton.classList.toggle("active");
                break;
            default:
                break;
        }
    };

    const tabBtns = document.querySelectorAll(".toggleButton");
    tabBtns.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            toggleFilterEvent(e.target.id);
        });
    });

    return (
        <div className="questions">
            <Sidebar />
            <div style={{ flex: "6" }}>
                <Navbar userMetadata={userMetadata} />
                <div className="askQuestionContainer" style={{ padding: "20px" }}>
                    {
                        questionId !== "" && answerId !== "" ?
                            <h1>Edit your Answer</h1>
                            :
                            questionId !== "" ?
                                <h1>Edit your Question</h1>
                                :
                                <h1>Ask a Question</h1>
                    }
                    <p>TactiBots expect to serve quality Questions and Answers within the platform.</p>
                    <br></br>
                    {
                        answerId === "" ?
                            <div className="mainContainer">

                                <div className="guide">
                                    <h3>Criteria of a Good Question</h3>
                                    <ul>
                                        <li>Describe how you approached the Problems</li>
                                        <li>Describe the Expected Results</li>
                                        <li>Add relevant Tags</li>
                                        <li>Prevent Duplication with other Questions</li>
                                    </ul>
                                </div>
                                <div className="title">
                                    <h3>Title</h3>
                                    <p>Be specific and summarize your problem in a one-liner. (Minimum 10 characters)</p>
                                    <br></br>
                                    <input id="titleInput" onInput={e => setTitle(e.target.value)} maxLength={100} placeholder="e.g. How to incorporate a Sdn Bhd Company in Malaysia?" />
                                </div>
                                <div className="details">
                                    <h3>Details of the Problem</h3>
                                    <p>Describe the problems and how you approach the problem thus far. Highly recommended to attach Images. (Minimum 50 characters)</p>
                                    <br></br>
                                    <div className="tipTapEditor">
                                        {
                                            questionId !== "" && typeof (existingQuestion.question) !== "undefined" ?
                                                <RichTextEditor setDesc={setDesc} setImages={setImages} content={existingQuestion.question}></RichTextEditor>
                                                : questionId === "" && answerId === "" ?
                                                    <RichTextEditor setDesc={setDesc} setImages={setImages}></RichTextEditor>
                                                    : <div></div>
                                        }
                                    </div>
                                </div>
                                <div className="tags">
                                    <h3>Tags</h3>
                                    <p>Add up to 5 tags for better categorization.</p>

                                    <div className="individualTag">
                                        {
                                            Array.from(tagArrays).map((item, i, row) => {
                                                return (
                                                    <div key={i + "_" + item} className="singleTag">
                                                        <p>{item}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <input id="tagInput" onInput={e => {
                                        var currentTags = [];
                                        for (let i = 0; i < String(e.target.value).split(' ').length; i++) {
                                            if (currentTags.length === 5)
                                                break;

                                            // If only symbols, reject
                                            if (String(e.target.value).split(' ')[i].replace(/[^a-zA-Z0-9 ]/g, '').length === 0)
                                                continue;

                                            // If have foreign language, reject
                                            if (/^[a-zA-Z0-9_@#$%&]+$/.test(String(e.target.value).split(' ')[i]) === false)
                                                continue;

                                            // If already existed, reject
                                            if (currentTags.includes(String(e.target.value).split(' ')[i].toLowerCase())) {
                                                continue;
                                            }
                                            else {
                                                currentTags.push(String(e.target.value).split(' ')[i].toLowerCase());
                                            }
                                        }

                                        setTagArrays(currentTags);
                                    }
                                    } placeholder="e.g. accounting  incorporation  lazada  shopee"></input>
                                </div>
                            </div>
                            :
                            <div>
                                {
                                    answerId !== "" && typeof (existingAnswer.answer) !== "undefined" && typeof (existingQuestion.question) !== "undefined" ?
                                        <div>
                                            <h3>Question</h3>
                                            <div className="fullQuestion">
                                                <div dangerouslySetInnerHTML={{ __html: existingQuestion.question.replace("<br>", "<br></br>") }} />
                                            </div>
                                            <br></br>
                                            <div className="details">
                                                <div className="tipTapEditor">
                                                    <h3>Your Answer</h3>
                                                    <RichTextEditor setDesc={setDesc} setImages={setImages} content={existingAnswer.answer}></RichTextEditor>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                }
                            </div>
                    }
                    <button onClick={async () => {

                        // For Editing
                        /*
                        for (let i = 0; i < existedImagesURL.length; i++) {
                            // Remove from Fire Storage
                            if (finalHTML.includes(existedImagesURL) === false) {
                                var bucket = "";
                                if (answerId !== "") {
                                    bucket = "gs://tactibots-1605018905444.appspot.com/questions/" + questionId + "/" + answerId + "/" + existedImagesReference[i];
                                }
                                else {
                                    bucket = "gs://tactibots-1605018905444.appspot.com/questions/" + questionId + "/" + existedImagesReference[i];
                                }

                                const storageRef = ref(storage, bucket);
                                deleteObject(storageRef).then(() => {
                                    // File deleted successfully
                                }).catch((error) => {
                                    // Uh-oh, an error occurred!
                                });
                            }
                        }
                        */

                        if (answerId === "") {
                            if (title.length === 0) {
                                alert("Please fill in the title.");
                                return;
                            }

                            if (title.length <= 10) {
                                alert("Title must be at least 10 characters.");
                                return;
                            }

                            if (title.length >= 100) {
                                alert("Title must not more than 100 characters.");
                                return;
                            }

                            // Calculate character
                            var readableQuestions = "";
                            var insideBracket = false;

                            var calculateChar = desc;
                            calculateChar = calculateChar.replace("&amp;", " ").replace("&lt;", " ").replace("&gt;", " ");
                            for (let i = 0; i < calculateChar.length; i++) {
                                if (insideBracket === false && calculateChar[i] === "<") {
                                    insideBracket = true;
                                    continue;
                                }

                                if (insideBracket === true && calculateChar[i] === ">") {
                                    insideBracket = false;
                                    continue;
                                }

                                if (insideBracket === false) {
                                    readableQuestions += calculateChar[i];
                                }
                            }

                            var searchStringArray = [];
                            var currentCharacter = "";

                            // Process Tags
                            for (let i = 0; i < tagArrays.length; i++) {

                                if (tagArrays[i].length < 3)
                                {
                                    if(searchStringArray.includes(tagArrays[i]) === false)
                                        searchStringArray.push(tagArrays[i]);
                                }

                                for (let j = 0; j < tagArrays[i].length; j++) {
                                    for (let k = 3; k <= tagArrays[i].length - j; k++) {
                                        if(searchStringArray.includes(tagArrays[i].substring(j, j + k)) === false)
                                            searchStringArray.push(tagArrays[i].substring(j, j + k));
                                    }
                                }
                            }

                            // Process Title
                            for (let i = 0; i < title.split(' ').length; i++) {
                                currentCharacter = title.split(' ')[i].replace(/[^a-zA-Z0-9 ]/g, '').toLocaleLowerCase();

                                if (currentCharacter.length < 3)
                                {
                                    if(searchStringArray.includes(currentCharacter) === false)
                                        searchStringArray.push(currentCharacter);
                                }

                                for (let j = 0; j < currentCharacter.length; j++) {
                                    for (let k = 3; k <= currentCharacter.length - j; k++) {
                                        if(searchStringArray.includes(currentCharacter.substring(j, j + k)) === false)
                                            searchStringArray.push(currentCharacter.substring(j, j + k));
                                    }
                                }
                            }

                            // Process Readable Questions
                            for (let i = 0; i < readableQuestions.split(' ').length; i++) {
                                if (searchStringArray.length > 2000)
                                    break;

                                currentCharacter = readableQuestions.split(' ')[i].replace(/[^a-zA-Z0-9 ]/g, '').toLocaleLowerCase();

                                if (currentCharacter.length < 3)
                                {
                                    if(searchStringArray.includes(currentCharacter) === false)
                                        searchStringArray.push(currentCharacter);
                                }

                                for (let j = 0; j < currentCharacter.length; j++) {
                                    for (let k = 3; k <= currentCharacter.length - j; k++) {
                                        if(searchStringArray.includes(currentCharacter.substring(j, j + k)) === false)
                                            searchStringArray.push(currentCharacter.substring(j, j + k));
                                    }
                                }
                            }

                            if (readableQuestions.length < 50) {
                                alert("Details must be at least 50 characters.");
                                return;
                            }

                            // Replace SRC with Download URL
                            var finalHTML = desc;

                            const documentId = doc(collection(db, "question")).id;

                            var copyImages = [];
                            for (let i = 0; i < images.length; i++) {
                                if (desc.includes("alt=\"" + images[i].size + "_" + encodeURIComponent(images[i].name)))
                                    copyImages.push(images[i]);
                            }

                            var startingImageIndex = 0;
                            var startingIndex = -1;
                            for (let i = 0; i < copyImages.length; i++) {

                                var endingImageIndex = desc.indexOf("alt=\"" + copyImages[i].size + "_" + encodeURIComponent(copyImages[i].name));
                                while (true) {
                                    startingImageIndex = desc.substring(startingImageIndex).indexOf("<img class=\"tiptap-image-class\" src=\"") + 32 + startingImageIndex;
                                    startingIndex++;

                                    if (startingIndex === i + existedImagesURL.length)
                                        break;
                                }

                                // If Existed, just leave it
                                if (existedImagesURL.includes(desc.substring(startingImageIndex, endingImageIndex)))
                                    continue;

                                var bucket = "";
                                if (questionId === "") {
                                    bucket = "gs://tactibots-1605018905444.appspot.com/questions/" + documentId + "/" + copyImages[i].size + "_" + encodeURIComponent(copyImages[i].name);
                                }
                                else {
                                    bucket = "gs://tactibots-1605018905444.appspot.com/questions/" + questionId + "/" + copyImages[i].size + "_" + encodeURIComponent(copyImages[i].name);
                                }

                                const storageRef = ref(storage, bucket);
                                await uploadBytes(storageRef, copyImages[i]);
                                const link = await getDownloadURL(storageRef)

                                finalHTML = finalHTML.replace(desc.substring(startingImageIndex, endingImageIndex), "src=\"" + link + "\" ");
                            }

                            // Add New Question
                            if (questionId === "") {
                                await setDoc(doc(collection(db, "question"), documentId), {
                                    uid: user.uid,
                                    name: user.displayName,
                                    image: user.photoURL,
                                    title: title,
                                    search_string_array: searchStringArray,
                                    question: finalHTML,
                                    tags: tagArrays,
                                    created_at: new Date(),
                                    like_count: 0,
                                    answer_count: 0,
                                    view_count: 0,
                                    status: "normal",
                                });

                                var currentMonth = new Date().getFullYear() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0");
                                for (let i = 0; i < tagArrays.length; i++) {

                                    var tagSearchStringArray = [];

                                    if (tagArrays[i].length < 3)
                                    {
                                        if(tagSearchStringArray.includes(tagArrays[i]) === false)
                                            tagSearchStringArray.push(tagArrays[i]);
                                    }

                                    for (let j = 0; j < tagArrays[i].length; j++) {
                                        for (let k = 3; k <= tagArrays[i].length - j; k++) {
                                            if(tagSearchStringArray.includes(tagArrays[i].substring(j, j + k)) === false)
                                                tagSearchStringArray.push(tagArrays[i].substring(j, j + k));
                                        }
                                    }

                                    await setDoc(doc(collection(db, "tag"), tagArrays[i]), {
                                        name: tagArrays[i],
                                        search_string_array: tagSearchStringArray,
                                        total_count: increment(1),
                                        [currentMonth]: increment(1),
                                    }, { merge: true });
                                }

                                // Total Count
                                await setDoc(doc(collection(db, "count"), "questions"), {
                                    total_count: increment(1),
                                    [currentMonth]: increment(1),
                                }, { merge: true });

                                navigate("/questions/" + documentId + "/" + encodeURIComponent(title.toLowerCase()));
                            }
                            // Edit Question
                            else {
                                await setDoc(doc(collection(db, "question"), questionId), {
                                    name: user.displayName,
                                    image: user.photoURL,
                                    title: title,
                                    search_string_array: searchStringArray,
                                    question: finalHTML,
                                    history_question: arrayUnion(existingQuestion.question),
                                    history_question_date: arrayUnion(new Date()),
                                    tags: tagArrays,
                                    updated_at: new Date(),
                                }, { merge: true });

                                var createdMonth = new Date(existingQuestion.created_at.seconds * 1000).getFullYear() + "-"
                                    + (new Date(existingQuestion.created_at.seconds * 1000).getMonth() + 1).toString().padStart(2, "0");

                                // Remove count for all old tags
                                for (let i = 0; i < existingQuestion.tags.length; i++) {
                                    if (tagArrays.includes(existingQuestion.tags[i]))
                                        continue;

                                    await setDoc(doc(collection(db, "tag"), existingQuestion.tags[i]), {
                                        name: existingQuestion.tags[i],
                                        total_count: increment(-1),
                                        [createdMonth]: increment(-1),
                                    }, { merge: true });
                                }

                                // Increase count for all new tags
                                for (let i = 0; i < tagArrays.length; i++) {
                                    if (existingQuestion.tags.includes(tagArrays[i]))
                                        continue;

                                    var tagSearchStringArray = [];

                                    if (tagArrays[i].length < 3)
                                    {
                                        if(tagSearchStringArray.includes(tagArrays[i]) === false)
                                            tagSearchStringArray.push(tagArrays[i]);
                                    }

                                    for (let j = 0; j < tagArrays[i].length; j++) {
                                        for (let k = 3; k <= tagArrays[i].length - j; k++) {
                                            if(tagSearchStringArray.includes(tagArrays[i].substring(j, j + k)) === false)
                                                tagSearchStringArray.push(tagArrays[i].substring(j, j + k));
                                        }
                                    }

                                    await setDoc(doc(collection(db, "tag"), tagArrays[i]), {
                                        name: tagArrays[i],
                                        search_string_array: tagSearchStringArray,
                                        total_count: increment(1),
                                        [createdMonth]: increment(1),
                                    }, { merge: true });
                                }

                                navigate("/questions/" + questionId + "/" + encodeURIComponent(title.toLowerCase()));
                            }
                        }
                        else {
                            var readableQuestions = "";
                            var insideBracket = false;

                            var calculateChar = desc;
                            calculateChar = calculateChar.replace("&amp;", " ").replace("&lt;", " ").replace("&gt;", " ");
                            for (let i = 0; i < calculateChar.length; i++) {
                                if (insideBracket === false && calculateChar[i] === "<") {
                                    insideBracket = true;
                                    continue;
                                }

                                if (insideBracket === true && calculateChar[i] === ">") {
                                    insideBracket = false;
                                    continue;
                                }

                                if (insideBracket === false) {
                                    readableQuestions += calculateChar[i];
                                }
                            }

                            if (readableQuestions.length < 20) {
                                alert("Answer must be at least 20 characters.");
                                return;
                            }

                            // Replace SRC with Download URL
                            var finalHTML = desc;

                            var copyImages = [];
                            for (let i = 0; i < images.length; i++) {
                                if (desc.includes("alt=\"" + images[i].size + "_" + encodeURIComponent(images[i].name)))
                                    copyImages.push(images[i]);
                            }

                            var startingImageIndex = 0;
                            var startingIndex = -1;
                            for (let i = 0; i < copyImages.length; i++) {

                                var endingImageIndex = desc.indexOf("alt=\"" + copyImages[i].size + "_" + encodeURIComponent(copyImages[i].name));
                                while (true) {
                                    startingImageIndex = desc.substring(startingImageIndex).indexOf("<img class=\"tiptap-image-class\" src=\"") + 32 + startingImageIndex;
                                    startingIndex++;

                                    if (startingIndex === i + existedImagesURL.length)
                                        break;
                                }

                                // If Existed, just leave it
                                if (existedImagesURL.includes(desc.substring(startingImageIndex, endingImageIndex)))
                                    continue;

                                const bucket = "gs://tactibots-1605018905444.appspot.com/questions/" + questionId + "/" + answerId + "/" + copyImages[i].size + "_" + encodeURIComponent(copyImages[i].name);
                                const storageRef = ref(storage, bucket);
                                await uploadBytes(storageRef, copyImages[i]);
                                const link = await getDownloadURL(storageRef)

                                finalHTML = finalHTML.replace(desc.substring(startingImageIndex, endingImageIndex), "src=\"" + link + "\" ");
                            }

                            await setDoc(doc(collection(db, "question/" + questionId + "/answer"), answerId), {
                                history_answer: arrayUnion(existingAnswer.answer),
                                history_answer_date: arrayUnion(new Date()),
                                answer: finalHTML,
                                updated_at: new Date()
                            }, { merge: true });

                            navigate("/questions/" + questionId + "/" + encodeURIComponent(title.toLowerCase()));
                        }
                    }}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default AskQuestion;
import "./ContactUs.scss";
import HomeNavbar from "../../../components/homeNavbar/HomeNavbar";
import HomeFooter from "../../../components/homeFooter/HomeFooter";
import { Helmet } from "react-helmet-async";


const ContactUs = ({ active }) => {

    return (
        <div>
            <div className="homeContainer">
                <Helmet>
                    <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                    <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
                </Helmet>
                <HomeNavbar active={active} />
                <div className="middle">
                    <div className="contactUsContainer" name="contactUsContainer">

                        <div className="topContainer">
                            <h1>Contact Us</h1>
                            <p>Feel free to approach us!</p>
                        </div>

                        <div className="location">
                            <div className="left">
                                <h1>Location</h1>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.438355978915!2d100.47689971499605!3d5.349870737175472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304ac99d38443bd3%3A0x7be554641b9112d6!2sTactiBots!5e0!3m2!1sen!2smy!4v1668153050657!5m2!1sen!2smy"

                                    style={{ border: "0" }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="right">
                                <div className="details">
                                    <h1>Address</h1>
                                    <p>5, Lorong Sepakat 1, Taman Bandaraya</p>
                                    <p>14000 Bukit Mertajam</p>
                                </div>
                                <div className="details">
                                    <h1>Email</h1>
                                    <p>contact@tactibots.com</p>
                                </div>
                                <div className="details">
                                    <h1>Phone</h1>
                                    <p>011 5559 1242</p>
                                </div>
                                <div className="details">
                                    <h1>Facebook</h1>
                                    <a href="https://www.facebook.com/tactibot">https://www.facebook.com/tactibot</a>
                                    <br></br>
                                    <a href="https://www.facebook.com/tactibot">https://www.facebook.com/tactibots (Old)</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <HomeFooter />
        </div>
    )
}

export default ContactUs;
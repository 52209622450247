import "./Account.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import Popup from "../../components/popup/Popup";
import { updateProfile, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { auth, db } from "../../firebaseconfig";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, onSnapshot, query, where, orderBy, doc, getDocs, setDoc, updateDoc, arrayUnion, increment, getDoc } from "firebase/firestore";
import encryption from "../../utils/encryption";

const Account = ({ type, userMetadata }) => {

    const [displayName, setDisplayName] = useState("");
    const storage = getStorage();
    const [userRows, setUserRows] = useState([]);
    const [user] = useAuthState(auth);
    const [selectedImage, setSelectedImage] = useState(null);
    const [photoUrl, setPhotoUrl] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [oldPassword, setOldPassword] = useState("");
    const [createPassword, setCreatePassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [openPopup, setOpenPopup] = useState(false);

    var currentUserRows = [];
    useEffect(() => {

        if (user.photoURL === "")
            setPhotoUrl("https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg");

        setDisplayName(user.displayName);
        setPhotoUrl(user.photoURL);

        var halfYearAgo = new Date();
        halfYearAgo.setDate(halfYearAgo.getDate() - 180);

        const accountDocsCollection = collection(db, "user");
        const accountDocsQuery = query(accountDocsCollection,
            where("uid", "==", user.uid));

        var no = 0;
        onSnapshot(accountDocsQuery, (snapshot) => {
            currentUserRows = Object.assign([], currentUserRows);
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    no++;
                    //if(currentUserRows.filter(e => e.id === change.doc.id).length === 0){
                    currentUserRows.push({
                        id: change.doc.id,
                        no: no,
                        title: change.doc.data().title,
                        details: change.doc.data().details,
                        status: change.doc.data().status,
                        created_at: change.doc.data().created_at
                    });
                    //}
                }

                if (change.type === "modified") {
                    currentUserRows = currentUserRows.map(obj => {
                        if (obj.id === change.doc.id) {
                            return {
                                ...obj,
                                title: change.doc.data().title,
                                details: change.doc.data().details,
                                status: change.doc.data().status,
                                created_at: change.doc.data().created_at
                            };
                        }
                        return obj;
                    });
                }

                if (change.type === "removed") {
                    currentUserRows = currentUserRows.filter(item => item.id !== change.doc.id);
                }

            }
            );

            setUserRows(currentUserRows);
        });
    }, []);


    return (
        <div className="account">
            <Sidebar />
            <div className="accountContainer">
                <Navbar userMetadata={userMetadata} />
                <div className="top">
                    <div className="accountTitle">
                        <p>Account</p>
                    </div>
                </div>
                <div className="center">


                    <div className="body">

                        <div>Name : </div>
                        <input value={displayName} onInput={e => {
                            if (e.target.value.length <= 60)
                                setDisplayName(e.target.value)
                            else
                                alert("Maximum of 60 characters are allowed.");
                        }}></input>
                        <button className="accountButton" onClick={async () => {

                            const accountDocs = doc(db, "user", user.uid);
                            const docSnap = await getDoc(accountDocs);

                            if (docSnap.exists()) {
                                if (profilePhoto !== null) {
                                    const bucket = "gs://tactibots-1605018905444.appspot.com/profile/" + user.uid + "/profile_picture.jpg";
                                    const storageRef = ref(storage, bucket);
                                    await uploadBytes(storageRef, profilePhoto);
                                    const downloadLink = await getDownloadURL(storageRef);

                                    await updateProfile(user, {
                                        displayName: displayName,
                                        photoURL: downloadLink
                                    });

                                    await setDoc(doc(collection(db, "user"), user.uid), {
                                        name: displayName,
                                        photoURL: downloadLink,
                                    }, { merge: true });
                                }
                                else {
                                    await updateProfile(user, {
                                        displayName: displayName,
                                    });

                                    await setDoc(doc(collection(db, "user"), user.uid), {
                                        name: displayName,
                                    }, { merge: true });
                                }
                            }



                            window.location.reload();
                        }}>Update</button>

                        <div>Profile Picture : </div>
                        <div className="profilePicture">
                            <div>

                                {
                                    String(profilePhoto).length <= 4 ?
                                        <div className="circle">{user.displayName.substring(0, 1)}</div>
                                        :
                                        <img src={profilePhoto ? URL.createObjectURL(profilePhoto) : photoUrl}
                                            alt="" />
                                }

                            </div>
                            <div className="description">
                                <ul>
                                    <input type="file" onInput={e => {
                                        setProfilePhoto(e.target.files[0]);
                                    }}></input>
                                    <li>Recommended image dimensions: width 300px, height 300px</li>
                                    <li>Maximum file size: 1.0MB</li>
                                    <li>Image format accepted: JPG,JPEG,PNG</li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="body">
                        <h2>Update Password</h2>
                        <div>
                            <input type="password" className="signupPassword" placeholder="Old Password" onChange={(e) => setOldPassword(e.target.value)}
                                style={{ marginBottom: "20px" }}>
                            </input>

                            <input type="password" className="signupPassword" placeholder="New Password" onChange={(e) => setCreatePassword(e.target.value)}>
                            </input>
                            <ul style={{ marginTop: "5px", color: "black" }}>
                                <li>At least 8 digits in length.</li>
                                <li>At least 1 char (a-z) and 1 number (0-9) and 1 special char (eg: !@#$)</li>
                            </ul>
                        </div>
                        <div>
                            <input type="password" className="signupConfirmPassword" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)}>
                            </input>
                        </div>
                        <button style={{ marginTop: "10px" }} onClick={async () => {
                            if (oldPassword === "") {
                                alert("Please fill in Old Password.");
                                return;
                            }

                            const credential = EmailAuthProvider.credential(
                                user.email,
                                oldPassword
                            );
                            // Now you can use that to reauthenticate

                            var endFunction = false;
                            
                            await reauthenticateWithCredential(
                                user, 
                                credential
                            ).then(() => {

                            }).catch((error) => {
                                endFunction = true;
                                if(error.code === "auth/wrong-password"){
                                    alert("Old Password is wrong.");
                                }
                                else if(error.code === "auth/too-many-requests"){
                                    alert("Too many requests at once. Please try again later on.");
                                }
                                else{
                                    alert(error.code + "\n\n" + error.message);
                                }
                            });

                            if(endFunction === true)
                                return;

                            const userCollection = doc(db, "user", user.uid);
                            var password_history = [];
                            const userDocSnap = await getDoc(userCollection);
                            if (userDocSnap.exists()) {

                                if (userDocSnap.data().password_history.length < 5) {
                                    // Get Maximum up to 4 passwords
                                    for (let i = 0; i < userDocSnap.data().password_history.length; i++) {
                                        if (createPassword === encryption.aes258descryption(userDocSnap.data().password_history[i])) {
                                            alert("Please do not reuse your Past Passwords");
                                            return;
                                        }
                                        password_history.push(userDocSnap.data().password_history[i]);
                                    }
                                }
                                else {
                                    // Starting from i = 1 and get the last 4 only
                                    for (let i = 1; i < userDocSnap.data().password_history.length; i++) {
                                        if (createPassword === encryption.aes258descryption(userDocSnap.data().password_history[i])) {
                                            alert("Please do not reuse your Past Passwords");
                                            return;
                                        }
                                        password_history.push(userDocSnap.data().password_history[i]);
                                    }
                                }

                                if (encryption.isPasswordValid(createPassword,
                                    confirmPassword) === false) {
                                    return;
                                }

                                var encryptedPassword = encryption.aes258encryption(createPassword);
                                password_history.push(encryptedPassword);

                                await setDoc(userCollection, {
                                    password_history: password_history
                                }, { merge: true });

                                await updatePassword(user, createPassword);
                                alert("Password has been reset successfully.");
                                window.location.reload();
                            } else {
                                alert("Unknown Error.");
                            }


                        }}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Account;
import "./orderSingle.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { auth, db } from "../../../firebaseconfig";
import { collection, getDocs, doc, getDoc, setDoc, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import Datatable from "../../../components/datatable/Datatable";

const OrderSingle = ({ shopList, userMetadata }) => {

    const [orderItemRows, setOrderItemRows] = useState([]);
    const [user] = useAuthState(auth);
    const location = useLocation();
    const platform = location.pathname.split('/')[3].split('_')[0];
    const shop_id = Number(location.pathname.split('/')[3].split('_')[1]);
    const order_id = location.pathname.split('/')[3].split('_')[2];
    const [shopName, setShopName] = useState("");

    var [orderInfo, setOrderInfo] = useState({
        order_id: "",
        customer_details: {
            item_list: [],
            pay_time: "",
            pickup_done_time: "",
            shipping_carrier: "",
            create_time: "",
            order_status: "",
            recipient_address: {
                full_address: "",
                name: "",
                phone: ""
            }
        },
        address_shipping: {
            first_name: "",
            phone: "",
            city: "",
            country: "",
        },
        tracking_number: "",
        shipping_carrier: "",
        logistic_details: {
            tracking_number: ""
        }
    });

    useEffect(() => {

        async function getData() {

            switch (platform) {
                case "Shopee":
                    // Check if Shop belongs to UID
                    const shopeeOrderShopDocsCollection = collection(db, "ecommerce/auth/shop");
                    const shopeeOrderShopDocsQuery = query(shopeeOrderShopDocsCollection
                        , where("uid", "==", user.uid)
                        , where("platform", "==", platform)
                        , where("shop_id", "==", shop_id));

                    var shopExists = false;
                    await getDocs(shopeeOrderShopDocsQuery)
                        .then((querySnapshot) => {

                            if (querySnapshot.size === 1) {
                                shopExists = true;

                                querySnapshot.forEach((doc) => {
                                    setShopName(doc.data().name);
                                });
                            }
                        });

                    if (shopExists === false)
                        return;

                    const shopeeOrderCollection = collection(db, "ecommerce/shopee/order");
                    const shopeeOrderQuery = query(shopeeOrderCollection
                        , where("shop_id", "==", shop_id)
                        , where("order_id_list", "array-contains-any", [order_id])
                        , limit(1));

                    await getDocs(shopeeOrderQuery)
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                for (let i = 0; i < doc.data().order_list.length; i++) {
                                    if (doc.data().order_list[i].order_id === order_id) {
                                        setOrderInfo(doc.data().order_list[i]);

                                        var tempRow = [];
                                        for (let j = 0; j < doc.data().order_list[i].customer_details.item_list.length; j++) {
                                            tempRow.push({
                                                no: j + 1,
                                                id: doc.id + "_" + doc.data().order_list[i].customer_details.item_list[j].order_item_id,
                                                item_details: doc.data().order_list[i].customer_details.item_list[j]
                                            })
                                        }

                                        setOrderItemRows(tempRow);
                                        break;
                                    }
                                }
                            });
                        });
                    break;
                case "Lazada":

                    // Check if Shop belongs to UID
                    const lazadaOrderShopDocsCollection = collection(db, "ecommerce/auth/shop");
                    const lazadaOrderShopDocsQuery = query(lazadaOrderShopDocsCollection
                        , where("uid", "==", user.uid)
                        , where("platform", "==", platform)
                        , where("shop_id", "==", String(shop_id)));

                    var shopExists = false;
                    await getDocs(lazadaOrderShopDocsQuery)
                        .then((querySnapshot) => {

                            if (querySnapshot.size === 1) {
                                shopExists = true;

                                querySnapshot.forEach((doc) => {
                                    setShopName(doc.data().name);
                                });
                            }
                        });

                    if (shopExists === false)
                        return;

                    const lazadaOrderCollection = collection(db, "ecommerce/lazada/order");
                    const lazadaOrderQuery = query(lazadaOrderCollection
                        , where("shop_id", "==", String(shop_id))
                        , where("order_id_list", "array-contains-any", [Number(order_id)])
                        , limit(1));

                    await getDocs(lazadaOrderQuery)
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {

                                for (let i = 0; i < doc.data().order_list.length; i++) {
                                    if (doc.data().order_list[i].order_id === Number(order_id)) {

                                        var shipmentProviderString = "";
                                        var trackingNumberString = "";
                                        var trackingNumberArray = [];
                                        var tempRow = [];
                                        var currentData = doc.data().order_list[i];
                                        for (let j = 0; j < currentData.item_details.length; j++) {

                                            if (trackingNumberArray.includes(currentData.item_details[j].tracking_code) === false) {
                                                if (trackingNumberString !== "") {
                                                    trackingNumberString += ", " + currentData.item_details[j].tracking_code;
                                                    shipmentProviderString += ", " + currentData.item_details[j].shipment_provider;
                                                }
                                                else {
                                                    trackingNumberString = currentData.item_details[j].tracking_code;
                                                    shipmentProviderString = currentData.item_details[j].shipment_provider;
                                                }

                                                trackingNumberArray.push(currentData.item_details[j].tracking_code);
                                            }

                                            var currentItemData = currentData.item_details[j];
                                            currentItemData.image_info = { image_url : currentItemData.product_main_image };
                                            currentItemData.model_discounted_price = currentItemData.paid_price;
                                            currentItemData.model_quantity_purchased = 1;
                                            currentItemData.item_name = currentItemData.name;
                                            currentItemData.model_name = currentItemData.variation;
                                            currentItemData.model_sku = currentItemData.sku;

                                            tempRow.push({
                                                no: j + 1,
                                                id: doc.id + "_" + currentItemData.order_item_id,
                                                item_details: currentItemData
                                            })
                                        }
                                        currentData.tracking_number = trackingNumberString;
                                        currentData.shipping_carrier = shipmentProviderString;
                                        setOrderInfo(currentData);

                                        setOrderItemRows(tempRow);
                                        break;
                                    }
                                }
                            });
                        });
                    break;
                default:
                    break;
            }

        }
        getData();

    }, []);

    switch (platform) {
        case "Lazada":

            return (
                <div className="single">
                    <Sidebar />
                    <div className="singleContainer">
                        <Navbar userMetadata={userMetadata} />
                        <div className="top">
                            <div className="left">
                                {/* 
                        <div className="editButton">Edit</div>
                        */}
                                <h1 className="title">Information</h1>
                                <div className="item">
                                    {/* 
                            <img
                                src="https://cf.shopee.com.my/file/dd20c90324c406fd02c0e0c36481309d_tn"
                                alt=""
                                className="itemImg"
                            />
                            */}
                                    <div className="details">
                                        {/* 
                                    <h1 className="itemTitle">{orderInfo.order_id}</h1>
                                */}
                                        <div className="detailItem">
                                            <h2>Platform:</h2>
                                            <span className="itemValue">{platform}</span>
                                        </div>
                                        <div className="detailItem">
                                            <h2>Shop Name:</h2>
                                            <span className="itemValue">{shopName}</span>
                                        </div>
                                        <div className="detailItem">
                                            <h2>Order ID:</h2>
                                            <span className="itemValue">{orderInfo.order_id}</span>
                                        </div>
                                        <div className="detailItem">
                                            <h2>Order Created At :</h2>
                                            <span className="itemValue">{new Date(orderInfo.created_at).toLocaleString('en-US',
                                                {
                                                    timeZone: 'Asia/Kuala_Lumpur',
                                                    year: 'numeric', month: 'short', day: 'numeric',
                                                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                }
                                            )}</span>
                                        </div>
                                        <div className="detailItem">
                                            <h2>Status:</h2>
                                            <span className="itemValue">{orderInfo.status}</span>
                                        </div>
                                        {
                                            /*
                                        <div className="detailItem">
                                            <h2>Delivery Address:</h2>
                                            <span className="itemValue">{orderInfo.address_shipping.first_name + ", " + orderInfo.address_shipping.phone}</span>
                                            <span className="itemValue">{orderInfo.address_shipping.city + ", " + orderInfo.address_shipping.country}</span>
                                        </div>
                                            */
                                        }
                                        <div className="detailItem">
                                            {/*
                                    <span className="itemKey">Tracking Number:</span>
                                    */}
                                            <h2>Logistic Details:</h2>
                                            <span className="itemValue">{orderInfo.tracking_number}</span>
                                            <span className="itemValue">{orderInfo.shipping_carrier}</span>
                                        </div>

                                        <div className="detailItem">
                                            <h2>Timeline:</h2>
                                            <span className="itemValue">{"Paid at " +
                                                new Date(orderInfo.created_at).toLocaleString('en-US',
                                                    {
                                                        timeZone: 'Asia/Kuala_Lumpur',
                                                        year: 'numeric', month: 'short', day: 'numeric',
                                                        hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                    }
                                                )
                                            }</span>
                                            <span className="itemValue">{"Pick up at " + new Date(orderInfo.created_at).toLocaleString('en-US',
                                                {
                                                    timeZone: 'Asia/Kuala_Lumpur',
                                                    year: 'numeric', month: 'short', day: 'numeric',
                                                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                }
                                            )
                                            }</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <h2 className="title">Product Information</h2>
                            <Datatable type="orders_single" className="datatable" overrideDataRow={orderItemRows} />
                        </div>
                    </div>
                </div>
            )
        case "Shopee":
        default:
            return (
                <div className="single">
                    <Sidebar />
                    <div className="singleContainer">
                        <Navbar userMetadata={userMetadata} />
                        <div className="top">
                            <div className="left">
                                {/* 
                                <div className="editButton">Edit</div>
                                */}
                                <h1 className="title">Information</h1>
                                <div className="item">
                                    {/* 
                                    <img
                                        src="https://cf.shopee.com.my/file/dd20c90324c406fd02c0e0c36481309d_tn"
                                        alt=""
                                        className="itemImg"
                                    />
                                    */}
                                    <div className="details">
                                        {/* 
                                            <h1 className="itemTitle">{orderInfo.order_id}</h1>
                                        */}
                                        <div className="detailItem">
                                            <h2>Platform:</h2>
                                            <span className="itemValue">{platform}</span>
                                        </div>
                                        <div className="detailItem">
                                            <h2>Shop Name:</h2>
                                            <span className="itemValue">{shopName}</span>
                                        </div>
                                        <div className="detailItem">
                                            <h2>Order ID:</h2>
                                            <span className="itemValue">{orderInfo.order_id}</span>
                                        </div>
                                        <div className="detailItem">
                                            <h2>Order Created At :</h2>
                                            <span className="itemValue">{new Date(Number(orderInfo.customer_details.create_time) * 1000).toLocaleString('en-US',
                                                {
                                                    timeZone: 'Asia/Kuala_Lumpur',
                                                    year: 'numeric', month: 'short', day: 'numeric',
                                                    hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                }
                                            )}</span>
                                        </div>
                                        <div className="detailItem">
                                            <h2>Status:</h2>
                                            <span className="itemValue">{orderInfo.customer_details.order_status}</span>
                                        </div>
                                        {
                                            /*
                                        <div className="detailItem">
                                            <h2>Delivery Address:</h2>
                                            <span className="itemValue">{orderInfo.customer_details.recipient_address.name + ", " + orderInfo.customer_details.recipient_address.phone}</span>
                                            <span className="itemValue">{orderInfo.customer_details.recipient_address.full_address}</span>
                                        </div>
                                            */
                                        }
                                        <div className="detailItem">
                                            {/*
                                            <span className="itemKey">Tracking Number:</span>
                                            */}
                                            <h2>Logistic Details:</h2>
                                            <span className="itemValue">{orderInfo.logistic_details.tracking_number}</span>
                                            <span className="itemValue">{orderInfo.customer_details.shipping_carrier}</span>
                                        </div>

                                        <div className="detailItem">
                                            <h2>Timeline:</h2>
                                            <span className="itemValue">{"Paid at " +
                                                new Date(Number(orderInfo.customer_details.pay_time) * 1000).toLocaleString('en-US',
                                                    {
                                                        timeZone: 'Asia/Kuala_Lumpur',
                                                        year: 'numeric', month: 'short', day: 'numeric',
                                                        hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                    }
                                                )
                                            }</span>
                                            {
                                                orderInfo.customer_details.pickup_done_time !== 0 ?
                                                <span className="itemValue">{"Pick up at " + new Date(Number(orderInfo.customer_details.pickup_done_time) * 1000).toLocaleString('en-US',
                                                    {
                                                        timeZone: 'Asia/Kuala_Lumpur',
                                                        year: 'numeric', month: 'short', day: 'numeric',
                                                        hour: '2-digit', minute: '2-digit', hourCycle: 'h23'
                                                    }
                                                )
                                                }</span>
                                                :<span></span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <h2 className="title">Product Information</h2>
                            <Datatable type="orders_single" className="datatable" overrideDataRow={orderItemRows} />
                        </div>
                    </div>
                </div>
            )

    }
}

export default OrderSingle;
import "./productNew.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { auth, db } from "../../../firebaseconfig";
import imageCompression from "browser-image-compression";
import { collection, query, where, doc, getDocs, setDoc, updateDoc, arrayUnion, increment, onSnapshot, orderBy, limit } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import Datatable from "../../../components/datatable/Datatable";

const ProductNew = ({ type, userMetadata }) => {

    const navigate = useNavigate();

    const location = useLocation();
    const url = location.pathname;

    const [user] = useAuthState(auth);
    const [sku, setSku] = useState("");
    const [name, setName] = useState("");
    const [variation, setVariationDescription] = useState("");
    const [salePrice, setSalePrice] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [previousImage, setPreviousImage] = useState("");
    const [editSku, setEditSku] = useState("");
    const [transferHistory, setTransferHistory] = useState([]);

    const storage = getStorage();

    useEffect(() => {

        if (url.split('/').length === 4) {
            setEditSku(decodeURIComponent(url.split('/')[3]));
        }
    }, [url]);

    useEffect(() => {
        async function getSku() {

            const skuDocsCollection = collection(db, "inventory");
            const skuQuery = query(skuDocsCollection,
                where("uid", "==", user.uid),)
            where("sku_list", "array-contains-any", [editSku]);

            onSnapshot(skuQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {

                    var fullDoc = change.doc.data();
                    for (let i = 0; i < fullDoc.inventory.length; i++) {
                        if (fullDoc.inventory[i].sku === editSku) {
                            var singleDoc = fullDoc.inventory[i];

                            setPreviousImage(fullDoc.inventory[i].image);

                            const skuElement = document.getElementById("skuInputText");
                            skuElement.value = singleDoc.sku;
                            setSku(singleDoc.sku);

                            const nameElement = document.getElementById("nameInputText");
                            nameElement.value = singleDoc.item_name;
                            setName(singleDoc.item_name);

                            const variationElement = document.getElementById("variationInputText");
                            variationElement.value = singleDoc.variation_name;
                            setVariationDescription(singleDoc.variation_name);

                            const purchaseElement = document.getElementById("purchasePriceInputText");
                            purchaseElement.value = singleDoc.purchase_price;
                            setPurchasePrice(singleDoc.purchase_price);

                            const saleElement = document.getElementById("salePriceInputText");
                            saleElement.value = singleDoc.sale_price;
                            setSalePrice(singleDoc.sale_price);
                        }
                    }

                })
            });
        }

        async function getTransferHistory() {

            const skuDocsCollection = collection(db, "inventory_transfer");
            const skuQuery = query(skuDocsCollection,
                where("uid", "==", user.uid),
                where("sku", "==", editSku));

            onSnapshot(skuQuery, (snapshot) => {
                var transferHistoryArray = [];
                snapshot.docChanges().forEach((change) => {

                    var fullDoc = change.doc.data();
                    for (let i = 0; i < fullDoc.transfer_history.length; i++) {
                        transferHistoryArray.push(fullDoc.transfer_history[i]);
                    }


                    setTransferHistory(transferHistoryArray);
                })
            });
        }
        getSku();
        getTransferHistory();
    }, [editSku]);

    async function createSKU() {

        if (sku === "" || name === "") {
            alert("Please fill in SKU and Name.");
            return;
        }

        const inventoryCollection = collection(db, "inventory");
        // Check whether New or Edit-To-New SKU Existed
        if(editSku === "" || editSku !== sku){
            var inventoryQuery = query(inventoryCollection,
                where("uid", "==", user.uid),
                where("sku_list", "array-contains-any", [sku]),
                limit(1));
            const querySnapshot = await getDocs(inventoryQuery);
            querySnapshot.forEach((doc) => {
                var fullDoc = doc.data();
                for (let i = 0; i < fullDoc.sku_list.length; i++) {
                    if (sku === fullDoc.sku_list[i]) {
                        alert("SKU already created previously.");
                        return;
                    }
                }
            });
        }

        var downloadURL = "";
        if (selectedImage !== null) {
            const bucket = "gs://tactibots-1605018905444.appspot.com/products/" + user.uid + "/" + encodeURIComponent(sku) + ".jpg";
            const storageRef = ref(storage, bucket);
            await uploadBytes(storageRef, selectedImage);
            downloadURL = await getDownloadURL(storageRef);
        }

        // Update Existing SKU
        if (editSku !== "") {
            var searchOldQuery = query(inventoryCollection,
                where("uid", "==", user.uid),
                where("sku_list", "array-contains-any", [editSku]),
                limit(1));
            const oldQuerySnapshot = await getDocs(searchOldQuery);
            oldQuerySnapshot.forEach(async (currentDoc) => {

            var fullDoc = currentDoc.data();
            for (let i = 0; i < fullDoc.sku_list.length; i++) {
                if (editSku === fullDoc.sku_list[i]) {
                    fullDoc.sku_list[i] = sku;
                }
            }
            
            for(let i = 0; i < fullDoc.inventory.length; i++){
                if(editSku === fullDoc.inventory[i].sku){
                    fullDoc.inventory[i].sku = sku;

                    // If have old image and SKU changed, delete old Image
                    if(fullDoc.inventory[i].image !== "" && editSku !== sku){
                        const bucket = "gs://tactibots-1605018905444.appspot.com/products/" + user.uid + "/" + encodeURIComponent(editSku) + ".jpg";
                        const storageRef = ref(storage, bucket);
                        deleteObject(storageRef).then(() => {
                            // File deleted successfully
                          }).catch((error) => {
                            // Uh-oh, an error occurred!
                          });
                          
                        fullDoc.inventory[i].image = "";
                    }

                    // Only update Image if it's not Empty
                    if(downloadURL !== "")
                        fullDoc.inventory[i].image = downloadURL;

                    fullDoc.inventory[i].item_name = name;
                    fullDoc.inventory[i].variation_name = variation;
                    fullDoc.inventory[i].sale_price = salePrice;
                    fullDoc.inventory[i].purchase_price = purchasePrice;
                    fullDoc.inventory[i].updated_at = new Date();
                }
            }
            
            // Update transfer history path
            if(editSku !== sku){
                const skuDocsCollection = collection(db, "inventory_transfer");
                const skuQuery = query(skuDocsCollection,
                    where("uid", "==", user.uid),
                    where("sku", "==", editSku));

                onSnapshot(skuQuery, (snapshot) => {
                    snapshot.docChanges().forEach(async (change) => {
                        var fullDoc = change.doc.data();
                        fullDoc.sku = sku;
                        await setDoc(doc(db, "inventory_transfer", change.doc.id), fullDoc);
                    })
                });
            }

            // Update document
            await setDoc(doc(db, "inventory", currentDoc.id), fullDoc);
            navigate("/products/");
        });

            if (editSku === sku) {

            }
            else {

            }

        }
        else {
            // Create New SKU
            var numberQuery = query(inventoryCollection,
                where("uid", "==", user.uid),
                orderBy("number", "desc"),
                limit(1));

            var maxNumber = 0;
            const numberSnapshot = await getDocs(numberQuery);
            numberSnapshot.forEach((doc) => {
                // Get Max Number
                if (maxNumber < doc.data().number)
                    maxNumber = doc.data().number;
            });

            var newSku = {
                sku: sku,
                image: downloadURL,
                item_name: name,
                variation_name: variation,
                sale_price: salePrice,
                purchase_price: purchasePrice,
                barcode: "",
                category: "",
                subcategory: "",
                quantity: 0,
                shopee_quantity: 0,
                lazada_quantity: 0,
                tiktok_quantity: 0,
                low_stock_quantity: 0,
                ecommerce_sync: false,
                ecommerce_sync_array: [],
                created_at: new Date()
            };

            // Create a new Inventory List
            if (maxNumber % 500 === 0) {

                maxNumber++;
                var inventory = [];
                inventory.push(newSku);

                var sku_list = [];
                sku_list.push(sku);

                await setDoc(
                    doc(db, "inventory", user.uid + "_" + Math.ceil(maxNumber / 500) * 500),
                    {
                        inventory: inventory,
                        number: maxNumber,
                        sku_list: sku_list,
                        uid: user.uid
                    });
            }
            else {
                // Append to the final Document
                numberSnapshot.forEach(async (eachDoc) => {
                    if (maxNumber === eachDoc.data().number) {
                        await updateDoc(doc(db, "inventory", eachDoc.id), {
                            inventory: arrayUnion(newSku),
                            sku_list: arrayUnion(sku),
                            number: increment(1)
                        });
                    }
                });
            }

        }
    }

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar userMetadata={userMetadata} />
                <div className="top">
                    {
                        editSku !== "" ?
                            <h1>Edit SKU | {editSku}</h1>
                            :
                            <h1>Add New Product</h1>
                    }
                </div>
                <div className="bottom">
                    <div className="left">
                        <img src={ 
                            selectedImage ? URL.createObjectURL(selectedImage) 
                            : editSku !== "" ? previousImage
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"}
                            alt="" />
                    </div>
                    <div className="right">
                        <form>
                            <div className="formInput">
                                <label htmlFor="file">
                                    Image: <DriveFolderUploadOutlinedIcon className="icon" />
                                </label>
                                <input type="file" id="file" style={{ display: "none" }}
                                    accept=".jpg, .jpeg, .png"
                                    onChange={(event) => {
                                        const options = {
                                            maxSizeMB: 1,
                                            maxWidthOrHeight: 800,
                                            useWebWorker: true
                                        }

                                        /*
                                        if(options.maxSizeMB >= event.target.files[0].size / 1024000){
                                            console.log("Image is too small")
                                        }
                                        else{
                                            console.log(event.target.files[0].size + "Image is too BIG")
                                        }
                                        */

                                        let output;
                                        var imger = URL.createObjectURL(event.target.files[0]);
                                        imageCompression(event.target.files[0], options).then((x) => {
                                            output = x;

                                            // If Less than 1MB
                                            // In case anything gone wrong, limit size
                                            if (output.size <= 1000000)
                                                setSelectedImage(output);
                                        })

                                        //setSelectedImage(event.target.files[0]);
                                    }} />
                            </div>
                            {/*
                            {productInputs.map(input => (
                                <div className="formInput" key={input.id}>
                                    <label>{input.label}</label>
                                    <input type={input.type} placeholder={input.placeholder}></input>
                                </div>
                            ))}
                             */}
                            <div className="formInput" key="sku">
                                <label>SKU</label>
                                <input id="skuInputText" type="text" placeholder="SKU"
                                    onInput={e => {
                                        e.target.value = (e.target.value).toUpperCase();
                                        setSku(e.target.value);
                                    }
                                    }></input>
                            </div>
                            <div className="formInput" key="name">
                                <label>Name</label>
                                <input id="nameInputText" type="text" placeholder="Name" onInput={e => setName(e.target.value)}></input>
                            </div>
                            <div className="formInput" key="variation">
                                <label>Variation</label>
                                <input id="variationInputText" type="text" onInput={e => setVariationDescription(e.target.value)}></input>
                            </div>
                            <div className="formInput" key="purchaseprice">
                                <label>Purchase Price</label>
                                <input id="purchasePriceInputText" type="text" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    onInput={e => setPurchasePrice(e.target.value)}></input>
                            </div>
                            <div className="formInput" key="saleprice">
                                <label>Sale Price</label>
                                <input id="salePriceInputText" type="text" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    onInput={e => setSalePrice(e.target.value)}></input>
                            </div>
                            <button type="button" onClick={() => createSKU()}>Submit</button>
                        </form>
                    </div>
                </div>

                {
                    editSku !== "" ?
                        <div className="transferHistory">
                            <h1>Transfer History</h1>
                            <div>
                                <Datatable type={"products_transfer"} overrideDataRow={transferHistory} />
                            </div>
                        </div>
                        :
                        <div></div>
                }
            </div>
        </div>
    )
}

export default ProductNew;
import "./AboutUs.scss";
import HomeNavbar from "../../../components/homeNavbar/HomeNavbar";
import HomeFooter from "../../../components/homeFooter/HomeFooter";
import { Helmet } from "react-helmet-async";


const AboutUs = ({ active }) => {

    return (
        <div>
            <div className="homeContainer">
                <Helmet>
                    <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                    <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
                </Helmet>
                <HomeNavbar active={active} />
                <div className="aboutUsContainer" name="aboutUsContainer">

                    <div className="title">

                        <div className="details">
                            <h2>TactiBots Sdn. Bhd.</h2>
                            <p>TactiBots is a Malaysian SaaS platform that is designed for all Businesses.</p>
                            <p>We provide FREE E-Commerce System, Question and Answer Website for businesses.</p>
                            <p>We hope everyone can Learn, Share & Grow with TactiBots.</p>
                        </div>
                    </div>

                    <div className="story">
                        <h1>Our Story</h1>
                        <p>We started out by assisting individual seller at a freelance basis.</p>
                        <p>As time passes, we realized that this solution could be useful for more people.</p>
                        <br></br>
                        <p>We decided to startup a company and name it TactiBots.</p>
                        <p>The naming is a combination word of 'Tactics' and 'Bots'.</p>
                        <br></br>
                        <p>TactiBots is founded in Penang at 3rd of June 2020.</p>
                        <p>Penang is a lovely state within Malaysia.</p>
                        <p>The picture above is glimpse of both Penang bridges and Island.</p>
                        <p>We decided to grow the company at a slow pace since it's pandemic era.</p>
                        <br></br>
                        <p>In 2020, we managed to partnered with Shopee, Lazada and SQL Financial Accounting.</p>
                        <p>We created a Windows Desktop application that can integrate with accounting software.</p>
                        <br></br>
                        <p>In 2021, we managed to develop our own Accounting Software.</p>
                        <p>We now have full fledged accounting solution for sellers.</p>
                        <br></br>
                        <p>In 2022, we finally move out to our first office!</p>
                        <div className="images">

                            <img
                                src={require('../../../materials/story_office_1.jpg')}
                                alt=""
                            />
                            <img
                                src={require('../../../materials/story_office_2.jpg')}
                                alt=""
                            />
                        </div>
                        <p>We start designing custom webs for our clients.</p>
                        <p>We also launched our SaaS platform for all businesses.</p>
                        <p>We promised to serve our users with our best effort!</p>
                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    )
}

export default AboutUs;